import React from 'react';
import HeroSection from '../components/homepage/hero';
import GenerateResume from '../components/homepage/generate_resume';
import HowItWorks from '../components/homepage/how_it_works';
import PricingSection from '../components/homepage/pricing_section.js';
import Footer from '../components/footer';
import FAQPage from '../../src/pages/faq.js';
import LinkedInPostMockup from '../components/homepage/linkedin_comment.js';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import FeatureHighlights from '../components/homepage/feature_highlights.js';

const Homepage = () => {
    const { isAuthenticated, isLoading } = useAuth0();

    if (isLoading) {
        return <div>Loading...</div>;
    }
    // Redirect if authenticated
    if (isAuthenticated) {
        return <Navigate to="/dashboard-layout" />;
    }

    return (
        <div>
            <HeroSection />
            {/* <FeatureHighlights /> */}
            {/* <LinkedInPostMockup /> */}
            {/* <GenerateResume /> */}
            {/* <HowItWorks /> */}
            <PricingSection />
            <FAQPage />
            <Footer />

        </div>
    );
};

export default Homepage;
