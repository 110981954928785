import React, { useState, useEffect } from 'react';
import TailoredResume from '../components/dashboard/tailored_resume';
import { Typography, Grid, useTheme } from '@mui/material';
import '../styles/components/dashboard.css';
import { UserProvider } from '../components/user_context';
import { useAuth0 } from '@auth0/auth0-react';
import { baseurl, check_credits } from '../util/endpoints.js';

const Dashboard = () => {
    const theme = useTheme();

    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [usagecredits, setUsagecredits] = useState({
        auto_apply_credits: 0,
        auto_apply_limit: 0,
        ai_resume_credits: 0,
        ai_resume_limit: 0,
        ai_post_comment_credits: 0,
        ai_post_comment_limit: 0,
    });

    const userid = localStorage.getItem('userid');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchUsagecredits = async () => {
            if (user) {
                setIsLoading(true);
                try {
                    const token = await getAccessTokenSilently();
                    const endpoint = baseurl + check_credits;
                    const response = await fetch(endpoint, {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ userid: userid }),
                    });
                    const data = await response.json();
                    if (data.status === 'success') {
                        setUsagecredits({
                            auto_apply_credits: data.data.auto_apply_credits,
                            auto_apply_limit: data.data.auto_apply_limit,
                            ai_resume_credits: data.data.ai_resume_credits,
                            ai_resume_limit: data.data.ai_resume_limit,
                            ai_post_comment_credits: data.data.ai_post_comment_credits,
                            ai_post_comment_limit: data.data.ai_post_comment_limit,
                        });
                        setIsLoading(false);
                    }
                } catch (error) {
                    console.error("Failed to check credits:", error);
                }
            }
        };

        fetchUsagecredits();
    }, [user]);

    return (
        <UserProvider>
            <div className="dashboard-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: '16px' }}>
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Typography sx={{
                            fontSize: { xs: '1.25rem', sm: '1.5rem' },
                            fontFamily: 'Georgia, serif',
                            marginBottom: '16px',
                            fontWeight: 'bold',
                        }}>
                            Generate AI Resume
                        </Typography>
                        <Typography sx={{
                            fontSize: { xs: '0.875rem', sm: '1rem' },
                            fontFamily: 'Georgia, serif',
                            width: { xs: '50%', sm: '50%' },
                            textAlign: 'center',
                            margin: '0 auto',
                            color: theme.palette.text.secondary,
                        }}>
                            You can ensure that your resume stands out and places you in the top 1% of candidates. All you need to do is provide the job details, and our AI will take care of the rest.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <TailoredResume />
                    </Grid>
                </Grid>
            </div>
        </UserProvider>
    );
};

export default Dashboard;