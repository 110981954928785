
// import React from 'react';
// import { Box, Grid, TextField, Typography, IconButton, Button } from '@mui/material';
// import DeleteIcon from '@mui/icons-material/Delete';
// import AddIcon from '@mui/icons-material/Add';

// const SectionFields = ({ sectionName, formData, setFormData, isConfirmed, handleAddItem, sectionErrors }) => {
//     const sectionKey = sectionName.toLowerCase();

//     const handleChange = (index, field, value) => {
//         const newItems = [...formData[sectionKey]];
//         newItems[index][field] = value;
//         setFormData({ ...formData, [sectionKey]: newItems });
//     };

//     const handleDescriptionChange = (index, descIndex, value) => {
//         const newItems = [...formData[sectionKey]];
//         newItems[index].description[descIndex] = value;
//         setFormData({ ...formData, [sectionKey]: newItems });
//     };

//     const handleAddDescription = (index) => {
//         const newItems = [...formData[sectionKey]];
//         newItems[index].description.push('');
//         setFormData({ ...formData, [sectionKey]: newItems });
//     };

//     const handleDeleteDescription = (index, descIndex) => {
//         const newItems = [...formData[sectionKey]];
//         newItems[index].description = newItems[index].description.filter((_, i) => i !== descIndex);
//         setFormData({ ...formData, [sectionKey]: newItems });
//     };

//     const handleDelete = (index) => {
//         const newItems = formData[sectionKey].filter((_, i) => i !== index);
//         setFormData({ ...formData, [sectionKey]: newItems });
//     };

//     const formatDate = (date) => {
//         try {
//             if (!date) return '';
//             const d = new Date(date);
//             if (isNaN(d.getTime())) throw new Error('Invalid date');
//             return d.toISOString().substring(0, 10);
//         } catch (error) {
//             console.error('Error formatting date:', error);
//             return '';
//         }
//     };

//     const renderDescriptionFields = (item, index) => (
//         <Box key={index} style={{ marginBottom: 10 }}>
//             <Typography variant="subtitle2">Description</Typography>
//             {(item.description || []).map((desc, descIndex) => (
//                 <Grid container spacing={1} key={descIndex} alignItems="center">
//                     <Grid item xs={11}>
//                         <TextField
//                             fullWidth
//                             margin="dense"
//                             value={desc}
//                             onChange={(e) => handleDescriptionChange(index, descIndex, e.target.value)}
//                             disabled={isConfirmed}
//                             multiline
//                             inputProps={{ style: { lineHeight: 'auto' } }}
//                         />
//                     </Grid>
//                     <Grid item xs={1}>
//                         <IconButton onClick={() => handleDeleteDescription(index, descIndex)} disabled={isConfirmed}>
//                             <DeleteIcon />
//                         </IconButton>
//                     </Grid>
//                 </Grid>
//             ))}
//             <Button onClick={() => handleAddDescription(index)} disabled={isConfirmed} startIcon={<AddIcon />}>
//                 Add Bullet Point
//             </Button>
//         </Box>
//     );

//     const renderFields = (item, index) => {
//         switch (sectionKey) {
//             case 'experiences':
//                 return (
//                     <Grid container spacing={2} key={index} style={{ marginBottom: 10 }}>
//                         <Grid item xs={12}>
//                             <IconButton
//                                 aria-label="delete"
//                                 onClick={() => handleDelete(index)}
//                                 disabled={isConfirmed}
//                                 style={{ position: 'absolute', right: 0, top: 0 }}
//                             >
//                                 <DeleteIcon />
//                             </IconButton>
//                         </Grid>
//                         <Grid item xs={12} sm={6}>
//                             <TextField
//                                 label="Job Title"
//                                 value={item.job_title}
//                                 disabled={isConfirmed}
//                                 onChange={(e) => handleChange(index, 'job_title', e.target.value)}
//                                 fullWidth
//                                 margin="dense"
//                                 error={sectionErrors[index]?.job_title}
//                                 helperText={sectionErrors[index]?.job_title ? "Required" : ""}
//                             />
//                         </Grid>
//                         <Grid item xs={12} sm={6}>
//                             <TextField
//                                 label="Company Name"
//                                 value={item.company_name}
//                                 disabled={isConfirmed}
//                                 onChange={(e) => handleChange(index, 'company_name', e.target.value)}
//                                 fullWidth
//                                 margin="dense"
//                                 error={sectionErrors[index]?.company_name}
//                                 helperText={sectionErrors[index]?.company_name ? "Required" : ""}
//                             />
//                         </Grid>
//                         <Grid item xs={12} sm={6}>
//                             <TextField
//                                 label="Start Date"
//                                 type="date"
//                                 value={formatDate(item.start_date)}
//                                 disabled={isConfirmed}
//                                 onChange={(e) => handleChange(index, 'start_date', e.target.value)}
//                                 fullWidth
//                                 margin="dense"
//                                 InputLabelProps={{ shrink: true }}
//                                 error={sectionErrors[index]?.start_date}
//                                 helperText={sectionErrors[index]?.start_date ? "Required" : ""}
//                             />
//                         </Grid>
//                         <Grid item xs={12} sm={6}>
//                             <TextField
//                                 label="End Date"
//                                 type="date"
//                                 value={formatDate(item.end_date)}
//                                 disabled={isConfirmed}
//                                 onChange={(e) => handleChange(index, 'end_date', e.target.value)}
//                                 fullWidth
//                                 margin="dense"
//                                 InputLabelProps={{ shrink: true }}
//                                 error={sectionErrors[index]?.end_date}
//                                 helperText={sectionErrors[index]?.end_date ? "Required" : ""}
//                             />
//                         </Grid>
//                         <Grid item xs={12}>
//                             {renderDescriptionFields(item, index)}
//                         </Grid>
//                     </Grid>
//                 );
//             case 'projects':
//                 return (
//                     <Grid container spacing={2} key={index} style={{ marginBottom: 10 }}>
//                         <Grid item xs={12}>
//                             <IconButton
//                                 aria-label="delete"
//                                 onClick={() => handleDelete(index)}
//                                 disabled={isConfirmed}
//                                 style={{ position: 'absolute', right: 0, top: 0 }}
//                             >
//                                 <DeleteIcon />
//                             </IconButton>
//                         </Grid>
//                         <Grid item xs={12}>
//                             <TextField
//                                 label="Project Title"
//                                 value={item.project_title}
//                                 disabled={isConfirmed}
//                                 onChange={(e) => handleChange(index, 'project_title', e.target.value)}
//                                 fullWidth
//                                 margin="dense"
//                                 error={sectionErrors[index]?.project_title}
//                                 helperText={sectionErrors[index]?.project_title ? "Required" : ""}
//                             />
//                         </Grid>
//                         <Grid item xs={12} sm={6}>
//                             <TextField
//                                 label="Start Date"
//                                 type="date"
//                                 value={formatDate(item.start_date)}
//                                 disabled={isConfirmed}
//                                 onChange={(e) => handleChange(index, 'start_date', e.target.value)}
//                                 fullWidth
//                                 margin="dense"
//                                 InputLabelProps={{ shrink: true }}
//                                 error={sectionErrors[index]?.start_date}
//                                 helperText={sectionErrors[index]?.start_date ? "Required" : ""}
//                             />
//                         </Grid>
//                         <Grid item xs={12} sm={6}>
//                             <TextField
//                                 label="End Date"
//                                 type="date"
//                                 value={formatDate(item.end_date)}
//                                 disabled={isConfirmed}
//                                 onChange={(e) => handleChange(index, 'end_date', e.target.value)}
//                                 fullWidth
//                                 margin="dense"
//                                 InputLabelProps={{ shrink: true }}
//                                 error={sectionErrors[index]?.end_date}
//                                 helperText={sectionErrors[index]?.end_date ? "Required" : ""}
//                             />
//                         </Grid>
//                         <Grid item xs={12}>
//                             <TextField
//                                 label="Technologies Used"
//                                 value={item.technologies_used}
//                                 disabled={isConfirmed}
//                                 onChange={(e) => handleChange(index, 'technologies_used', e.target.value)}
//                                 fullWidth
//                                 margin="dense"
//                                 error={sectionErrors[index]?.technologies_used}
//                                 helperText={sectionErrors[index]?.technologies_used ? "Required" : ""}
//                             />
//                         </Grid>
//                         <Grid item xs={12}>
//                             {renderDescriptionFields(item, index)}
//                         </Grid>
//                     </Grid>
//                 );
//             case 'education':
//                 return (
//                     <Grid container spacing={2} key={index} style={{ marginBottom: 10 }}>
//                         <Grid item xs={12}>
//                             <IconButton
//                                 aria-label="delete"
//                                 onClick={() => handleDelete(index)}
//                                 disabled={isConfirmed}
//                                 style={{ position: 'absolute', right: 0, top: 0 }}
//                             >
//                                 <DeleteIcon />
//                             </IconButton>
//                         </Grid>
//                         <Grid item xs={12}>
//                             <TextField
//                                 label="Institution Name"
//                                 value={item.institution_name}
//                                 disabled={isConfirmed}
//                                 onChange={(e) => handleChange(index, 'institution_name', e.target.value)}
//                                 fullWidth
//                                 margin="dense"
//                                 error={sectionErrors[index]?.institution_name}
//                                 helperText={sectionErrors[index]?.institution_name ? "Required" : ""}
//                             />
//                         </Grid>
//                         <Grid item xs={12} sm={6}>
//                             <TextField
//                                 label="Degree"
//                                 value={item.degree}
//                                 disabled={isConfirmed}
//                                 onChange={(e) => handleChange(index, 'degree', e.target.value)}
//                                 fullWidth
//                                 margin="dense"
//                                 error={sectionErrors[index]?.degree}
//                                 helperText={sectionErrors[index]?.degree ? "Required" : ""}
//                             />
//                         </Grid>
//                         <Grid item xs={12} sm={6}>
//                             <TextField
//                                 label="Field of Study"
//                                 value={item.field_of_study}
//                                 disabled={isConfirmed}
//                                 onChange={(e) => handleChange(index, 'field_of_study', e.target.value)}
//                                 fullWidth
//                                 margin="dense"
//                                 error={sectionErrors[index]?.field_of_study}
//                                 helperText={sectionErrors[index]?.field_of_study ? "Required" : ""}
//                             />
//                         </Grid>
//                         <Grid item xs={12} sm={6}>
//                             <TextField
//                                 label="Start Date"
//                                 type="date"
//                                 value={formatDate(item.start_date)}
//                                 disabled={isConfirmed}
//                                 onChange={(e) => handleChange(index, 'start_date', e.target.value)}
//                                 fullWidth
//                                 margin="dense"
//                                 InputLabelProps={{ shrink: true }}
//                                 error={sectionErrors[index]?.start_date}
//                                 helperText={sectionErrors[index]?.start_date ? "Required" : ""}
//                             />
//                         </Grid>
//                         <Grid item xs={12} sm={6}>
//                             <TextField
//                                 label="End Date"
//                                 type="date"
//                                 value={formatDate(item.end_date)}
//                                 disabled={isConfirmed}
//                                 onChange={(e) => handleChange(index, 'end_date', e.target.value)}
//                                 fullWidth
//                                 margin="dense"
//                                 InputLabelProps={{ shrink: true }}
//                                 error={sectionErrors[index]?.end_date}
//                                 helperText={sectionErrors[index]?.end_date ? "Required" : ""}
//                             />
//                         </Grid>
//                     </Grid>
//                 );
//             case 'certifications':
//                 return (
//                     <Grid container spacing={2} key={index} style={{ marginBottom: 10 }}>
//                         <Grid item xs={12}>
//                             <IconButton
//                                 aria-label="delete"
//                                 onClick={() => handleDelete(index)}
//                                 disabled={isConfirmed}
//                                 style={{ position: 'absolute', right: 0, top: 0 }}
//                             >
//                                 <DeleteIcon />
//                             </IconButton>
//                         </Grid>
//                         <Grid item xs={12} sm={6}>
//                             <TextField
//                                 label="Certification Name"
//                                 value={item.certificationName}
//                                 disabled={isConfirmed}
//                                 onChange={(e) => handleChange(index, 'certificationName', e.target.value)}
//                                 fullWidth
//                                 margin="dense"
//                                 error={sectionErrors[index]?.certificationName}
//                                 helperText={sectionErrors[index]?.certificationName ? "Required" : ""}
//                             />
//                         </Grid>
//                         <Grid item xs={12} sm={6}>
//                             <TextField
//                                 label="Issuing Organization"
//                                 value={item.issuingOrganization}
//                                 disabled={isConfirmed}
//                                 onChange={(e) => handleChange(index, 'issuingOrganization', e.target.value)}
//                                 fullWidth
//                                 margin="dense"
//                                 error={sectionErrors[index]?.issuingOrganization}
//                                 helperText={sectionErrors[index]?.issuingOrganization ? "Required" : ""}
//                             />
//                         </Grid>
//                         <Grid item xs={12} sm={6}>
//                             <TextField
//                                 label="Date Received"
//                                 type="date"
//                                 value={formatDate(item.dateReceived)}
//                                 disabled={isConfirmed}
//                                 onChange={(e) => handleChange(index, 'dateReceived', e.target.value)}
//                                 fullWidth
//                                 margin="dense"
//                                 InputLabelProps={{ shrink: true }}
//                                 error={sectionErrors[index]?.dateReceived}
//                                 helperText={sectionErrors[index]?.dateReceived ? "Required" : ""}
//                             />
//                         </Grid>
//                     </Grid>
//                 );
//             default:
//                 return null;
//         }
//     };

//     const buttonLabel = sectionName === "Education" ? "Education" : sectionName.slice(0, -1);

//     return (
//         <>
//             <Typography variant="h6" style={{ marginTop: 20, marginBottom: 10 }}>{sectionName}:</Typography>
//             <Button
//                 onClick={() => handleAddItem(sectionKey)}
//                 variant="outlined"
//                 startIcon={<AddIcon />}
//                 style={{ marginBottom: 20 }}
//             >
//                 Add {buttonLabel}
//             </Button>
//             {formData[sectionKey].map((item, index) => (
//                 <Box key={index} style={{ marginBottom: 20, padding: 10, border: '1px solid gray', borderRadius: 5, position: 'relative' }}>
//                     {renderFields(item, index)}
//                 </Box>
//             ))}
//         </>
//     );
// };

// export default SectionFields;


import React from 'react';
import { Box, Grid, TextField, Typography, IconButton, Button, Checkbox, FormControlLabel } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

const SectionFields = ({ sectionName, formData, setFormData, isConfirmed, handleAddItem, sectionErrors }) => {
    const sectionKey = sectionName.toLowerCase();

    const handleChange = (index, field, value) => {
        const newItems = [...formData[sectionKey]];
        newItems[index][field] = value;
        setFormData({ ...formData, [sectionKey]: newItems });
    };

    const handleDescriptionChange = (index, descIndex, value) => {
        const newItems = [...formData[sectionKey]];
        newItems[index].description[descIndex] = value;
        setFormData({ ...formData, [sectionKey]: newItems });
    };

    const handleAddDescription = (index) => {
        const newItems = [...formData[sectionKey]];
        newItems[index].description = newItems[index].description || [];
        newItems[index].description.push('');
        setFormData({ ...formData, [sectionKey]: newItems });
    };

    const handleDeleteDescription = (index, descIndex) => {
        const newItems = [...formData[sectionKey]];
        newItems[index].description = newItems[index].description.filter((_, i) => i !== descIndex);
        setFormData({ ...formData, [sectionKey]: newItems });
    };

    const handleDelete = (index) => {
        const newItems = formData[sectionKey].filter((_, i) => i !== index);
        setFormData({ ...formData, [sectionKey]: newItems });
    };

    const formatDate = (date) => {
        try {
            if (!date) return '';
            const d = new Date(date);
            if (isNaN(d.getTime())) throw new Error('Invalid date');
            return d.toISOString().substring(0, 10);
        } catch (error) {
            console.error('Error formatting date:', error);
            return '';
        }
    };

    const renderDescriptionFields = (item, index) => (
        <Box key={index} style={{ marginBottom: 10 }}>
            <Typography variant="subtitle2">Description</Typography>
            {(item.description || []).map((desc, descIndex) => (
                <Grid container spacing={1} key={descIndex} alignItems="center">
                    <Grid item xs={11}>
                        <TextField
                            fullWidth
                            margin="dense"
                            value={desc}
                            onChange={(e) => handleDescriptionChange(index, descIndex, e.target.value)}
                            disabled={isConfirmed}
                            multiline
                            inputProps={{ style: { lineHeight: 'auto' } }}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton onClick={() => handleDeleteDescription(index, descIndex)} disabled={isConfirmed}>
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            ))}
            <Button onClick={() => handleAddDescription(index)} disabled={isConfirmed} startIcon={<AddIcon />}>
                Add Bullet Point
            </Button>
        </Box>
    );

    const handleCheckboxChange = (index, checked) => {
        const newItems = [...formData[sectionKey]];
        if (checked) {
            newItems[index].end_date = '9999-12-12';
        } else {
            newItems[index].end_date = '';
        }
        newItems[index].is_present = checked;
        setFormData({ ...formData, [sectionKey]: newItems });
    };

    const renderFields = (item, index) => {
        const isPresent = item.end_date === '9999-12-12';

        switch (sectionKey) {
            case 'experiences':
                return (
                    <Grid container spacing={2} key={index} style={{ marginBottom: 10 }}>
                        <Grid item xs={12}>
                            <IconButton
                                aria-label="delete"
                                onClick={() => handleDelete(index)}
                                disabled={isConfirmed}
                                style={{ position: 'absolute', right: 0, top: 0 }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Job Title"
                                value={item.job_title}
                                disabled={isConfirmed}
                                onChange={(e) => handleChange(index, 'job_title', e.target.value)}
                                fullWidth
                                margin="dense"
                                error={sectionErrors[index]?.job_title}
                                helperText={sectionErrors[index]?.job_title ? "Required" : ""}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Company Name"
                                value={item.company_name}
                                disabled={isConfirmed}
                                onChange={(e) => handleChange(index, 'company_name', e.target.value)}
                                fullWidth
                                margin="dense"
                                error={sectionErrors[index]?.company_name}
                                helperText={sectionErrors[index]?.company_name ? "Required" : ""}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Start Date"
                                type="date"
                                value={formatDate(item.start_date)}
                                disabled={isConfirmed}
                                onChange={(e) => handleChange(index, 'start_date', e.target.value)}
                                fullWidth
                                margin="dense"
                                InputLabelProps={{ shrink: true }}
                                error={sectionErrors[index]?.start_date}
                                helperText={sectionErrors[index]?.start_date ? "Required" : ""}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="End Date"
                                type="date"
                                value={isPresent ? '' : formatDate(item.end_date)}
                                disabled={isConfirmed || isPresent}
                                onChange={(e) => handleChange(index, 'end_date', e.target.value)}
                                fullWidth
                                margin="dense"
                                InputLabelProps={{ shrink: true }}
                                error={sectionErrors[index]?.end_date}
                                helperText={sectionErrors[index]?.end_date ? "Required" : ""}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isPresent}
                                        onChange={(e) => handleCheckboxChange(index, e.target.checked)}
                                        disabled={isConfirmed}
                                    />
                                }
                                label="Present"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {renderDescriptionFields(item, index)}
                        </Grid>
                    </Grid>
                );
            case 'projects':
                return (
                    <Grid container spacing={2} key={index} style={{ marginBottom: 10 }}>
                        <Grid item xs={12}>
                            <IconButton
                                aria-label="delete"
                                onClick={() => handleDelete(index)}
                                disabled={isConfirmed}
                                style={{ position: 'absolute', right: 0, top: 0 }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Project Title"
                                value={item.project_title}
                                disabled={isConfirmed}
                                onChange={(e) => handleChange(index, 'project_title', e.target.value)}
                                fullWidth
                                margin="dense"
                                error={sectionErrors[index]?.project_title}
                                helperText={sectionErrors[index]?.project_title ? "Required" : ""}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Start Date"
                                type="date"
                                value={formatDate(item.start_date)}
                                disabled={isConfirmed}
                                onChange={(e) => handleChange(index, 'start_date', e.target.value)}
                                fullWidth
                                margin="dense"
                                InputLabelProps={{ shrink: true }}
                                error={sectionErrors[index]?.start_date}
                                helperText={sectionErrors[index]?.start_date ? "Required" : ""}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="End Date"
                                type="date"
                                value={isPresent ? '' : formatDate(item.end_date)}
                                disabled={isConfirmed || isPresent}
                                onChange={(e) => handleChange(index, 'end_date', e.target.value)}
                                fullWidth
                                margin="dense"
                                InputLabelProps={{ shrink: true }}
                                error={sectionErrors[index]?.end_date}
                                helperText={sectionErrors[index]?.end_date ? "Required" : ""}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isPresent}
                                        onChange={(e) => handleCheckboxChange(index, e.target.checked)}
                                        disabled={isConfirmed}
                                    />
                                }
                                label="Present"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Technologies Used"
                                value={item.technologies_used}
                                disabled={isConfirmed}
                                onChange={(e) => handleChange(index, 'technologies_used', e.target.value)}
                                fullWidth
                                margin="dense"
                                error={sectionErrors[index]?.technologies_used}
                                helperText={sectionErrors[index]?.technologies_used ? "Required" : ""}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {renderDescriptionFields(item, index)}
                        </Grid>
                    </Grid>
                );
            case 'education':
                return (
                    <Grid container spacing={2} key={index} style={{ marginBottom: 10 }}>
                        <Grid item xs={12}>
                            <IconButton
                                aria-label="delete"
                                onClick={() => handleDelete(index)}
                                disabled={isConfirmed}
                                style={{ position: 'absolute', right: 0, top: 0 }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Institution Name"
                                value={item.institution_name}
                                disabled={isConfirmed}
                                onChange={(e) => handleChange(index, 'institution_name', e.target.value)}
                                fullWidth
                                margin="dense"
                                error={sectionErrors[index]?.institution_name}
                                helperText={sectionErrors[index]?.institution_name ? "Required" : ""}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Degree"
                                value={item.degree}
                                disabled={isConfirmed}
                                onChange={(e) => handleChange(index, 'degree', e.target.value)}
                                fullWidth
                                margin="dense"
                                error={sectionErrors[index]?.degree}
                                helperText={sectionErrors[index]?.degree ? "Required" : ""}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Field of Study"
                                value={item.field_of_study}
                                disabled={isConfirmed}
                                onChange={(e) => handleChange(index, 'field_of_study', e.target.value)}
                                fullWidth
                                margin="dense"
                                error={sectionErrors[index]?.field_of_study}
                                helperText={sectionErrors[index]?.field_of_study ? "Required" : ""}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Start Date"
                                type="date"
                                value={formatDate(item.start_date)}
                                disabled={isConfirmed}
                                onChange={(e) => handleChange(index, 'start_date', e.target.value)}
                                fullWidth
                                margin="dense"
                                InputLabelProps={{ shrink: true }}
                                error={sectionErrors[index]?.start_date}
                                helperText={sectionErrors[index]?.start_date ? "Required" : ""}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="End Date"
                                type="date"
                                value={isPresent ? '' : formatDate(item.end_date)}
                                disabled={isConfirmed || isPresent}
                                onChange={(e) => handleChange(index, 'end_date', e.target.value)}
                                fullWidth
                                margin="dense"
                                InputLabelProps={{ shrink: true }}
                                error={sectionErrors[index]?.end_date}
                                helperText={sectionErrors[index]?.end_date ? "Required" : ""}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isPresent}
                                        onChange={(e) => handleCheckboxChange(index, e.target.checked)}
                                        disabled={isConfirmed}
                                    />
                                }
                                label="Present"
                            />
                        </Grid>
                    </Grid>
                );
            case 'certifications':
                return (
                    <Grid container spacing={2} key={index} style={{ marginBottom: 10 }}>
                        <Grid item xs={12}>
                            <IconButton
                                aria-label="delete"
                                onClick={() => handleDelete(index)}
                                disabled={isConfirmed}
                                style={{ position: 'absolute', right: 0, top: 0 }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Certification Name"
                                value={item.certificationName}
                                disabled={isConfirmed}
                                onChange={(e) => handleChange(index, 'certificationName', e.target.value)}
                                fullWidth
                                margin="dense"
                                error={sectionErrors[index]?.certificationName}
                                helperText={sectionErrors[index]?.certificationName ? "Required" : ""}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Issuing Organization"
                                value={item.issuingOrganization}
                                disabled={isConfirmed}
                                onChange={(e) => handleChange(index, 'issuingOrganization', e.target.value)}
                                fullWidth
                                margin="dense"
                                error={sectionErrors[index]?.issuingOrganization}
                                helperText={sectionErrors[index]?.issuingOrganization ? "Required" : ""}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Date Received"
                                type="date"
                                value={formatDate(item.dateReceived)}
                                disabled={isConfirmed}
                                onChange={(e) => handleChange(index, 'dateReceived', e.target.value)}
                                fullWidth
                                margin="dense"
                                InputLabelProps={{ shrink: true }}
                                error={sectionErrors[index]?.dateReceived}
                                helperText={sectionErrors[index]?.dateReceived ? "Required" : ""}
                            />
                        </Grid>
                    </Grid>
                );
            default:
                return null;
        }
    };

    const buttonLabel = sectionName === "Education" ? "Education" : sectionName.slice(0, -1);

    return (
        <>
            <Typography variant="h6" style={{ marginTop: 20, marginBottom: 10 }}>{sectionName}:</Typography>
            <Button
                onClick={() => handleAddItem(sectionKey)}
                variant="outlined"
                startIcon={<AddIcon />}
                style={{ marginBottom: 20 }}
            >
                Add {buttonLabel}
            </Button>
            {formData[sectionKey].map((item, index) => (
                <Box key={index} style={{ marginBottom: 20, padding: 10, border: '1px solid gray', borderRadius: 5, position: 'relative' }}>
                    {renderFields(item, index)}
                </Box>
            ))}
        </>
    );
};

export default SectionFields;