export const formatFileName = (userProfileData) => {
    const userFullName = `${userProfileData.firstName}_${userProfileData.lastName}`.replace(/\s+/g, '_');
    const currentDate = new Date().toISOString().split('T')[0];
    return `${userFullName}_Resume.pdf`;
};

export const handleInputChange = (value, setter) => setter(value);

export const handleSectionChange = (section, setFormData, formData, category = null, value = null) => {
    if (section === 'skills') {
        const newSkills = value.split(',').map(skill => skill.trim());
        setFormData(prevData => ({
            ...prevData,
            skills: { ...prevData.skills, [category]: newSkills }
        }));
    } else {
        const newSectionData = [...formData[section]];
        newSectionData.unshift({
            ...newSectionData[0],
            job_title: '',
            company_name: '',
            start_date: '',
            end_date: '',
            description: []
        });
        setFormData(prevData => ({ ...prevData, [section]: newSectionData }));
    }
};