import React from 'react';
import { motion } from 'framer-motion';
import '../styles/career_hacks.css';

const CareerHacks = () => {
    const hacks = [
        {
            title: 'Tailored Resumes',
            content: 'Customizing your resume for each job role based on the job description can increase your chances of getting an interview by approximately 50%. Applicant Tracking Systems (ATS) prioritize resumes that closely match the job requirements.',
        },
        {
            title: 'Active LinkedIn Presence',
            content: 'Regularly commenting on posts and engaging with content on LinkedIn enhances your visibility and can lead to more opportunities being noticed by recruiters.',
        },
        {
            title: 'Expand Your Network',
            content: 'Having 500+ connections on LinkedIn significantly increases your visibility to recruiters and potential employers.',
        },
        {
            title: 'Open Source Contributions',
            content: 'Highlighting personal projects or contributions to open-source projects on your resume or LinkedIn profile can set you apart from other candidates.',
        },
        {
            title: 'Continuous Learning',
            content: 'Staying updated with industry trends and continually acquiring new skills through courses or certifications can make you a more attractive candidate.',
        },
    ];

    return (
        <div className="career-hacks-container">
            <motion.h1
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.2 }}
                className="career-hacks-title"
            >
                Career Hacks
            </motion.h1>
            <div className="hacks-list">
                {hacks.map((hack, index) => (
                    <motion.div
                        key={index}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.2 + index * 0.2, duration: 0.2 }}
                        className="hack-item"
                    >
                        <h3>{hack.title}</h3>
                        <p>{hack.content}</p>
                    </motion.div>
                ))}
            </div>
        </div>
    );
};

export default CareerHacks;
