import React, { useEffect, useState } from 'react';
import { Box, Typography, useTheme, IconButton, Skeleton } from '@mui/material';
import { motion } from 'framer-motion';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import Lottie from 'react-lottie';
import autoPilotAnimation from '../../lottie/auto-pilot.json';
import linkedinCommentAnimation from '../../lottie/linkedin-comment.json';
import resumeAnimation from '../../lottie/resume.json';
import '../../styles/components/homepage/feature_highlights.css';
import demoVideo from '../../lottie/demo.mp4';

// High-Quality SEO additions
const pageTitle = "Resume Builder Free | Best AI Resume Maker for Job Applications";
const pageDescription = "Create the best resume for job applications with our free AI resume builder. Boost your job search and apply to jobs with ease. Try it now!";

const features = [
    {
        icon: <Lottie options={{ animationData: resumeAnimation, loop: true, autoplay: true }} height={'20vw'} width={'20vw'} />,
        title: 'Tailored Resume for Every Job Application',
        description: 'Create a customized resume for each job application in less than 15 seconds, making you the ideal candidate for every job.',
    },
    {
        icon: <Lottie options={{ animationData: autoPilotAnimation, loop: true, autoplay: true }} height={'20vw'} width={'20vw'} />,
        title: 'Apply to Thousands of Jobs with One Click',
        description: `Apply to 100's jobs with a one click. Our intelligent AI will handle all the form-filling for you, saving you time and increasing your chances.`,
    },
    {
        icon: <Lottie options={{ animationData: linkedinCommentAnimation, loop: true, autoplay: true }} height={'20vw'} width={'20vw'} />,
        title: 'Automate LinkedIn Comments',
        description: 'Add human-like comments to LinkedIn posts automatically to boost your presence and engagement on the platform.',
    },
];
const videos = [
    {
        src: demoVideo,
        heading: 'Struggling to Get Interview Calls? Let An AI Build The Best Resume For You in Seconds',
        description: `Struggling to get interview calls after applying to countless jobs? Our advanced AI resume builder can help. Our platform ensures your resume places you in the top 1% of candidates. Experience our free tier and see significant results—90% of our users receive interview calls within just two weeks. Our AI generates job-specific resumes up to ten times faster than any other platform including chatgpt, helping you get the attention you deserve quickly and effectively. Start today and transform your job search!`
    },
    {
        src: 'https://user-resumes.nyc3.cdn.digitaloceanspaces.com/extension.mp4',
        heading: 'Tired of spending countless hours applying to jobs?',
        description: 'Let our advanced AI intelligently auto-fill and apply to hundreds of jobs for you, saving you countless hours. Track all your applications easily on your personalized job board and boost your job search efficiency like never before.'
    }
];

const HeroSection = () => {
    const theme = useTheme();
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const [videoLoading, setVideoLoading] = useState(true);

    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: true,
        });
    }, []);

    useEffect(() => {
        document.title = pageTitle;
        document.querySelector('meta[name="description"]').setAttribute("content", pageDescription);
    }, []);

    const handleNextVideo = () => {
        setVideoLoading(true);
        setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videos.length);
    };

    const handlePreviousVideo = () => {
        setVideoLoading(true);
        setCurrentVideoIndex((prevIndex) => (prevIndex - 1 + videos.length) % videos.length);
    };

    const handleVideoLoaded = () => {
        setVideoLoading(false);
    };

    const textVariants = {
        offscreen: { y: -30, opacity: 0 },
        onscreen: {
            y: 0,
            opacity: 1,
            transition: {
                type: 'spring',
                stiffness: 120,
                damping: 7,
                delay: 0.2
            }
        }
    };

    return (
        <section id="hero" className="hero-section" data-aos="fade-up" data-aos-duration="1000">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: theme.spacing(4),
                    textAlign: 'center',
                    overflow: 'hidden',
                    position: 'relative',
                    '@media (max-width:600px)': {
                        padding: theme.spacing(2),
                        minHeight: 'auto',
                    },
                    paddingTop: theme.spacing(8)
                }}
            >
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: -1,
                    background: theme.palette.primary.main,
                    clipPath: 'polygon(0 60%, 100% 30%, 100% 100%, 0 100%)',
                }} />

                <motion.div
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.8 }}
                    variants={textVariants}
                >
                    <Typography variant="h2" component="h1" gutterBottom sx={{
                        fontSize: { xs: '1.4rem', sm: '1.6rem', md: '1.8rem', lg: '2.2rem' },
                        mb: 3,
                        color: theme.palette.text.primary,
                        fontFamily: 'Georgia, serif',
                    }}>
                        {videos[currentVideoIndex].heading}
                    </Typography>
                    <Typography variant="body2" component="p" gutterBottom sx={{
                        fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem', lg: '1.2rem' },
                        mb: 2,
                        color: theme.palette.text.secondary,
                        fontFamily: 'Arial, sans-serif',
                    }}>
                        {videos[currentVideoIndex].description}
                    </Typography>
                </motion.div>

                <Box sx={{
                    width: '100%',
                    maxWidth: '100%',
                    position: 'relative',
                    zIndex: 1,
                    marginTop: theme.spacing(4),
                    marginBottom: theme.spacing(4),
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '@media (max-width:900px)': {
                        flexDirection: 'column',
                    },
                }}>

                    <IconButton onClick={handlePreviousVideo} sx={{
                        position: 'absolute',
                        left: { xs: '2%', md: '5%', lg: '10%' },
                        top: '50%',
                        transform: 'translateY(-50%)',
                        backgroundColor: theme.palette.primary.main,
                        color: '#fff',
                        zIndex: 1,
                        '&:hover': {
                            backgroundColor: theme.palette.primary.dark,
                        },
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                        transform: 'scale(1.1)',
                        transition: 'transform 0.3s ease, box-shadow 0.3s ease'
                    }}>
                        <FaArrowLeft />
                    </IconButton>

                    <Box sx={{
                        position: 'relative',
                        width: '100%',
                        maxWidth: { xs: '90vw', sm: '80vw', md: '70vw' },
                        height: { xs: '40vh', sm: '50vh', md: '60vh', lg: '70vh' },
                    }}
                        data-aos="fade-up"
                        data-aos-duration="1000"
                    >
                        {videoLoading && <Skeleton variant="rectangular" width="100%" height="100%" />}
                        <video
                            src={videos[currentVideoIndex].src}
                            style={{ width: '100%', height: '100%', display: videoLoading ? 'none' : 'block' }}
                            controls={false}
                            autoPlay
                            loop
                            muted
                            onLoadedData={handleVideoLoaded}
                            playsInline
                        />
                    </Box>

                    <IconButton onClick={handleNextVideo} sx={{
                        position: 'absolute',
                        right: { xs: '2%', md: '5%', lg: '10%' },
                        top: '50%',
                        transform: 'translateY(-50%)',
                        backgroundColor: theme.palette.primary.main,
                        color: '#fff',
                        zIndex: 1,
                        '&:hover': {
                            backgroundColor: theme.palette.primary.dark,
                        },
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                        transform: 'scale(1.1)',
                        transition: 'transform 0.3s ease, box-shadow 0.3s ease'
                    }}>
                        <FaArrowRight />
                    </IconButton>
                </Box>

                <div className="feature-highlights" style={{ zIndex: 2, display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                    {features.map((feature, index) => (
                        <motion.div
                            className="feature-card"
                            key={index}
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: index * 0.2 }}
                            whileHover={{ scale: 1.05 }}
                            style={{ flex: '1 1 300px', margin: '10px', maxWidth: 'calc(33.333% - 20px)', minWidth: '300px' }}
                        >
                            <motion.div
                                className="feature-background"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 0.1 }}
                                transition={{ duration: 0.7 }}
                            />
                            <div className="feature-icon">{feature.icon}</div>
                            <h3 className="feature-title" style={{ fontFamily: 'Georgia, serif', fontWeight: 'bold' }}>{feature.title}</h3>
                            <p className="feature-description" style={{ fontFamily: 'Georgia, serif' }}>{feature.description}</p>
                        </motion.div>
                    ))}
                </div>
            </Box>
        </section>
    );
};

export default HeroSection;