import React, { useContext, useState } from 'react';
import { motion } from 'framer-motion';
import '../../styles/support.css';
import { baseurl, supportMail } from '../../util/endpoints';
import { useAuth0 } from '@auth0/auth0-react';

const Support = () => {
    const [formState, setFormState] = useState({ subject: '', message: '' });
    const emailID = localStorage.getItem('emailID');
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

    const handleChange = (e) => {
        setFormState({
            ...formState,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const emailData = {
            from: emailID,
            subject: formState.subject,
            message: formState.message,
        };

        if (!emailID) {
            alert("User email not available");
            return;
        }

        try {
            if (emailID) {
                const token = await getAccessTokenSilently();
                const endpoint = baseurl + supportMail;
                const response = await fetch(endpoint, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(emailData),
                });

                if (response.ok) {
                    alert('Email sent successfully!');
                } else {
                    alert('Failed to send email. Please try again later.');
                }
            }
        } catch (error) {
            alert('Failed to send email. Please try again later.');
        }
    };

    return (
        <div className="support-container">
            <motion.h1
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.2 }}
                className="support-title"
            >
                Support
            </motion.h1>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5, duration: 0.2 }}
                className="support-form"
            >
                <form onSubmit={handleSubmit}>
                    <label htmlFor="subject">Subject</label>
                    <input
                        id="subject"
                        name="subject"
                        type="text"
                        placeholder="Enter the subject of your query"
                        value={formState.subject}
                        onChange={handleChange}
                    />

                    <label htmlFor="message">Message</label>
                    <textarea
                        id="message"
                        name="message"
                        rows="4"
                        cols="50"
                        placeholder="Please provide a detailed message regarding your query. We aim to address your concerns promptly."
                        value={formState.message}
                        onChange={handleChange}
                    ></textarea>

                    <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        className="submit-button"
                        type="submit"
                    >
                        Submit
                    </motion.button>
                </form>
            </motion.div>
        </div>
    );
};

export default Support;