import React from 'react';
import { Container, Typography, Box, Divider, Link, List, ListItem, ListItemText } from '@mui/material';
import { styled } from '@mui/system';

const StyledContainer = styled(Container)(({ theme }) => ({
  backgroundColor: '#f9f9f9',
  padding: theme.spacing(6, 4),
  borderRadius: theme.spacing(2),
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  marginTop: theme.spacing(4),
}));

const HeroSection = styled(Box)(({ theme }) => ({
  backgroundColor: '#0077B5',
  color: '#fff',
  padding: theme.spacing(4),
  borderRadius: theme.spacing(2),
  textAlign: 'center',
  marginBottom: theme.spacing(4),
}));

const ContentBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: '#fff',
  borderRadius: theme.spacing(1),
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
}));

const SectionHeading = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: 'bold',
  marginBottom: theme.spacing(2),
  paddingBottom: theme.spacing(1),
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(3, 0),
}));

const StyledList = styled(List)(({ theme }) => ({
  listStyleType: 'disc',
  paddingLeft: theme.spacing(4),
  '& .MuiListItem-root': {
    display: 'list-item',
  },
}));

const PrivacyPolicy = () => {
  return (
    <StyledContainer>
      <HeroSection>
        <Typography variant="h3" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="h6">
          Last updated: May 21, 2024
        </Typography>
      </HeroSection>
      <ContentBox>
        <Typography variant="body1" paragraph>
          Welcome to Careerfornia! Thank you for choosing to be part of our community at Careerfornia. We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy, or our practices with regards to your personal information, please contact us at <Link href="mailto:support@careerfornia.com">support@careerfornia.com</Link>.
        </Typography>
        
        <SectionHeading variant="h6">Table of Contents</SectionHeading>
        <section>
          <ListItem><ListItemText primary="1. Information We Collect" /></ListItem>
          <ListItem><ListItemText primary="2. How We Use Your Information" /></ListItem>
          <ListItem><ListItemText primary="3. Sharing Your Information" /></ListItem>
          <ListItem><ListItemText primary="4. Cookies and Other Tracking Technologies" /></ListItem>
          <ListItem><ListItemText primary="5. Data Security" /></ListItem>
          <ListItem><ListItemText primary="6. Privacy Rights" /></ListItem>
          <ListItem><ListItemText primary="7. Updates to This Policy" /></ListItem>
          <ListItem><ListItemText primary="8. Contact Us" /></ListItem>
        </section>

        <StyledDivider />

        <SectionHeading variant="h6">1. Information We Collect</SectionHeading>
        <Typography variant="body1" paragraph>
          We collect personal information that you provide to us directly when you register, apply for jobs, or interact with our services. This information includes:
        </Typography>
        <StyledList>
          <ListItem><ListItemText primary="Email address and contact details" /></ListItem>
          <ListItem><ListItemText primary="Job preferences and application details" /></ListItem>
          <ListItem><ListItemText primary="Resume and employment history" /></ListItem>
        </StyledList>
        <Typography variant="body1" paragraph>
          We also collect information automatically as you navigate through the site or through our extension. This may include usage details, IP addresses, and information collected through cookies and other tracking technologies.
        </Typography>

        <StyledDivider />

        <SectionHeading variant="h6">2. How We Use Your Information</SectionHeading>
        <Typography variant="body1" paragraph>
          We use your information to:
        </Typography>
        <StyledList>
          <ListItem><ListItemText primary="Provide, operate, and maintain our services" /></ListItem>
          <ListItem><ListItemText primary="Improve, personalize, and expand our services" /></ListItem>
          <ListItem><ListItemText primary="Understand and analyze how you use our services" /></ListItem>
          <ListItem><ListItemText primary="Develop new products, services, features, and functionality" /></ListItem>
          <ListItem><ListItemText primary="Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the service, and for marketing and promotional purposes" /></ListItem>
          <ListItem><ListItemText primary="Find and prevent fraud" /></ListItem>
        </StyledList>

        <StyledDivider />

        <SectionHeading variant="h6">3. Sharing Your Information</SectionHeading>
        <Typography variant="body1" paragraph>
          Your information may be shared with third parties in the following ways:
        </Typography>
        <StyledList>
          <ListItem><ListItemText primary="With service providers performing services on our behalf" /></ListItem>
          <ListItem><ListItemText primary="With partners and others who help us provide services to you" /></ListItem>
          <ListItem><ListItemText primary="For legal, protection, and safety purposes" /></ListItem>
        </StyledList>

        <StyledDivider />

        <SectionHeading variant="h6">4. Cookies and Other Tracking Technologies</SectionHeading>
        <Typography variant="body1" paragraph>
          We use cookies and similar tracking technologies to track the activity on our service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze our service.
        </Typography>

        <StyledDivider />

        <SectionHeading variant="h6">5. Data Security</SectionHeading>
        <Typography variant="body1" paragraph>
          We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information.
        </Typography>

        <StyledDivider />

        <SectionHeading variant="h6">6. Privacy Rights</SectionHeading>
        <Typography variant="body1" paragraph>
          If you are a resident of the European Economic Area (EEA), you have certain data protection rights. Careerfornia aims to take reasonable steps to allow you to correct, amend, delete or limit the use of your Personal Data.
        </Typography>
        <Typography variant="body1" paragraph>
          If you wish to be informed about what Personal Data we hold about you and if you want it to be removed from our systems, please contact us.
        </Typography>

        <StyledDivider />

        <SectionHeading variant="h6">7. Updates to This Policy</SectionHeading>
        <Typography variant="body1" paragraph>
          We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
        </Typography>

        <StyledDivider />

        <SectionHeading variant="h6">8. Contact Us</SectionHeading>
        <Typography variant="body1" paragraph>
          If you have any questions about this Privacy Policy, you can contact us at:
        </Typography>
        <Typography variant="body1" paragraph>
          Email: <Link href="mailto:support@careerfornia.com">support@careerfornia.com</Link>
        </Typography>
      </ContentBox>
    </StyledContainer>
  );
};

export default PrivacyPolicy;