import React, { useState, useEffect, useRef } from 'react';
import { Container, Typography, Box, Link } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { motion } from 'framer-motion';
import Skeleton from '@mui/material/Skeleton';

const PREFIX = 'HowExtensionWorks';

const classes = {
  mainContainer: `${PREFIX}-mainContainer`,
  header: `${PREFIX}-header`,
  videoContainer: `${PREFIX}-videoContainer`,
  stepContainer: `${PREFIX}-stepContainer`,
  content: `${PREFIX}-content`,
  textContainer: `${PREFIX}-textContainer`,
  divider: `${PREFIX}-divider`,
  linkContainer: `${PREFIX}-linkContainer`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`&.${classes.mainContainer}`]: {
    marginTop: theme.spacing(4),
  },
  [`& .${classes.header}`]: {
    marginBottom: theme.spacing(4),
    textAlign: 'center',
    color: theme.palette.primary.main,
    fontFamily: 'Georgia, serif',
  },
  [`& .${classes.videoContainer}`]: {
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
  [`& .${classes.linkContainer}`]: {
    textAlign: 'center',
    marginTop: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
}));

const HowExtensionWorks = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const videoRef = useRef(null);
  useEffect(() => {
    const videoElement = videoRef.current;

    const handleVideoLoad = () => {
      console.log('Video has loaded');
      setLoading(false);
    };

    const handleError = () => {
      console.log('Error loading video');
      setLoading(false);
    };

    if (videoElement) {
      videoElement.addEventListener('loadeddata', handleVideoLoad);
      videoElement.addEventListener('error', handleError);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('loadeddata', handleVideoLoad);
        videoElement.removeEventListener('error', handleError);
      }
    };
  }, []);


  return (
    <StyledContainer className={classes.mainContainer}>
      <Box className={classes.linkContainer} sx={{ marginBottom: theme.spacing(2) }}>
        <Typography variant="h6" component="p" sx={{ fontFamily: 'Georgia, serif', color: theme.palette.text.secondary, marginRight: theme.spacing(1) }}>
          Click here to add the Careerfornia extension to your browser:
        </Typography>
        <Link href="https://chromewebstore.google.com/detail/careerfonia/ampdncancbacehgbghpfhgfllclcinnp" target="_blank" rel="noopener" sx={{ fontFamily: 'Georgia, serif', color: theme.palette.primary.main, textDecoration: 'none', fontSize: '1.2rem' }}>
          Careerfornia Chrome Extension
        </Link>
      </Box>
      <div className={classes.videoContainer}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <video
            ref={videoRef}
            width="100%"
            height="600px"
            controls
            muted
            onLoadedData={() => {
              console.log('Video data loaded');
              setLoading(false);
            }}
            onError={() => {
              console.log('Error loading video');
              setLoading(false);
            }}
            onVolumeChange={(e) => {
              if (e.target.muted === false) {
                e.target.muted = true;
              }
            }}
          >
            <source src="https://user-resumes.nyc3.cdn.digitaloceanspaces.com/user-uploaded-resumes/Marketings%20video.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </motion.div>
      </div>
      <Box sx={{ textAlign: 'center', marginTop: theme.spacing(4), marginBottom: theme.spacing(4) }}>
        <Typography variant="body1" sx={{ fontFamily: 'Georgia, serif', color: theme.palette.text.primary }}>
          You may occasionally need to input certain fields that the autopilot feature does not initially recognize. Once these fields are provided, AI will remember and utilize this information automatically in subsequent uses, requiring no further input from you.
        </Typography>
      </Box>
    </StyledContainer>
  );
};

export default HowExtensionWorks;