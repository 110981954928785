import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer style={{ padding: '20px', textAlign: 'center', marginTop: '20px' }}>
            <p>&copy; 2024 Careerfornia. All rights reserved.</p>
            <nav style={{ margin: '10px 0' }}>
                <Link to="https://www.careerfornia.com/about-us" style={{ margin: '0 10px', color: '#0077B5', textDecoration: 'none' }}>About us</Link>
                <Link to="https://www.careerfornia.com/terms" style={{ margin: '0 10px', color: '#0077B5', textDecoration: 'none' }}>Terms of Service</Link>
                <Link to="https://www.careerfornia.com/privacy-policy" style={{ margin: '0 10px', color: '#0077B5', textDecoration: 'none' }}>Privacy Policy</Link>
                <Link to="https://www.careerfornia.com/contact" style={{ margin: '0 10px', color: '#0077B5', textDecoration: 'none' }}>Contact Us</Link>
                <Link to="https://www.careerfornia.com/support" style={{ margin: '0 10px', color: '#0077B5', textDecoration: 'none' }}>Support</Link>
            </nav>
            <p style={{ fontSize: '0.875rem', color: '#868686' }}>Follow us on

                <a href="https://twitter.com/careerfornia" target="_blank" rel="noreferrer" style={{ margin: '0 5px', color: '#0077B5', textDecoration: 'none' }}>Twitter</a>
                <a href="https://www.linkedin.com/in/careerfornia/" target="_blank" rel="noreferrer" style={{ margin: '0 5px', color: '#0077B5', textDecoration: 'none' }}>LinkedIn</a>
            </p>
        </footer>
    );
};

export default Footer;
