import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const formatFileName = (fileName) => {
  const parts = fileName.split('_');
  const capitalizedParts = parts.map(part => part.charAt(0).toUpperCase() + part.slice(1));
  const formattedFileName = capitalizedParts.join('_').replace(/_+/g, '_').replace(/_$/, '') + '_Resume.pdf';
  return formattedFileName;
};

const sortByEndDate = (a, b) => new Date(b.end_date || new Date()) - new Date(a.end_date || new Date());

const formatDate = (date) => {
  if (date.includes('9999')) return 'Present';
  return moment(date).format('MMM YYYY');
};

const generatePDF = (resumeData) => {
  if (!resumeData) {
    throw new Error('No resume data available');
  }

  const contactInfo = [
    resumeData.user_phone && resumeData.user_phone.trim(),
    resumeData.user_email && resumeData.user_email.trim(),
    resumeData.user_linkedin && { text: 'LinkedIn', link: resumeData.user_linkedin.trim(), color: 'blue', decoration: ['underline'] },
    resumeData.portfolio_link && { text: 'Website', link: resumeData.portfolio_link.trim(), color: 'blue', decoration: ['underline'] },
    resumeData.github_link && { text: 'GitHub', link: resumeData.github_link.trim(), color: 'blue', decoration: ['underline'] },
    resumeData.user_location && resumeData.user_location.trim()
  ].filter(Boolean);

  const contactInfoString = contactInfo.reduce((acc, info, index) => {
    if (index !== 0) acc.push({ text: ' | ', style: 'separator' });
    if (typeof info === 'string') {
      acc.push({ text: info });
    } else {
      acc.push(info);
    }
    return acc;
  }, []);

  const docDefinition = {
    content: [
      {
        text: resumeData.user_full_name,
        style: 'name'
      },
      {
        text: contactInfoString,
        style: 'contactInfo',
        margin: [0, 0, 10, 0],
      },
      {
        canvas: [{ type: 'line', x1: 0, y1: 0, x2: 555, y2: 0, lineWidth: 1 }],
        margin: [0, 2, 0, 2]
      },
      resumeData.generated_summary && {
        text: resumeData.generated_summary,
        style: 'content'
      },
      resumeData.education && resumeData.education.length > 0 && {
        stack: [
          { text: 'EDUCATION', style: 'sectionTitle' },
          { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 555, y2: 0, lineWidth: 1 }] }
        ],
        margin: [0, 1, 0, 2]
      },
      ...(resumeData.education?.sort(sortByEndDate).map(edu => ({
        margin: [0, 0, 0, 2],
        columns: [
          {
            stack: [
              {
                text: edu.institution_name,
                style: 'educationInstitution'
              },
              {
                text: `${edu.degree}, ${edu.field_of_study}`,
                style: 'educationDegree'
              }
            ],
            width: '70%'
          },
          {
            text: `${formatDate(edu.start_date)} - ${formatDate(edu.end_date)}`,
            style: 'dates',
            alignment: 'right',
            width: '30%'
          }
        ]
      })) || []),
      resumeData.skills && Object.keys(resumeData.skills).length > 0 && {
        stack: [
          { text: 'SKILLS', style: 'sectionTitle' },
          { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 555, y2: 0, lineWidth: 1 }] }
        ],
        margin: [0, 1, 0, 2]
      },
      ...(Object.entries(resumeData.skills).map(([category, skills]) => ({
        text: [{ text: `${category}: `, style: 'categoryTitle' }, { text: skills.join(', '), style: 'content' }],
        margin: [0, 0, 0, 2]
      })) || []),
      resumeData.experiences && resumeData.experiences.length > 0 && {
        stack: [
          { text: 'EXPERIENCE', style: 'sectionTitle' },
          { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 555, y2: 0, lineWidth: 1 }] }
        ],
        margin: [0, 1, 0, 2]
      },
      ...(resumeData.experiences?.sort(sortByEndDate).map(exp => ([
        {
          columns: [
            {
              text: `${exp.job_title}, ${exp.company_name}`,
              style: 'jobTitle',
              width: '70%'
            },
            {
              text: `${formatDate(exp.start_date)} - ${formatDate(exp.end_date)}`,
              style: 'dates',
              alignment: 'right',
              width: '30%'
            }
          ],
          margin: [0, 0, 0, 2]
        },
        ...exp.description.map(desc => ({
          text: `• ${desc}`,
          style: 'bulletPoint'
        }))
      ])) || []),
      resumeData.projects && resumeData.projects.length > 0 && {
        stack: [
          { text: 'PROJECTS', style: 'sectionTitle' },
          { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 555, y2: 0, lineWidth: 1 }] }
        ],
        margin: [0, 1, 0, 2]
      },
      ...(resumeData.projects?.sort(sortByEndDate).map(proj => [
        {
          columns: [
            {
              text: proj.project_title,
              style: 'projectTitle',
              width: '70%'
            },
            {
              text: `${formatDate(proj.start_date)} - ${formatDate(proj.end_date)}`,
              style: 'dates',
              alignment: 'right',
              width: '30%'
            }
          ],
          margin: [0, 0, 0, 2]
        },
        ...proj.description.map(desc => ({
          text: `• ${desc}`,
          style: 'bulletPoint'
        }))
      ]) || []),
      resumeData.certifications && resumeData.certifications.length > 0 && {
        stack: [
          { text: 'CERTIFICATIONS', style: 'sectionTitle' },
          { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 555, y2: 0, lineWidth: 1 }] }
        ],
        margin: [0, 1, 0, 2]
      },
      ...(resumeData.certifications?.map(cert => ({
        columns: [
          {
            text: `${cert.certificationName || cert.certification_name}, ${cert.issuingOrganization || cert.issuing_organization}`,
            style: 'certificationDetailsLeft',
            width: '70%'
          },
          {
            text: `${moment(cert.dateReceived || cert.date_received).format('MMM YYYY')}`,
            style: 'dates',
            alignment: 'right',
            width: '30%'
          }
        ],
        margin: [0, 0, 0, 2]
      })) || [])
    ].filter(Boolean),
    styles: {
      name: {
        fontSize: 12,
        bold: true,
        alignment: 'center',
        margin: [0, 0, 0, 2],
        textTransform: 'uppercase'
      },
      contactInfo: {
        fontSize: 10,
        alignment: 'center',
        margin: [0, 0, 0, 2]
      },
      sectionTitle: {
        fontSize: 11,
        bold: true,
        margin: [0, 1, 0, 2],
        textTransform: 'uppercase'
      },
      educationInstitution: {
        fontSize: 10,
        bold: true,
        margin: [0, 0, 0, 2]
      },
      educationDegree: {
        fontSize: 10,
        margin: [0, 0, 0, 2]
      },
      categoryTitle: {
        fontSize: 10,
        bold: true
      },
      categoryContent: {
        fontSize: 10,
        alignment: 'left',
        margin: [0, 0, 0, 2]
      },
      content: {
        fontSize: 10,
        alignment: 'justify',
        margin: [0, 0, 0, 2]
      },
      bulletPoint: {
        fontSize: 10,
        margin: [10, 0, 0, 2]
      },
      dates: {
        fontSize: 10,
        italics: true,
        alignment: 'right'
      },
      jobTitle: {
        fontSize: 10,
        bold: true,
        margin: [0, 2, 0, 2]
      },
      projectTitle: {
        fontSize: 10,
        bold: true,
        margin: [0, 0, 0, 2]
      },
      certificationDetails: {
        fontSize: 10,
        alignment: 'right',
        margin: [0, 0, 0, 2]
      },
      certificationDetailsLeft: {
        fontSize: 10,
        alignment: 'left',
        margin: [0, 0, 0, 2]
      }
    },
    defaultStyle: {
      font: 'Roboto'
    },
    pageMargins: [16, 16, 16, 16]
  };

  pdfMake.createPdf(docDefinition).download(formatFileName((resumeData.user_full_name || 'resume').replace(/\s/g, '_')));
};

export { generatePDF, formatFileName };