import React from 'react';
import { Container, Typography, Box, Link, Divider, Grid } from '@mui/material';
import { styled } from '@mui/system';

const StyledContainer = styled(Container)(({ theme }) => ({
  backgroundColor: '#f9f9f9',
  padding: theme.spacing(6, 4),
  borderRadius: theme.spacing(2),
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  marginTop: theme.spacing(4),
}));

const HeroSection = styled(Box)(({ theme }) => ({
  backgroundColor: '#0077B5',
  color: '#fff',
  padding: theme.spacing(4),
  borderRadius: theme.spacing(2),
  textAlign: 'center',
  marginBottom: theme.spacing(4),
}));

const ContentBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: '#fff',
  borderRadius: theme.spacing(1),
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(3, 0),
}));

const SectionHeading = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: 'bold',
  color: '#0077B5',
  marginBottom: theme.spacing(2),
  paddingBottom: theme.spacing(1),
}));

const TermsConditions = () => {
  return (
    <StyledContainer>
      <HeroSection>
        <Typography variant="h3" gutterBottom>
          Terms and Conditions
        </Typography>
        <Typography variant="h6">
          We are committed to transparency. Please read our terms carefully.
        </Typography>
      </HeroSection>
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <ContentBox>
            <Typography variant="h4" gutterBottom>
              Terms and Conditions
            </Typography>
            <Typography variant="body1" paragraph>
              Last updated: 21st May, 2024
            </Typography>

            <SectionHeading variant="h6">
              1. Introduction
            </SectionHeading>
            <Typography variant="body1" paragraph>
              Welcome to Careerfornia! These Terms and Conditions outline the rules and regulations for the use of our website.
              By accessing this website, we assume you accept these terms and conditions. Do not continue to use Careerfornia if you do not agree to all of the terms and conditions stated on this page.
            </Typography>

            <StyledDivider />

            <SectionHeading variant="h6">
              2. Intellectual Property Rights
            </SectionHeading>
            <Typography variant="body1" paragraph>
              Unless otherwise stated, Careerfornia and/or its licensors own the intellectual property rights for all material on Careerfornia. All intellectual property rights are reserved. You may access this from Careerfornia for your own personal use subjected to restrictions set in these terms and conditions.
            </Typography>

            <StyledDivider />

            <SectionHeading variant="h6">
              3. Restrictions
            </SectionHeading>
            <Typography variant="body1" paragraph>
              You are specifically restricted from all of the following:
            </Typography>
            <ul>
              <li>Publishing any website material in any other media</li>
              <li>Selling, sublicensing, and/or otherwise commercializing any website material</li>
              <li>Publicly performing and/or showing any website material</li>
              <li>Using this website in any way that is or may be damaging to this website</li>
              <li>Using this website in any way that impacts user access to this website</li>
            </ul>

            <StyledDivider />

            <SectionHeading variant="h6">
              4. Your Privacy
            </SectionHeading>
            <Typography variant="body1" paragraph>
              Please read our <Link href="/privacy-policy">Privacy Policy</Link>.
            </Typography>

            <StyledDivider />

            <SectionHeading variant="h6">
              5. Limitation of Liability
            </SectionHeading>
            <Typography variant="body1" paragraph>
              In no event shall Careerfornia, nor any of its officers, directors, and employees, be held liable for anything arising out of or in any way connected with your use of this website whether such liability is under contract. Careerfornia, including its officers, directors, and employees shall not be held liable for any indirect, consequential, or special liability arising out of or in any way related to your use of this website.
            </Typography>

            <StyledDivider />

            <SectionHeading variant="h6">
              6. Indemnification
            </SectionHeading>
            <Typography variant="body1" paragraph>
              You hereby indemnify to the fullest extent Careerfornia from and against any and all liabilities, costs, demands, causes of action, damages, and expenses arising in any way related to your breach of any of the provisions of these Terms.
            </Typography>

            <StyledDivider />

            <SectionHeading variant="h6">
              7. Severability
            </SectionHeading>
            <Typography variant="body1" paragraph>
              If any provision of these Terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein.
            </Typography>

            <StyledDivider />

            <SectionHeading variant="h6">
              8. Variation of Terms
            </SectionHeading>
            <Typography variant="body1" paragraph>
              Careerfornia is permitted to revise these Terms at any time as it sees fit, and by using this website you are expected to review these Terms on a regular basis.
            </Typography>

            <StyledDivider />

            <SectionHeading variant="h6">
              9. Assignment
            </SectionHeading>
            <Typography variant="body1" paragraph>
              Careerfornia is allowed to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification. However, you are not allowed to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.
            </Typography>

            <StyledDivider />

            <SectionHeading variant="h6">
              10. Entire Agreement
            </SectionHeading>
            <Typography variant="body1" paragraph>
              These Terms constitute the entire agreement between Careerfornia and you in relation to your use of this website, and supersede all prior agreements and understandings.
            </Typography>

            <StyledDivider />

            <SectionHeading variant="h6">
              11. Governing Law & Jurisdiction
            </SectionHeading>
            <Typography variant="body1" paragraph>
              These Terms will be governed by and interpreted in accordance with the laws of the State of Telangana, and you submit to the non-exclusive jurisdiction of the state and federal courts located in Telangana for the resolution of any disputes.
            </Typography>

            <StyledDivider />

            <SectionHeading variant="h6">
              12. Refund Policy
            </SectionHeading>
            <Typography variant="body1" paragraph>
              Please read our <Link href="/refunds">Refund Policy</Link> to understand our policies regarding refunds.
            </Typography>
          </ContentBox>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

export default TermsConditions;