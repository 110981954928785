import React from 'react';
import { Container, Typography, Box, Paper, Divider, Grid } from '@mui/material';
import { styled } from '@mui/system';

const StyledContainer = styled(Container)(({ theme }) => ({
  backgroundColor: '#f9f9f9',
  padding: theme.spacing(6, 4),
  borderRadius: theme.spacing(2),
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  marginTop: theme.spacing(4),
}));

const HeroSection = styled(Box)(({ theme }) => ({
  backgroundColor: '#0077B5',
  color: '#fff',
  padding: theme.spacing(4),
  borderRadius: theme.spacing(2),
  textAlign: 'center',
  marginBottom: theme.spacing(4),
}));

const ContentBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 4),
  backgroundColor: '#fff',
  borderRadius: theme.spacing(1),
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(3, 0),
}));

const RefundsPolicy = () => {
  return (
    <StyledContainer>
      <HeroSection>
        <Typography variant="h3" gutterBottom>
          No Refund Policy
        </Typography>
        <Typography variant="h6">
          We are committed to your satisfaction. Please read our policy below.
        </Typography>
      </HeroSection>
      <Grid>
        <ContentBox>
          <Typography variant="h5" gutterBottom>
            No Refund Policy
          </Typography>
          <Typography variant="body1" paragraph>
            At Careerfornia, we pride ourselves on providing the highest quality services to our users. Our services include LinkedIn Auto Apply, Customized Resumes, and AI Post Comments.
          </Typography>
          <Typography variant="body1" paragraph>
            Please be aware that we do not offer refunds for any of our services. This policy applies to all one-time purchases. Once a payment is made, it is considered final and non-refundable.
          </Typography>
          <StyledDivider />
          <Typography variant="h5" gutterBottom>
            Why No Refunds?
          </Typography>
          <Typography variant="body1" paragraph>
            We believe in the value and quality of our services. By maintaining a no-refund policy, we can focus on improving and expanding our offerings to provide the best possible experience for all users. We are committed to transparency and fairness, and our pricing reflects the value we deliver.
          </Typography>
          <Typography variant="body1" paragraph>
            We encourage you to carefully evaluate our services before making a purchase. You can explore our free resources or contact our support team for more information.
          </Typography>
          <StyledDivider />
          <Typography variant="h5" gutterBottom>
            Contact Us
          </Typography>
          <Typography variant="body1" paragraph>
            If you are dissatisfied with our service or have any concerns, please reach out to us. We value your feedback and are always looking for ways to improve. You can contact us via:
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Email:</strong> <a href="mailto:support@careerfornia.com">support@careerfornia.com</a>
          </Typography>
          <Typography variant="body1" paragraph>
            Our support team is available to assist you with any questions or concerns you may have. We are committed to ensuring that you have a positive experience with Careerfornia.
          </Typography>
          <StyledDivider />
          <Typography variant="h5" gutterBottom>
            Improving Our Services
          </Typography>
          <Typography variant="body1" paragraph>
            Your satisfaction is important to us. While we do not offer refunds, we are dedicated to continuously improving our services. Your feedback helps us identify areas for enhancement and implement changes that benefit all users.
          </Typography>
          <Typography variant="body1" paragraph>
            Thank you for choosing Careerfornia. We appreciate your understanding and support.
          </Typography>
          <StyledDivider />
          <Typography variant="h5" gutterBottom>
            Cancellation Policy
          </Typography>
          <Typography variant="body1" paragraph>
            As our services are provided on a one-time purchase basis, cancellation of services is not applicable. Once a purchase is made, you will have access to the purchased service or product as outlined at the time of purchase.
          </Typography>
          <Typography variant="body1" paragraph>
            If you have any issues with the purchased service or product, please contact our support team at <a href="mailto:support@careerfornia.com">support@careerfornia.com</a> for assistance.
          </Typography>
        </ContentBox>
      </Grid>
    </StyledContainer>
  );
};

export default RefundsPolicy;