import React from 'react';
import { motion } from 'framer-motion';
import '../../styles/report_issue.css';


const ReportIssue = () => {
    return (
        <div className="report-issue-container">
            <motion.h1
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="report-issue-title"
            >
                Report Issue
            </motion.h1>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5, duration: 0.5 }}
                className="report-issue-form"
            >
                <form>
                    <label htmlFor="issue">Describe the issue</label>
                    <textarea id="issue" name="issue" rows="4" cols="50" placeholder="Please provide a detailed description of the issue, including any steps to reproduce it if applicable. We aim to address your concerns promptly."></textarea>
                    <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        className="submit-button"
                    >
                        Submit
                    </motion.button>
                </form>


            </motion.div>

        </div>
    );
};

export default ReportIssue;
