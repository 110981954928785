import React, { useState, useRef } from 'react';
import axios from 'axios';
import 'aos/dist/aos.css';
import '../../styles/linkedin_comment.css';
import logo from '../../images/logo.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { trial_post, baseurl } from '../../util/endpoints';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt } from '@fortawesome/free-solid-svg-icons';
import { faSmile } from '@fortawesome/free-regular-svg-icons';
import { faImage } from '@fortawesome/free-regular-svg-icons';
import { useAuth0 } from '@auth0/auth0-react';

const LinkedInPostMockup = () => {
    const [showPrompt, setShowPrompt] = useState('');
    const [animateBolt, setAnimateBolt] = useState(false);
    const [typedComment, setTypedComment] = useState('');
    const [isBoltDisabled, setIsBoltDisabled] = useState(false);
    const textareaRef = useRef(null);
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

    const handleClick = async (button) => {
        const messages = {
            like: "Come on, we already have a lot of likes. Want to shake things up? Hit the thunder!",
            comment: "Almost there! But if you really want to make noise, the thunder's where it's at!",
            repost: "We love the love, but can you handle the storm? Click the thunder and find out!",
            send: "We love you too! Now, strike with the thunder and feel the power!",
        };
        setShowPrompt(messages[button]);
        setAnimateBolt(true);
        setTimeout(() => {
            setShowPrompt('');
            setAnimateBolt(false);
        }, 5000);

        // Display toast for like, comment, repost, and send actions
        if (button !== 'bolt') {
            toast.info(messages[button], {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        // Handle the 'bolt' button action
        if (button === 'bolt' && !isBoltDisabled) {
            setIsBoltDisabled(true);
            try {
                const token = await getAccessTokenSilently();
                const url = baseurl + trial_post;
                const response = await axios.get(url,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        }
                    });
                await simulateTyping(response.data.message, textareaRef.current);
            } catch (error) {
                if (error.response && error.response.status === 429) {
                    toast.error("Looks like you've hit the storm limit on your trial! Sign up now to keep the thunder rolling without boundaries!", {
                        position: "top-right",
                        autoClose: 7000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    alert('Error fetching comment: ' + error.message);
                }
            } finally {
                setIsBoltDisabled(false);
            }
        }
    }




    const simulateTyping = (message, textarea) => {
        setIsBoltDisabled(true);

        return new Promise((resolve) => {
            let words = message.split(' ');
            let typedWords = [];
            setTypedComment('');
            let index = 0;
            const interval = setInterval(() => {
                if (index < words.length) {
                    typedWords.push(words[index]);
                    setTypedComment(typedWords?.join(' '));
                    adjustTextAreaHeight(textarea);
                    index++;
                } else {
                    clearInterval(interval);
                    setIsBoltDisabled(false);
                    resolve();
                }
            }, 25);
        });
    };


    const handleTextAreaChange = (e) => {
        setTypedComment(e.target.value);
        adjustTextAreaHeight(e.target);
    };



    const adjustTextAreaHeight = (textarea) => {
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
    };

    return (
        <div>
            <div className="ai-instruction-heading">
                Try AI Post Comment Generator by clicking the <i className="fas fa-bolt" style={{ color: '#0a66c2' }}></i> in the comment box.
            </div>
            <article className="linkedin-post-mockup" data-aos="fade-up" data-aos-duration="1000">
                <header className="linkedin-post-header">
                    <img src={logo} alt="Careerfornia Logo" className="logo" />
                    <div className="post-header-details">
                        <h2>Careerfornia</h2>
                        <p>now • <span className="world-icon">🌐</span></p>
                    </div>
                </header>
                <div className="linkedin-post-body">
                    <p>To celebrate our commitment to career growth, Careerfornia is launching a new feature to help professionals stand out. Try our AI comments to revolutionize how feedback is given.</p>
                </div>
                <footer className="linkedin-post-footer">
                    <div className="like-comment-share">
                    </div>
                    <div className="post-footer-details">
                        <div className="likes-count">
                            <p>420M likes</p>
                        </div>
                        <div className="comments-reposts-count">
                            <p>2M comments • 900k reposts</p>
                        </div>
                    </div>
                    <div className="like-comment-share">
                        <button className="like" onClick={() => handleClick('like')}>
                            <span className="btn-content">Like</span>
                        </button>
                        <button className="comment" onClick={() => handleClick('comment')}>
                            <span className="btn-content">Comment</span>
                        </button>
                        <button className="repost" onClick={() => handleClick('repost')}>
                            <span>Repost</span>
                        </button>
                        <button className="send" onClick={() => handleClick('send')}>
                            <span className="btn-content">Send</span>
                        </button>
                    </div>
                    <div className="comment-field-container">
                        <img src={logo} alt="Profile" className="profile-pic" />
                        <textarea
                            ref={textareaRef}
                            className="comment-field"
                            value={typedComment}
                            onChange={handleTextAreaChange}
                            placeholder="Hit the blue thunder on the right to try the AI Comment!"
                            style={{ overflow: 'hidden', resize: 'none' }}
                        />
                        <div className="comment-icons">
                            <div className="bolt-container" style={{ position: 'relative' }}>
                                <button className={`icon-btn ${animateBolt ? 'bolt-active' : ''}`} onClick={() => handleClick('bolt')} disabled={isBoltDisabled}>
                                    <FontAwesomeIcon icon={faBolt} />
                                </button>
                                <div className="tooltip">Tap the thunder to make your comment electrifying!</div>
                            </div>
                            <button className="icon-btn">
                                <FontAwesomeIcon icon={faSmile} style={{ color: 'gray' }} />
                            </button>
                            <button className="icon-btn">
                                <FontAwesomeIcon icon={faImage} style={{ color: 'gray' }} />
                            </button>

                        </div>
                    </div>
                    <div className="note" style={{ backgroundColor: '#f0f2f5', borderRadius: '8px', }}>

                        <p style={{ margin: '0', fontStyle: 'italic', color: '#606770', }}>🌟 Fun fact - While this demo adds a touch of humor, real AI-generated comments on LinkedIn aim to be professionally uplifting, fostering a positive and encouraging environment. 🌟</p>
                    </div>
                    <div className="note" style={{ backgroundColor: '#f0f2f5', borderRadius: '8px', }}>

                        <p style={{ margin: '0', fontStyle: 'italic', color: '#606770', }}>💡 Insightful Discovery - Engaging with LinkedIn posts through comments may boost your profile's visibility more effectively than posting alone. 💡</p>
                    </div>

                </footer>
                <ToastContainer position="bottom-right" />

            </article>
        </div>
    );
};

export default LinkedInPostMockup;
