import React, { useEffect, useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, TextField, Box, Button, Checkbox, FormControlLabel, MenuItem, Select, FormControl, InputLabel, Snackbar } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import { baseurl, save_user_preferences_v2, user_details } from '../util/endpoints';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';
import countryCodes from '../util/countryCodes';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ProfileUpdatePage = () => {
    const userid = localStorage.getItem('userid');
    const { user, getAccessTokenSilently } = useAuth0();
    const { isLoading, isAuthenticated } = useAuth0();

    const [profileData, setProfileData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [changedFields, setChangedFields] = useState({});
    const [isRelocate, setIsRelocate] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfileData({
            ...profileData,
            [name]: value
        });
        setChangedFields({
            ...changedFields,
            [name]: value
        });
    };

    const handleToolsChange = (e) => {
        const { name, value } = e.target;
        setProfileData({
            ...profileData,
            [name]: value
        });
        setChangedFields({
            ...changedFields,
            [name]: value
        });
    };

    const handleRelocateChange = (e) => {
        const checked = e.target.checked;
        setIsRelocate(checked);
        setProfileData((prev) => {
            const newLocation = checked ? `${prev.location_preference || ''} (open to relocate)` : (prev.location_preference || '').replace(' (open to relocate)', '');
            return { ...prev, location_preference: newLocation };
        });
        setChangedFields((prev) => {
            const newLocation = checked ? `${prev.location_preference || ''} (open to relocate)` : (prev.location_preference || '').replace(' (open to relocate)', '');
            return { ...prev, location_preference: newLocation };
        });
    };

    const handleSubmit = async () => {
        if (user) {
            const token = await getAccessTokenSilently();
            const url = baseurl + save_user_preferences_v2;
            const updatedProfileData = {
                ...profileData,
                tools: typeof profileData.tools === 'string' 
                    ? profileData.tools.split(',').map(tool => tool.trim()).filter(tool => tool) 
                    : [],
                userid: userid
            };
            const response = await axios.post(url, updatedProfileData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            if (response.status === 200) {
                setChangedFields({});
                setOpenSnackbar(true); // Show snackbar on successful update
            } else {
                alert('Error updating profile: ' + response.statusText);
            }
        }
    };

    useEffect(() => {
        if (user) {
            const fetchData = async () => {
                const token = await getAccessTokenSilently();
                const url = baseurl + user_details;
                try {
                    const response = await axios.post(url, {
                        userid: userid
                    }, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        }
                    });
                    const data = Object.keys(response.data).reduce((acc, key) => {
                        acc[key] = response.data[key] ?? '';
                        return acc;
                    }, {});

                    // Format the start_date field to YYYY-MM-DD if it exists
                    if (data.start_date) {
                        data.start_date = new Date(data.start_date).toISOString().split('T')[0];
                    }

                    setProfileData(data);
                    setLoading(false);
                    if (data.location_preference && data.location_preference.includes('(open to relocate)')) {
                        setIsRelocate(true);
                    }
                } catch (error) {
                    alert("Error fetching user details: " + error.message);
                }
            };
            fetchData();
        }
    }, [user, userid]);

    const renderSkeleton = () => (
        <Skeleton count={5} height={40} style={{ marginBottom: '16px' }} />
    );

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!isAuthenticated) {
        return <Navigate to="/" />;
    }

    return (
        <Box sx={{ maxWidth: '600px', margin: 'auto', padding: '20px' }}>
            <Typography variant="h4" gutterBottom>
                Update Profile
            </Typography>
            {loading ? (
                renderSkeleton()) : (
                <>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Personal Information</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TextField
                                label="First Name"
                                fullWidth
                                margin="normal"
                                name="first_name"
                                value={profileData.first_name}
                                onChange={handleChange}
                            />
                            <TextField
                                label="Last Name"
                                fullWidth
                                margin="normal"
                                name="last_name"
                                value={profileData.last_name}
                                onChange={handleChange}
                            />
                            <TextField
                                label="Current Location"
                                fullWidth
                                margin="normal"
                                name="current_location"
                                value={profileData.current_location}
                                onChange={handleChange}
                            />
                            <FormControl fullWidth margin="normal" sx={{ maxWidth: '150px', marginRight: '10px' }}>
                                <InputLabel>Country Code</InputLabel>
                                <Select
                                    name="country_code"
                                    value={profileData.country_code}
                                    onChange={handleChange}
                                    label="Country Code"
                                >
                                    <MenuItem value="">Select Country Code</MenuItem>
                                    {countryCodes.map((code) => (
                                        <MenuItem key={code.value} value={code.value}>
                                            {code.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <TextField
                                label="Phone Number"
                                fullWidth
                                margin="normal"
                                name="phone"
                                value={profileData.phone}
                                onChange={handleChange}
                                inputProps={{ maxLength: 10 }}
                            />
                            <TextField
                                label="Email Address"
                                fullWidth
                                margin="normal"
                                name="email"
                                value={profileData.email}
                                onChange={handleChange}
                            />
                            <TextField
                                label="Portfolio/website"
                                fullWidth
                                margin="normal"
                                name="portfolio_link"
                                value={profileData.portfolio_link}
                                onChange={handleChange}
                            />
                            <TextField
                                label="Github"
                                fullWidth
                                margin="normal"
                                name="github_link"
                                value={profileData.github_link}
                                onChange={handleChange}
                            />
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Gender</InputLabel>
                                <Select
                                    name="gender"
                                    value={profileData.gender}
                                    onChange={handleChange}
                                    label="Gender"
                                >
                                    <MenuItem value="">Select Gender</MenuItem>
                                    <MenuItem value="male">Male</MenuItem>
                                    <MenuItem value="female">Female</MenuItem>
                                    <MenuItem value="non-binary">Non-binary</MenuItem>
                                    <MenuItem value="prefer_not_to_say">Prefer not to say</MenuItem>
                                </Select>
                            </FormControl>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Professional Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TextField
                                label="LinkedIn URL"
                                fullWidth
                                margin="normal"
                                name="linkedin"
                                value={profileData.linkedin}
                                disabled
                            />
                            <TextField
                                label="Role Preference"
                                fullWidth
                                margin="normal"
                                name="role_preference"
                                value={profileData.role_preference}
                                onChange={handleChange}
                            />
                            <TextField
                                label="Salary Range"
                                fullWidth
                                margin="normal"
                                name="salary_range"
                                value={profileData.salary_range}
                                onChange={handleChange}
                            />
                            <TextField
                                label="Job Type"
                                fullWidth
                                margin="normal"
                                name="job_type"
                                value={profileData.job_type}
                                onChange={handleChange}
                            />
                            <TextField
                                label="Start Date"
                                type="date"
                                fullWidth
                                margin="normal"
                                InputLabelProps={{ shrink: true }}
                                name="start_date"
                                value={profileData.start_date}
                                onChange={handleChange}
                            />
                            <TextField
                                label="Level"
                                fullWidth
                                margin="normal"
                                name="level"
                                value={profileData.level}
                                onChange={handleChange}
                            />
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Skills and Tools</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TextField
                                label="Tools (comma separated)"
                                fullWidth
                                margin="normal"
                                name="tools"
                                value={profileData.tools}
                                onChange={handleToolsChange}
                                helperText="Enter the values separated by commas"
                            />
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Preferences</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TextField
                                label="Location Preferences"
                                fullWidth
                                margin="normal"
                                name="location_preference"
                                value={profileData.location_preference}
                                onChange={handleChange}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isRelocate}
                                        onChange={handleRelocateChange}
                                    />
                                }
                                label="Open to relocate"
                                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 0, whiteSpace: 'nowrap' }}
                            />
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Preferred work mode</InputLabel>
                                <Select
                                    name="onsite"
                                    value={profileData.onsite}
                                    onChange={handleChange}
                                    label="Preferred work mode"
                                >
                                    <MenuItem value="">Select</MenuItem>
                                    <MenuItem value="on_site">On site</MenuItem>
                                    <MenuItem value="hybrid">Hybrid</MenuItem>
                                    <MenuItem value="remote">Remote</MenuItem>
                                    <MenuItem value="Any">No preference</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Are you willing to take a drug test in accordance with local law/regulations if required?</InputLabel>
                                <Select
                                    name="drug_test"
                                    value={profileData.drug_test}
                                    onChange={handleChange}
                                    label="Are you willing to take a drug test in accordance with local law/regulations if required?"
                                >
                                    <MenuItem value="">Select</MenuItem>
                                    <MenuItem value="yes">Yes</MenuItem>
                                    <MenuItem value="no">No</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Will you now or in the future require sponsorship to work in the U.S?</InputLabel>
                                <Select
                                    name="sponsorship"
                                    value={profileData.sponsorship}
                                    onChange={handleChange}
                                    label="Will you now or in the future require sponsorship to work in the U.S?"
                                >
                                    <MenuItem value="">Select</MenuItem>
                                    <MenuItem value="yes">Yes</MenuItem>
                                    <MenuItem value="no">No</MenuItem>
                                </Select>
                            </FormControl>
                        </AccordionDetails>
                    </Accordion>
                </>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Button variant="contained" color="primary" onClick={handleSubmit}>Update Profile</Button>
            </Box>
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    Profile updated successfully!
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ProfileUpdatePage;