import React, { useState } from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSpring, animated } from 'react-spring';

const FAQPage = () => {
    const [expanded, setExpanded] = useState(false);

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const animation = useSpring({
        opacity: expanded ? 1 : 0,
        transform: expanded ? 'translateY(0)' : 'translateY(-20px)',
    });

    const faqs = [
        {
            question: 'Do I need to download any extension for AI Resume Generation?',
            answer: 'No, you do not need to download any extension for AI Resume Generation. Our platform\'s AI-powered tools can generate tailored resumes for you without any additional downloads.',
        },
        {
            question: 'Do I need an extension to use LinkedIn Easy Apply?',
            answer: 'Yes, our platform utilizes a browser extension to streamline the LinkedIn Easy Apply process. This extension enables you to apply to multiple jobs with just a few clicks.',
        },
        {
            question: 'How do you handle and protect my personal data?',
            answer: 'Your privacy is our top priority. We use your data exclusively to enhance your experience on our platform and to create personalized resumes. We implement strict security measures to protect your information and do not sell your data to third parties. Your personal data is used only for the intended purpose of providing you with our services.',
        },
        {
            question: 'Will recruiters or LinkedIn know I’m using AI to apply for jobs?',
            answer: `No, recruiters and LinkedIn will not know that you are using AI to apply for jobs. They do not have the means to detect whether AI tools are being used in the application process.

            Moreover, many recruiters use Applicant Tracking Systems (ATS) backed by AI to filter and screen candidates, which can sometimes create an uneven playing field. To stay competitive and ensure your application stands out, using AI tools can be a strategic advantage. Our tools can help you efficiently navigate the application process and meet the criteria set by these AI-driven systems. By using AI, you can enhance your chances of success in the job market.`
        },
        {
            question: 'Is there a limit to the number of job applications I can submit using the AI?',
            answer: 'There is no strict limit to the number of job applications you can submit using our AI. However, for optimal performance and to avoid any potential issues with LinkedIn\'s terms of service, we recommend a reasonable number of applications per day. We have seen users applying to 50 jobs everyday without any problem.',
        },
        {
            question: 'What should I do if I encounter an issue with the browser extension?',
            answer: 'If you encounter any issues with the browser extension, please contact our support team at support@careerfornia.com. We will assist you in troubleshooting and resolving any problems you may face.',
        },
        {
            question: 'How do I get started with AI Resume Generation?',
            answer: 'Getting started with AI Resume Generation is simple. Sign up for an account on our platform, upload your existing resume, set preferences, navigate to the Resume Generator section, and follow the prompts to input your information. The AI will create a customized resume for you in seconds.',
        },
        {
            question: 'What types of job positions are best suited for AI Resume Generation?',
            answer: 'AI Resume Generation is suitable for a wide range of job positions across various industries. Whether you are applying for roles in technology, healthcare, finance, marketing, or other fields, our AI can tailor your resume to fit the specific job requirements.',
        },
        {
            question: 'How is my data handled on your platform?',
            answer: 'Your data is automatically and permanently deleted after 45 days of inactivity to ensure your privacy and security. We do not sell or share your data at any cost.',
        },
    ];

    return (
        <Box sx={{
            maxWidth: 800,
            margin: 'auto',
            p: 2,
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            '@media (max-width:600px)': {
                p: 1,
            },
        }}>
            <Typography variant="h4" sx={{
                mb: 2,
                pb: 8,
                textAlign: 'center',
                fontSize: { xs: '1.5rem', sm: '2.125rem' },
                fontFamily: 'Georgia, serif',
            }}>
                Frequently Asked Questions
            </Typography>
            {faqs.map((faq, index) => (
                <Box key={index} sx={{ mb: 2, width: '100%' }}>
                    <Accordion expanded={expanded === `panel${index}`} onChange={handleAccordionChange(`panel${index}`)}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography sx={{
                                fontSize: { xs: '1rem', sm: '1.25rem' },
                                fontFamily: 'Georgia, serif',
                            }}>
                                {faq.question}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <animated.div style={animation}>
                                <Typography sx={{
                                    fontSize: { xs: '0.875rem', sm: '1rem' },
                                    fontFamily: 'Georgia, serif',
                                }}>
                                    {faq.answer}
                                </Typography>
                            </animated.div>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            ))}
        </Box>
    );
};

export default FAQPage;
