import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import '../../styles/jobs_applied.css';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { baseurl, getAppliedJobs } from '../../util/endpoints';
import Lottie from 'react-lottie';
import noJobApplicationsAnimation from '../../lottie/no-job-applications.json';
import { FiArrowUpRight } from 'react-icons/fi';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
const JobsApplied = () => {
    const [jobs, setJobs] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedJobDetails, setSelectedJobDetails] = useState(null);
    const { isAuthenticated, getAccessTokenSilently, isLoading, user } = useAuth0();
    const userid = localStorage.getItem('userid');

    useEffect(() => {
        const fetchJobs = async () => {
            if (userid) {
                try {
                    const token = await getAccessTokenSilently();
                    const endpoint = baseurl + getAppliedJobs;
                    const response = await fetch(endpoint, {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ userid: userid }),
                    });

                    const data = await response.json();
                    // Sort jobs by applied_date in descending order
                    const sortedData = data.sort((a, b) => new Date(b.applied_date) - new Date(a.applied_date));
                    setJobs(sortedData);
                    setLoading(false);
                    if (data.length === 0 || data.length < 10) {
                        setHasMore(false);
                    }
                } catch (error) {
                    alert('Error fetching jobs: ' + error.message);
                    setLoading(false);
                }
            }
        };

        fetchJobs();
    }, [page, user]);

    const loadMoreJobs = () => {
        setPage(page + 1);
    };

    const handleDialogOpen = (jobDetails) => {
        setSelectedJobDetails(jobDetails);
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setSelectedJobDetails(null);
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!isAuthenticated) {
        return <Navigate to="/" />;
    }

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: noJobApplicationsAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <div className="jobs-applied">
            {loading ? (
                <div>Loading...</div>
            ) : jobs.length > 0 ? (
                <>
                    <h1>Jobs Applied Using Auto Apply with Extension</h1>
                    <InfiniteScroll
                        dataLength={jobs.length}
                        next={loadMoreJobs}
                        hasMore={hasMore}
                        // loader={<h4>Loading...</h4>}
                        endMessage={<p className="end-message">No more jobs to show</p>}
                    >
                        <div className="table-container">
                            <table className="jobs-table">
                                <thead>
                                    <tr>
                                        <th>Job Title</th>
                                        <th>Company</th>
                                        <th>Applied Date</th>
                                        <th>Job Link</th>
                                        <th>Application Details</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {jobs.map((job, index) => (
                                        <tr key={index}>
                                            <td>{job.job_title !== 'Not specified' ? job.job_title : 'N/A'}</td>
                                            <td>{job.company !== 'Not specified' ? job.company : 'N/A'}</td>
                                            <td>{new Date(job.applied_date).toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })}</td>
                                            <td>
                                                <a href={job.job_link} target="_blank" rel="noreferrer">
                                                    View Job <FiArrowUpRight />
                                                </a>
                                            </td>
                                            <td>
                                                <button
                                                    onClick={() => handleDialogOpen(job.application_details)}
                                                    style={{
                                                        padding: '5px 10px',
                                                        border: '1px solid #0077B5',
                                                        backgroundColor: 'transparent',
                                                        color: '#0077B5',
                                                        borderRadius: '4px',
                                                        cursor: 'pointer'
                                                    }}
                                                >
                                                    View Details
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </InfiniteScroll>
                </>
            ) : (
                <div className="no-jobs">
                    <Lottie options={defaultOptions} height={400} width={600} />
                    <h2 style={{ padding: '10px' }}>No Jobs Applied Yet</h2>
                    <p style={{ padding: '10px' }}>Begin applying to jobs to see them listed here.</p>
                </div>
            )}

            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle style={{ textAlign: 'center', fontWeight: 'bold' }}>Application Details</DialogTitle>
                <DialogContent style={{ textAlign: 'center' }}>
                    {selectedJobDetails ? (
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            {Object.entries(selectedJobDetails).map(([question, answer], index) => (
                                <div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #ccc', padding: '10px 0', width: '100%' }}>
                                    <strong style={{ flex: '4', textAlign: 'left' }}>{question}:</strong>
                                    <span style={{ flex: '3', textAlign: 'right' }}>{answer}</span>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p>No application details available.</p>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default JobsApplied;