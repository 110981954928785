import React from 'react';
import { motion } from 'framer-motion';
import '../../styles/announcements.css';

const Announcements = () => {
    return (
        <div className="announcement-container">
            <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5, type: 'spring', stiffness: 100 }}
                className="announcement-card"
            >
                <motion.h2
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.5, duration: 0.5 }}
                    className="announcement-title"
                >
                    New Feature Alert!
                </motion.h2>
                <motion.p
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 1, duration: 0.5 }}
                    className="announcement-message"
                >
                    We're excited to announce the launch of our latest feature. Stay tuned for more updates!
                </motion.p>
                <motion.button
                    whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
                    whileTap={{ scale: 0.95 }}
                    className="announcement-button"
                >
                    Learn More
                </motion.button>
            </motion.div>
        </div>
    );
};

export default Announcements;
