// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useDropzone } from 'react-dropzone';
// import { motion } from 'framer-motion';
// import '../styles/onboard_user.css';
// import { baseurl, upload_resume, save_user_details } from '../util/endpoints.js';
// import { useUser } from '../components/user_context.js';
// import { useAuth0 } from '@auth0/auth0-react';
// import Lottie from 'react-lottie';
// import processingAnimation from '../lottie/processing_resume.json';
// import { Navigate } from 'react-router-dom';

// const stepsTitles = ['Upload Resume', 'Add Job Preferences', 'Start Applying'];

// const OnboardUser = () => {
//     // const user = useUser();
//     const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
//     const userid = localStorage.getItem('userid');
//     const [selectedFile, setSelectedFile] = useState(null);
//     const navigate = useNavigate();
//     const [accessToken, setAccessToken] = useState('');
//     // const { isAuthenticated, getAccessTokenSilently } = useAuth0();
//     const [isUploading, setIsUploading] = useState(false);
//     const [elapsedTime, setElapsedTime] = useState(0);
//     const { getRootProps, getInputProps, isDragActive } = useDropzone({
//         onDrop: (acceptedFiles) => {
//             setSelectedFile(acceptedFiles[0]);
//         }
//     });

//     useEffect(() => {
//         let timer;
//         if (isUploading) {
//             timer = setInterval(() => {
//                 setElapsedTime(prevTime => prevTime + 1);
//             }, 1000);
//         } else {
//             setElapsedTime(0);
//         }
//         return () => clearInterval(timer);
//     }, [isUploading]);

//     const handleResumeUpload = (event) => {
//         setSelectedFile(event.target.files[0]);
//     };

//     const uploadFile = async (file, userid) => {
//         setIsUploading(true);
//         const formData = new FormData();
//         formData.append('resume_file', file);
//         formData.append('userid', userid);

//         try {
//             const token = await getAccessTokenSilently();
//             const endpoint = baseurl + upload_resume;
//             const response = await fetch(endpoint, {
//                 method: 'POST',
//                 headers: {
//                     'Authorization': `Bearer ${token}`,
//                 },
//                 body: formData,
//             });


//             if (response.ok) {
//                 const data = await response.json();
//                 // Navigate to ResumePage with response data
//                 navigate('/resume-page', { state: { resumeData: data } });
//             } else {
//                 throw new Error(response.statusText);
//             }
//         } catch (error) {
//             alert('Error uploading file: ' + error.message);
//         } finally {
//             setIsUploading(false);
//         }
//     };

//     const handleUploadClick = () => {
//         if (user && selectedFile) {
//             const userid = user.sub;
//             uploadFile(selectedFile, userid);
//         } else if (!user) {
//             alert('User details are not available yet. Please try again in a few seconds.');
//         } else {
//             alert('Please select a file to upload.');
//         }
//     };

//     useEffect(() => {
//         if (isAuthenticated) {
//             sendUserDetailsToBackend();
//         }
//     }, [isAuthenticated, getAccessTokenSilently, user]);

//     const sendUserDetailsToBackend = async () => {
//         try {
//             const token = await getAccessTokenSilently();
//             const endpoint = baseurl + save_user_details;
//             const response = await fetch(endpoint, {
//                 method: 'POST',
//                 headers: {
//                     'Authorization': `Bearer ${token}`,
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({
//                     email: user.email,
//                     username: user.nickname,
//                     userid: user.sub
//                 }),
//             });

//             if (!response.ok) {
//                 const errorBody = await response.text();
//                 alert(`HTTP error! status: ${response.status}, body: ${errorBody}`);

//                 throw new Error(`HTTP error! status: ${response.status}`);
//             }

//             const data = await response.json();
//         } catch (error) {
//         }
//     };

//     const defaultOptions = {
//         loop: true,
//         autoplay: true,
//         animationData: processingAnimation,
//         rendererSettings: {
//             preserveAspectRatio: 'xMidYMid slice'
//         }
//     };

//     if (isLoading) {
//         return <div>Loading...</div>;
//     }

//     if (!isAuthenticated) {
//         return <Navigate to="/" />;
//     }

//     return (
//         <div className="get-started-container">
//             <div className="progress-bar">
//                 {stepsTitles.map((title, index) => (
//                     <div key={title} className="step-container">
//                         <div className={`step ${index === 0 ? 'active-step' : ''}`}>
//                             {index + 1}
//                         </div>
//                         <div className="step-title">{title}</div>
//                     </div>
//                 ))}
//             </div>

//             <div className="upload-section">
//                 <h2>Upload your resume to get started</h2>
//                 {/* <p>Don't have a resume? Try a sample scan</p> */}
//                 <div {...getRootProps()} className="dropzone">
//                     <input {...getInputProps()} />
//                     {isDragActive ? (
//                         <p>Drop the files here ...</p>
//                     ) : (
//                         <p>Drag 'n' drop your resume here, or click to select file</p>
//                     )}
//                 </div>
//                 {selectedFile && (
//                     <p className="selected-file-name">Selected file: {selectedFile.name}</p>
//                 )}
//                 <button
//                     onClick={handleUploadClick}
//                     disabled={!selectedFile || isUploading}
//                     style={{ backgroundColor: (!selectedFile || isUploading) ? 'gray' : '' }}
//                 >
//                     Upload your resume
//                 </button>
//                 <p className="file-types">as .pdf or .docx file</p>
//                 {isUploading && (
//                     <div className="processing-info">
//                         <div className="processing-animation">
//                             <Lottie options={defaultOptions} height={200} width={200} />
//                         </div>
//                         <p>Processing your resume. It may take about a minute.</p>
//                         <p>Time elapsed: {elapsedTime} seconds</p>
//                     </div>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default OnboardUser;



import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import '../styles/onboard_user.css';
import { baseurl, upload_resume, save_user_details } from '../util/endpoints.js';
import { useAuth0 } from '@auth0/auth0-react';
import Lottie from 'react-lottie';
import processingAnimation from '../lottie/processing_resume.json';
import { Navigate } from 'react-router-dom';

const stepsTitles = ['Upload Resume', 'Add Job Preferences', 'Start Applying'];

const OnboardUser = () => {
    const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    const [selectedFile, setSelectedFile] = useState(null);
    const navigate = useNavigate();
    const [isUploading, setIsUploading] = useState(false);
    const [elapsedTime, setElapsedTime] = useState(0);
    const [error, setError] = useState(null);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: (acceptedFiles) => {
            setSelectedFile(acceptedFiles[0]);
        }
    });

    useEffect(() => {
        let timer;
        if (isUploading) {
            timer = setInterval(() => {
                setElapsedTime(prevTime => prevTime + 1);
            }, 1000);
        } else {
            setElapsedTime(0);
        }
        return () => clearInterval(timer);
    }, [isUploading]);

    const handleResumeUpload = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const uploadFile = async (file, userid) => {
        setIsUploading(true);
        const formData = new FormData();
        formData.append('resume_file', file);
        formData.append('userid', userid);

        try {
            const token = await getAccessTokenSilently();
            const endpoint = baseurl + upload_resume;
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData,
            });

            if (response.ok) {
                const data = await response.json();
                navigate('/resume-page', { state: { resumeData: data } });
            } else {
                throw new Error('Error uploading file. Please try again.');
            }
        } catch (error) {
            setError('Error uploading file. Please try again.');
        } finally {
            setIsUploading(false);
        }
    };

    const handleUploadClick = () => {
        if (user && selectedFile) {
            const userid = user.sub;
            uploadFile(selectedFile, userid);
        } else if (!user) {
            setError('User details are not available yet. Please try again in a few seconds.');
        } else {
            setError('Please select a file to upload.');
        }
    };

    useEffect(() => {
        if (isAuthenticated) {
            sendUserDetailsToBackend();
        }
    }, [isAuthenticated, getAccessTokenSilently, user]);

    const sendUserDetailsToBackend = async () => {
        try {
            const token = await getAccessTokenSilently();
            const endpoint = baseurl + save_user_details;
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: user.email,
                    username: user.nickname,
                    userid: user.sub
                }),
            });

            if (!response.ok) {
                const errorBody = await response.json();
                if (response.status === 400) {
                    setError('Required fields are missing. Please complete your profile.');
                } else if (response.status === 500 && errorBody.error.includes('Validation error')) {
                    setError('User not onboarded yet.');
                } else {
                    setError('An unexpected error occurred. Please try again later.');
                }
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            if (!error.message.includes('Validation error')) {
                setError('An unexpected error occurred. Please try again later.');
            }
        }
    };

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: processingAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!isAuthenticated) {
        return <Navigate to="/" />;
    }

    return (
        <div className="get-started-container">
            <div className="progress-bar">
                {stepsTitles.map((title, index) => (
                    <div key={title} className="step-container">
                        <div className={`step ${index === 0 ? 'active-step' : ''}`}>
                            {index + 1}
                        </div>
                        <div className="step-title">{title}</div>
                    </div>
                ))}
            </div>

            <div className="upload-section">
                <h2>Upload your resume to get started</h2>
                <div {...getRootProps()} className="dropzone">
                    <input {...getInputProps()} />
                    {isDragActive ? (
                        <p>Drop the files here ...</p>
                    ) : (
                        <p>Drag 'n' drop your resume here, or click to select file</p>
                    )}
                </div>
                {selectedFile && (
                    <p className="selected-file-name">Selected file: {selectedFile.name}</p>
                )}
                <button
                    onClick={handleUploadClick}
                    disabled={!selectedFile || isUploading}
                    style={{ backgroundColor: (!selectedFile || isUploading) ? 'gray' : '' }}
                >
                    Upload your resume
                </button>
                <p className="file-types">as .pdf or .docx file</p>
                {isUploading && (
                    <div className="processing-info">
                        <div className="processing-animation">
                            <Lottie options={defaultOptions} height={200} width={200} />
                        </div>
                        <p>Processing your resume. It may take about a minute.</p>
                        <p>Time elapsed: {elapsedTime} seconds</p>
                    </div>
                )}
                {/* {error && (
                    <div className="error-message">
                        <p>{error}</p>
                    </div>
                )} */}
            </div>
        </div>
    );
};

export default OnboardUser;