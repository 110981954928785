// import React, { useEffect, useState } from 'react';
// import { motion } from 'framer-motion';
// import { useLocation, Navigate } from 'react-router-dom';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import moment from 'moment';
// import { baseurl, save_user_resume_details, get_resume_data } from '../util/endpoints';
// import '../styles/resume_page.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTrash } from '@fortawesome/free-solid-svg-icons';
// import { useAuth0 } from '@auth0/auth0-react';
// import { useNavigate } from 'react-router-dom';

// const ResumePage = () => {
//     const { user, isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
//     const location = useLocation();
//     const navigate = useNavigate();
//     const [resumeData, setResumeData] = useState({
//         experiences: [],
//         education: [],
//         user_projects: [],
//         certifications: [],
//     });
//     const userid = localStorage.getItem('userid');
//     const [validationErrors, setValidationErrors] = useState({});
//     const [loading, setLoading] = useState(false);

//     useEffect(() => {
//         if (userid) {
//             const loadedResumeData = location.state?.resumeData?.data?.resume;
//             if (!loadedResumeData) {
//                 const fetchResumeData = async () => {
//                     if (userid) {
//                         const requestBody = { userid: userid };
//                         try {
//                             const token = await getAccessTokenSilently();
//                             const url = baseurl + get_resume_data;
//                             const response = await fetch(url, {
//                                 method: 'POST',
//                                 headers: {
//                                     'Authorization': `Bearer ${token}`,
//                                     'Content-Type': 'application/json',
//                                 },
//                                 body: JSON.stringify(requestBody),
//                             });

//                             const data = await response.json();

//                             if (data.message !== "User not onboarded") {
//                                 setResumeData({
//                                     experiences: data.data.resume.experiences.experiences || [],
//                                     education: data.data.resume.education.education || [],
//                                     user_projects: data.data.resume.user_projects || [],
//                                     certifications: data.data.resume.certifications.Certifications || [],
//                                 });
//                             }
//                         } catch (error) {
//                             alert('Failed to fetch resume data: ' + error.message);
//                         }
//                     }
//                 };

//                 fetchResumeData();
//             }
//         }
//     }, [location.state]);

//     useEffect(() => {
//         const loadedResumeData = location.state?.resumeData?.data?.resume;
//         if (loadedResumeData) {
//             setResumeData({
//                 experiences: loadedResumeData.experiences.experiences || [],
//                 education: loadedResumeData.education.education || [],
//                 user_projects: loadedResumeData.user_projects || [],
//                 certifications: loadedResumeData.certifications.Certifications || [],
//             });
//         }
//     }, [location.state]);

//     const handleUpdate = (section, index, key, value) => {
//         const updatedData = { ...resumeData };
//         const updatedSection = updatedData[section];
//         updatedSection[index] = { ...updatedSection[index], [key]: value };
//         updatedData[section] = updatedSection;
//         setResumeData(updatedData);
//     };

//     const handleDelete = (section, index) => {
//         setResumeData(prevData => ({
//             ...prevData,
//             [section]: prevData[section].filter((_, idx) => idx !== index),
//         }));
//     };

//     const EditableField = ({ value, onChange, fieldType = 'text', error }) => {
//         const [fieldValue, setFieldValue] = useState(value);

//         const handleChange = (e) => setFieldValue(e.target.value);

//         const handleBlur = () => {
//             onChange(fieldValue);
//         };

//         return fieldType === 'textarea' ? (
//             <textarea
//                 value={fieldValue}
//                 onChange={handleChange}
//                 onBlur={handleBlur}
//                 className={`editable-textarea ${error ? 'error-field' : ''}`}
//             />
//         ) : (
//             <input
//                 type={fieldType}
//                 value={fieldValue}
//                 onChange={handleChange}
//                 onBlur={handleBlur}
//                 className={`editable-input ${error ? 'error-field' : ''}`}
//             />
//         );
//     };

//     const handleSaveChanges = async () => {
//         setLoading(true);

//         const errors = validateResumeData(resumeData);
//         setValidationErrors({ ...errors });
//         if (Object.keys(errors)?.length > 0) {
//             setLoading(false);

//             alert('Validation failed: ' + JSON.stringify(errors));
//             Object.values(errors).forEach(errorMessage => {
//                 toast.error(errorMessage, {
//                     position: "top-right",
//                     autoClose: false,
//                     hideProgressBar: false,
//                     closeOnClick: true,
//                     pauseOnHover: true,
//                     draggable: true,
//                     progress: undefined,
//                 });
//             });
//             return;
//         }
//         const userid = localStorage.getItem('userid'); // Retrieve userid from localStorage

//         const formattedData = {
//             userid,
//             data: {
//                 experiences: resumeData.experiences,
//                 education: resumeData.education,
//                 projects: resumeData.user_projects,
//                 certifications: resumeData.certifications,
//             },
//         };

//         try {
//             const token = await getAccessTokenSilently();
//             const url = baseurl + save_user_resume_details;
//             const response = await fetch(url, {
//                 method: 'POST',
//                 headers: {
//                     'Authorization': `Bearer ${token}`,
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify(formattedData),
//             });

//             if (!response.ok) {
//                 throw new Error(`HTTP error! Status: ${response.status}`);
//             }

//             const result = await response.json();

//             if (result.message === "Resume data processed and saved successfully") {
//                 const loadedResumeData = location.state?.resumeData?.data?.resume;
//                 if (loadedResumeData) {
//                     window.location.href = "/job-preferences";
//                     // navigate('/onboard-user');
//                 }
//                 else {
//                     setLoading(false);
//                     toast.success("Resume edited successfully", {
//                         autoClose: 2000,
//                         position: "top-center",
//                     });
//                 }
//             } else {
//                 toast.error(result.message, {
//                     position: "top-right",
//                     autoClose: 5000,
//                     hideProgressBar: false,
//                     closeOnClick: true,
//                     pauseOnHover: true,
//                     draggable: true,
//                     progress: undefined,
//                 });
//             }
//         } catch (error) {
//             alert('Failed to save resume data: ' + error.message);
//             toast.error("An error occurred while saving resume data.", {
//                 position: "top-right",
//                 autoClose: 5000,
//                 hideProgressBar: false,
//                 closeOnClick: true,
//                 pauseOnHover: true,
//                 draggable: true,
//                 progress: undefined,
//             });
//         } finally {
//             setLoading(false); // Stop loading regardless of the outcome
//         }
//     };

//     const validateResumeData = (data) => {
//         const errors = {};

//         // Validate experiences
//         if (data.experiences && data.experiences?.length > 0) {
//             data.experiences.forEach((exp, index) => {
//                 let missingFields = [];
//                 if (!exp.job_title) missingFields.push("Job Title");
//                 if (!exp.company_name) missingFields.push("Company Name");
//                 if (!exp.start_date || !moment(exp.start_date, 'YYYY-MM-DD', true).isValid()) {
//                     missingFields.push("Start Date");
//                 }
//                 if (!exp.end_date || !moment(exp.end_date, 'YYYY-MM-DD', true).isValid()) {
//                     missingFields.push("End Date");
//                 }
//                 if (!exp.description) missingFields.push("Description");
//                 if (missingFields?.length > 0) {
//                     errors[`experiences_${index}`] = `Missing or invalid fields in Experience ${index + 1}: ${missingFields?.join(", ")}`;
//                 }
//             });
//         }

//         // Validate education
//         if (data.education && data.education?.length > 0) {
//             data.education.forEach((edu, index) => {
//                 let missingFields = [];
//                 if (!edu.institution_name) missingFields.push("Institution Name");
//                 if (!edu.degree) missingFields.push("Degree");
//                 if (!edu.field_of_study) missingFields.push("Field of Study");
//                 if (!edu.start_date || !moment(edu.start_date, 'YYYY-MM-DD', true).isValid()) {
//                     missingFields.push("Start Date");
//                 }
//                 if (!edu.end_date || !moment(edu.end_date, 'YYYY-MM-DD', true).isValid()) {
//                     missingFields.push("End Date");
//                 }
//                 if (missingFields?.length > 0) {
//                     errors[`education_${index}`] = `Missing or invalid fields in Education ${index + 1}: ${missingFields?.join(", ")}`;
//                 }
//             });
//         }

//         // Validate projects
//         if (data.user_projects && data.user_projects?.length > 0) {
//             data.user_projects.forEach((project, index) => {
//                 let missingFields = [];
//                 if (!project.project_name) missingFields.push("Project Name");
//                 if (!project.technologies_used) missingFields.push("Technologies Used");
//                 if (!project.start_date || !moment(project.start_date, 'YYYY-MM-DD', true).isValid()) {
//                     missingFields.push("Start Date");
//                 }
//                 if (!project.end_date || !moment(project.end_date, 'YYYY-MM-DD', true).isValid()) {
//                     missingFields.push("End Date");
//                 }
//                 if (!project.description) missingFields.push("Description");
//                 if (missingFields?.length > 0) {
//                     errors[`projects_${index}`] = `Missing or invalid fields in Project ${index + 1}: ${missingFields?.join(", ")}`;
//                 }
//             });
//         }

//         // Validate certifications
//         if (data.certifications && data.certifications?.length > 0) {
//             data.certifications.forEach((cert, index) => {
//                 let missingFields = [];
//                 if (!cert.certification_name) missingFields.push("Certification Name");
//                 if (!cert.issued_by) missingFields.push("Issued By");
//                 if (!cert.issued_date) missingFields.push("Issued Date");
//                 if (!cert.valid_till) missingFields.push("Valid till");

//                 if (cert.issued_date && !moment(cert.issued_date, 'YYYY-MM-DD', true).isValid()) {
//                     missingFields.push("Invalid format for Issued Date");
//                 }
//                 if (cert.valid_till && !moment(cert.valid_till, 'YYYY-MM-DD', true).isValid()) {
//                     missingFields.push("Invalid format for Valid Till");
//                 }

//                 // Validate date order
//                 if (cert.issued_date && cert.valid_till && moment(cert.issued_date).isAfter(moment(cert.valid_till))) {
//                     missingFields.push("Issued Date must be before Valid Till");
//                 }

//                 if (!cert.description) missingFields.push("Description");
//                 if (missingFields?.length > 0) {
//                     errors[`certifications_${index}`] = `Missing fields in Certification ${index + 1}: ${missingFields?.join(", ")}`;
//                 }
//             });
//         }

//         return errors;
//     };

//     const handleAddItem = (section) => {
//         const newItem = getNewItemForSection(section);
//         setResumeData(prevData => ({
//             ...prevData,
//             [section]: [newItem, ...prevData[section]],
//         }));
//     };

//     const getNewItemForSection = (section) => {
//         switch (section) {
//             case 'experiences':
//                 return { job_title: '', company_name: '', start_date: '', end_date: '', description: '' };
//             case 'education':
//                 return { institution_name: '', degree: '', start_date: '', end_date: '', field_of_study: '' };
//             case 'user_projects':
//                 return { project_name: '', technologies_used: '', start_date: '', end_date: '', description: '' };
//             case 'certifications':
//                 return { certification_name: '', issued_by: '', issued_date: '', valid_till: '', description: '' };
//             default:
//                 return {};
//         }
//     };

//     const ExperiencesSection = ({ experiences = [], onUpdate, onDelete, validationErrors = {} }) => (
//         <div className="resume-section">
//             <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//                 <h2>Experiences</h2>
//                 <button onClick={() => handleAddItem('experiences')} style={{ cursor: 'pointer', color: '#333', border: '1px solid black', backgroundColor: 'transparent', padding: '5px 10px', borderRadius: '5px' }}>
//                     + Add Experience
//                 </button>
//             </div>
//             {experiences.length > 0 ? (
//                 experiences.map((exp, index) => (
//                     <div key={index} className="resume-item" style={{ position: 'relative' }}>
//                         <div className="field-group">
//                             <label>Job Title</label>
//                             <EditableField
//                                 value={exp.job_title}
//                                 onChange={(value) => onUpdate('experiences', index, 'job_title', value)}
//                                 error={validationErrors[`experiences_${index}`]}
//                             />
//                         </div>
//                         <div className="field-group">
//                             <label>Company Name</label>
//                             <EditableField
//                                 value={exp.company_name}
//                                 onChange={(value) => onUpdate('experiences', index, 'company_name', value)}
//                                 error={validationErrors[`experiences_${index}`]}
//                             />
//                         </div>
//                         <div className="field-group">
//                             <label>Start Date</label>
//                             <EditableField
//                                 value={exp.start_date}
//                                 onChange={(value) => onUpdate('experiences', index, 'start_date', value)}
//                                 fieldType="date"
//                                 error={validationErrors[`experiences_${index}`]}
//                             />
//                         </div>
//                         <div className="field-group">
//                             <label>End Date</label>
//                             <EditableField
//                                 value={exp.end_date}
//                                 onChange={(value) => onUpdate('experiences', index, 'end_date', value)}
//                                 fieldType="date"
//                                 error={validationErrors[`experiences_${index}`]}
//                             />
//                         </div>
//                         <div className="field-group">
//                             <label>Description</label>
//                             <EditableField
//                                 value={exp.description}
//                                 onChange={(value) => onUpdate('experiences', index, 'description', value)}
//                                 fieldType="textarea"
//                                 error={validationErrors[`experiences_${index}`]}
//                             />
//                         </div>
//                         <button className="delete-icon" onClick={() => onDelete('experiences', index)} style={{ cursor: 'pointer', backgroundColor: 'transparent', border: 'none', color: '#333', padding: '4px', borderRadius: '5px', outline: 'none' }}>
//                             <FontAwesomeIcon icon={faTrash} />
//                         </button>
//                     </div>
//                 ))
//             ) : (
//                 <p>No experiences listed.</p>
//             )}
//         </div>
//     );

//     const ProjectsSection = ({ projects, onUpdate, onDelete, validationErrors }) => (
//         <div className="resume-section">
//             <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//                 <h2>Projects</h2>
//                 <button onClick={() => handleAddItem('user_projects')} style={{ cursor: 'pointer', color: '#333', border: '1px solid black', backgroundColor: 'transparent', padding: '5px 10px', borderRadius: '5px' }}>
//                     + Add Projects
//                 </button>
//             </div>
//             {projects?.length > 0 ? (
//                 projects.map((project, index) => (
//                     <div key={index} className="resume-item" style={{ position: 'relative' }}>
//                         <div className="field-group">
//                             <label>Project Name</label>
//                             <EditableField
//                                 value={project.project_name}
//                                 onChange={(value) => onUpdate('user_projects', index, 'project_name', value)}
//                                 error={validationErrors[`projects_${index}`]}
//                             />
//                         </div>
//                         <div className="field-group">
//                             <label>Technologies Used</label>
//                             <EditableField
//                                 value={project.technologies_used}
//                                 onChange={(value) => onUpdate('user_projects', index, 'technologies_used', value)}
//                                 error={validationErrors[`projects_${index}`]}
//                             />
//                         </div>
//                         <div className="field-group">
//                             <label>Start Date</label>
//                             <EditableField
//                                 value={project.start_date}
//                                 onChange={(value) => onUpdate('user_projects', index, 'start_date', value)}
//                                 fieldType="date"
//                                 error={validationErrors[`projects_${index}`]}
//                             />
//                         </div>
//                         <div className="field-group">
//                             <label>End Date</label>
//                             <EditableField
//                                 value={project.end_date}
//                                 onChange={(value) => onUpdate('user_projects', index, 'end_date', value)}
//                                 fieldType="date"
//                                 error={validationErrors[`projects_${index}`]}
//                             />
//                         </div>
//                         <div className="field-group">
//                             <label>Description</label>
//                             <EditableField
//                                 value={project.description}
//                                 onChange={(value) => onUpdate('user_projects', index, 'description', value)}
//                                 fieldType="textarea"
//                                 error={validationErrors[`projects_${index}`]}
//                             />
//                         </div>
//                         <button className="delete-icon" onClick={() => onDelete('user_projects', index)} style={{ cursor: 'pointer', backgroundColor: 'transparent', border: 'none', color: '#333', padding: '4px', borderRadius: '5px', outline: 'none' }}>
//                             <FontAwesomeIcon icon={faTrash} />
//                         </button>
//                     </div>
//                 ))
//             ) : (
//                 <p>No projects listed.</p>
//             )}
//         </div>
//     );

//     const EducationSection = ({ education = [], onUpdate, onDelete, validationErrors = {} }) => (
//         <div className="resume-section">
//             <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//                 <h2>Education</h2>
//                 <button onClick={() => handleAddItem('education')} style={{ cursor: 'pointer', color: '#333', border: '1px solid black', backgroundColor: 'transparent', padding: '5px 10px', borderRadius: '5px' }}>
//                     + Add Education
//                 </button>
//             </div>
//             {education.length > 0 ? (
//                 education.map((edu, index) => (
//                     <div key={index} className="resume-item" style={{ position: 'relative' }}>
//                         <div className="field-group">
//                             <label>Institution Name</label>
//                             <EditableField
//                                 value={edu.institution_name}
//                                 onChange={(value) => onUpdate('education', index, 'institution_name', value)}
//                                 error={validationErrors[`education_${index}`]}
//                             />
//                         </div>
//                         <div className="field-group">
//                             <label>Degree</label>
//                             <EditableField
//                                 value={edu.degree}
//                                 onChange={(value) => onUpdate('education', index, 'degree', value)}
//                                 error={validationErrors[`education_${index}`]}
//                             />
//                         </div>
//                         <div className="field-group">
//                             <label>Field of Study</label>
//                             <EditableField
//                                 value={edu.field_of_study}
//                                 onChange={(value) => onUpdate('education', index, 'field_of_study', value)}
//                                 error={validationErrors[`education_${index}`]}
//                             />
//                         </div>
//                         <div className="field-group">
//                             <label>Start Date</label>
//                             <EditableField
//                                 value={edu.start_date}
//                                 onChange={(value) => onUpdate('education', index, 'start_date', value)}
//                                 fieldType="date"
//                                 error={validationErrors[`education_${index}`]}
//                             />
//                         </div>
//                         <div className="field-group">
//                             <label>End Date</label>
//                             <EditableField
//                                 value={edu.end_date}
//                                 onChange={(value) => onUpdate('education', index, 'end_date', value)}
//                                 fieldType="date"
//                                 error={validationErrors[`education_${index}`]}
//                             />
//                         </div>
//                         <button className="delete-icon" onClick={() => onDelete('education', index)} style={{ cursor: 'pointer', backgroundColor: 'transparent', border: 'none', color: '#333', padding: '4px', borderRadius: '5px', outline: 'none' }}>
//                             <FontAwesomeIcon icon={faTrash} />
//                         </button>
//                     </div>
//                 ))
//             ) : (
//                 <p>No education listed.</p>
//             )}
//         </div>
//     );

//     const CertificationsSection = ({ certifications = [], onUpdate, onDelete, validationErrors = {} }) => (
//         <div className="resume-section">
//             <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//                 <h2>Certifications</h2>
//                 <button onClick={() => handleAddItem('certifications')} style={{ cursor: 'pointer', color: '#333', border: '1px solid black', backgroundColor: 'transparent', padding: '5px 10px', borderRadius: '5px' }}>
//                     + Add Certification
//                 </button>
//             </div>
//             {certifications.length > 0 ? (
//                 certifications.map((cert, index) => (
//                     <div key={index} className="resume-item" style={{ position: 'relative' }}>
//                         <div className="field-group">
//                             <label>Certification Title</label>
//                             <EditableField
//                                 value={cert.certification_name}
//                                 onChange={(value) => onUpdate('certifications', index, 'certification_name', value)}
//                                 error={validationErrors[`certifications_${index}`]}
//                             />
//                         </div>
//                         <div className="field-group">
//                             <label>Issued By</label>
//                             <EditableField
//                                 value={cert.issued_by}
//                                 onChange={(value) => onUpdate('certifications', index, 'issued_by', value)}
//                                 error={validationErrors[`certifications_${index}`]}
//                             />
//                         </div>
//                         <div className="field-group">
//                             <label>Issued Date</label>
//                             <EditableField
//                                 value={cert.issued_date}
//                                 onChange={(value) => onUpdate('certifications', index, 'issued_date', value)}
//                                 fieldType="date"
//                                 error={validationErrors[`certifications_${index}`]}
//                             />
//                         </div>
//                         <div className="field-group">
//                             <label>Valid Till</label>
//                             <EditableField
//                                 value={cert.valid_till}
//                                 onChange={(value) => onUpdate('certifications', index, 'valid_till', value)}
//                                 fieldType="date"
//                                 error={validationErrors[`certifications_${index}`]}
//                             />
//                         </div>
//                         <div className="field-group">
//                             <label>Description</label>
//                             <EditableField
//                                 value={cert.description}
//                                 onChange={(value) => onUpdate('certifications', index, 'description', value)}
//                                 fieldType="textarea"
//                                 error={validationErrors[`certifications_${index}`]}
//                             />
//                         </div>
//                         <button className="delete-icon" onClick={() => onDelete('certifications', index)} style={{ cursor: 'pointer', backgroundColor: 'transparent', border: 'none', color: '#333', padding: '4px', borderRadius: '5px', outline: 'none' }}>
//                             <FontAwesomeIcon icon={faTrash} />
//                         </button>
//                     </div>
//                 ))
//             ) : (
//                 <p>No certifications listed.</p>
//             )}
//         </div>
//     );

//     if (isLoading) {
//         return <div>Loading...</div>;
//     }

//     if (!isAuthenticated) {
//         return <Navigate to="/" />;
//     }



//     return (
//         <motion.div
//             className="resume-container"
//             initial={{ opacity: 0 }}
//             animate={{ opacity: 1, transition: { delay: 0.5, duration: 0.5 } }}
//         >
//             <div className="resume-sections">
//                 <h1>Review Your Details</h1>
//                 <p>A minimum of one experience & 2 projects will give the best results</p>

//                 <>
//                     <EducationSection
//                         education={resumeData.education}
//                         onUpdate={handleUpdate}
//                         onDelete={handleDelete}
//                         validationErrors={validationErrors}
//                     />

//                     <ExperiencesSection
//                         experiences={resumeData.experiences}
//                         onUpdate={handleUpdate}
//                         onDelete={handleDelete}
//                         validationErrors={validationErrors}
//                     />

//                     <ProjectsSection
//                         projects={resumeData.user_projects}
//                         onUpdate={handleUpdate}
//                         onDelete={handleDelete}
//                         validationErrors={validationErrors}
//                     />

//                     <CertificationsSection
//                         certifications={resumeData.certifications}
//                         onUpdate={handleUpdate}
//                         onDelete={handleDelete}
//                         validationErrors={validationErrors}
//                     />

//                     <ToastContainer />

//                     <button
//                         className="save-button"
//                         onClick={handleSaveChanges}
//                         disabled={loading}
//                         style={{
//                             cursor: loading ? 'not-allowed' : 'pointer',
//                             backgroundColor: loading ? '#ccc' : '#0077B5',
//                             color: loading ? '#666' : '#fff'
//                         }}
//                     >
//                         {loading ? <div className="loading-spinner"></div> : 'Save Changes'}
//                     </button>
//                 </>
//             </div>
//         </motion.div>
//     );

// }

// export default ResumePage;


import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useLocation, Navigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { baseurl, save_user_resume_details, get_resume_data } from '../util/endpoints';
import '../styles/resume_page.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

const ResumePage = () => {
    const { user, isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const location = useLocation();
    const navigate = useNavigate();
    const [resumeData, setResumeData] = useState({
        experiences: [],
        education: [],
        user_projects: [],
        certifications: [],
    });
    const userid = localStorage.getItem('userid');
    const [validationErrors, setValidationErrors] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function fetchAndProcessResumeData() {
            if (userid) {
                try {
                    const token = await getAccessTokenSilently();
                    const url = baseurl + get_resume_data;
                    const response = await fetch(url, {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ userid }),
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }

                    const data = await response.json();
                    if (data.message !== "User not onboarded") {
                        const validatedData = processResumeData(data.data.resume);
                        setResumeData(validatedData);
                    }
                } catch (error) {
                    alert('Failed to fetch resume data: ' + error.message);
                }
            }
        }

        fetchAndProcessResumeData();
    }, [userid, getAccessTokenSilently, location.state]);

    function processResumeData(resume) {
        return {
            experiences: resume.experiences.experiences.map(exp => ({
                ...exp,
                is_present: exp.end_date === '9999-12-12'
            })),
            education: resume.education.education.map(edu => ({
                ...edu,
                is_present: edu.end_date === '9999-12-12'
            })),
            user_projects: resume.user_projects.map(proj => ({
                ...proj,
                is_present: proj.end_date === '9999-12-12'
            })),
            certifications: resume.certifications.Certifications.map(cert => ({
                ...cert,
                is_present: cert.valid_till === '9999-12-12'
            })),
        };
    }

    useEffect(() => {
        const loadedResumeData = location.state?.resumeData?.data?.resume;
        if (loadedResumeData) {
            setResumeData({
                experiences: loadedResumeData.experiences?.experiences?.map(exp => ({
                    ...exp,
                    is_present: exp.end_date === '9999-12-12'
                })) || [],
                education: loadedResumeData.education?.education?.map(edu => ({
                    ...edu,
                    is_present: edu.end_date === '9999-12-12'
                })) || [],
                user_projects: loadedResumeData.user_projects?.map(proj => ({
                    ...proj,
                    is_present: proj.end_date === '9999-12-12'
                })) || [],
                certifications: loadedResumeData.certifications?.Certifications?.map(cert => ({
                    ...cert,
                    is_present: cert.valid_till === '9999-12-12'
                })) || [],
            });
        }
    }, [location.state]);

    const handleUpdate = (section, index, key, value) => {
        const updatedData = { ...resumeData };
        const updatedSection = updatedData[section];
        updatedSection[index] = { ...updatedSection[index], [key]: value };
        updatedData[section] = updatedSection;
        setResumeData(updatedData);
    };

    const handleDelete = (section, index) => {
        setResumeData(prevData => ({
            ...prevData,
            [section]: prevData[section].filter((_, idx) => idx !== index),
        }));
    };

    const EditableField = ({ value, onChange, fieldType = 'text', error, disabled }) => {
        const [fieldValue, setFieldValue] = useState(value);

        const handleChange = (e) => setFieldValue(e.target.value);

        const handleBlur = () => {
            onChange(fieldValue);
        };

        return fieldType === 'textarea' ? (
            <textarea
                value={fieldValue}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`editable-textarea ${error ? 'error-field' : ''}`}
                disabled={disabled}
            />
        ) : (
            <input
                type={fieldType}
                value={disabled ? 'Present' : fieldValue}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`editable-input ${error ? 'error-field' : ''}`}
                disabled={disabled}
            />
        );
    };

    const handleCheckboxChange = (section, index, key, checked) => {
        const updatedData = { ...resumeData };
        const updatedSection = updatedData[section];
        if (checked) {
            updatedSection[index] = { ...updatedSection[index], [key]: '9999-12-12', is_present: true };
        } else {
            updatedSection[index] = { ...updatedSection[index], [key]: '', is_present: false };
        }
        updatedData[section] = updatedSection;
        setResumeData(updatedData);
    };

    const handleSaveChanges = async () => {
        setLoading(true);

        const errors = validateResumeData(resumeData);
        setValidationErrors({ ...errors });
        if (Object.keys(errors)?.length > 0) {
            setLoading(false);

            alert('Validation failed: ' + JSON.stringify(errors));
            Object.values(errors).forEach(errorMessage => {
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
            return;
        }
        const userid = localStorage.getItem('userid'); // Retrieve userid from localStorage

        const formattedData = {
            userid,
            data: {
                experiences: resumeData.experiences.map(exp => ({
                    ...exp,
                    end_date: exp.is_present ? '9999-12-12' : exp.end_date
                })),
                education: resumeData.education.map(edu => ({
                    ...edu,
                    end_date: edu.is_present ? '9999-12-12' : edu.end_date
                })),
                projects: resumeData.user_projects.map(proj => ({
                    ...proj,
                    end_date: proj.is_present ? '9999-12-12' : proj.end_date
                })),
                certifications: resumeData.certifications.map(cert => ({
                    ...cert,
                    valid_till: cert.is_present ? '9999-12-12' : cert.valid_till
                })),
            },
        };

        try {
            const token = await getAccessTokenSilently();
            const url = baseurl + save_user_resume_details;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formattedData),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();

            if (result.message === "Resume data processed and saved successfully") {
                const loadedResumeData = location.state?.resumeData?.data?.resume;
                if (loadedResumeData) {
                    window.location.href = "/job-preferences";
                    // navigate('/onboard-user');
                }
                else {
                    setLoading(false);
                    toast.success("Resume edited successfully", {
                        autoClose: 2000,
                        position: "top-center",
                    });
                }
            } else {
                toast.error(result.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            alert('Failed to save resume data: ' + error.message);
            toast.error("An error occurred while saving resume data.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } finally {
            setLoading(false); // Stop loading regardless of the outcome
        }
    };

    const validateResumeData = (data) => {
        const errors = {};

        // Validate experiences
        if (data.experiences && data.experiences?.length > 0) {
            data.experiences.forEach((exp, index) => {
                let missingFields = [];
                if (!exp.job_title) missingFields.push("Job Title");
                if (!exp.company_name) missingFields.push("Company Name");
                if (!exp.start_date || !moment(exp.start_date, 'YYYY-MM-DD', true).isValid()) {
                    missingFields.push("Start Date");
                }
                if (!exp.is_present && (!exp.end_date || !moment(exp.end_date, 'YYYY-MM-DD', true).isValid())) {
                    missingFields.push("End Date");
                }
                if (!exp.description) missingFields.push("Description");
                if (missingFields?.length > 0) {
                    errors[`experiences_${index}`] = `Missing or invalid fields in Experience ${index + 1}: ${missingFields?.join(", ")}`;
                }
            });
        }

        // Validate education
        if (data.education && data.education?.length > 0) {
            data.education.forEach((edu, index) => {
                let missingFields = [];
                if (!edu.institution_name) missingFields.push("Institution Name");
                if (!edu.degree) missingFields.push("Degree");
                if (!edu.field_of_study) missingFields.push("Field of Study");
                if (!edu.start_date || !moment(edu.start_date, 'YYYY-MM-DD', true).isValid()) {
                    missingFields.push("Start Date");
                }
                if (!edu.is_present && (!edu.end_date || !moment(edu.end_date, 'YYYY-MM-DD', true).isValid())) {
                    missingFields.push("End Date");
                }
                if (missingFields?.length > 0) {
                    errors[`education_${index}`] = `Missing or invalid fields in Education ${index + 1}: ${missingFields?.join(", ")}`;
                }
            });
        }

        // Validate projects
        if (data.user_projects && data.user_projects?.length > 0) {
            data.user_projects.forEach((project, index) => {
                let missingFields = [];
                if (!project.project_name) missingFields.push("Project Name");
                if (!project.technologies_used) missingFields.push("Technologies Used");
                if (!project.start_date || !moment(project.start_date, 'YYYY-MM-DD', true).isValid()) {
                    missingFields.push("Start Date");
                }
                if (!project.is_present && (!project.end_date || !moment(project.end_date, 'YYYY-MM-DD', true).isValid())) {
                    missingFields.push("End Date");
                }
                if (!project.description) missingFields.push("Description");
                if (missingFields?.length > 0) {
                    errors[`projects_${index}`] = `Missing or invalid fields in Project ${index + 1}: ${missingFields?.join(", ")}`;
                }
            });
        }

        // Validate certifications
        if (data.certifications && data.certifications?.length > 0) {
            data.certifications.forEach((cert, index) => {
                let missingFields = [];
                if (!cert.certification_name) missingFields.push("Certification Name");
                if (!cert.issued_by) missingFields.push("Issued By");
                if (!cert.issued_date || !moment(cert.issued_date, 'YYYY-MM-DD', true).isValid()) {
                    missingFields.push("Issued Date");
                }
                if (!cert.is_present && (!cert.valid_till || !moment(cert.valid_till, 'YYYY-MM-DD', true).isValid())) {
                    missingFields.push("Valid till");
                }
                if (cert.issued_date && cert.valid_till && !cert.is_present && moment(cert.issued_date).isAfter(moment(cert.valid_till))) {
                    missingFields.push("Issued Date must be before Valid Till");
                }
                if (!cert.description) missingFields.push("Description");
                if (missingFields?.length > 0) {
                    errors[`certifications_${index}`] = `Missing fields in Certification ${index + 1}: ${missingFields?.join(", ")}`;
                }
            });
        }

        return errors;
    };

    const handleAddItem = (section) => {
        const newItem = getNewItemForSection(section);
        setResumeData(prevData => ({
            ...prevData,
            [section]: [newItem, ...prevData[section]],
        }));
    };

    const getNewItemForSection = (section) => {
        switch (section) {
            case 'experiences':
                return { job_title: '', company_name: '', start_date: '', end_date: '', description: '', is_present: false };
            case 'education':
                return { institution_name: '', degree: '', start_date: '', end_date: '', field_of_study: '', is_present: false };
            case 'user_projects':
                return { project_name: '', technologies_used: '', start_date: '', end_date: '', description: '', is_present: false };
            case 'certifications':
                return { certification_name: '', issued_by: '', issued_date: '', valid_till: '', description: '', is_present: false };
            default:
                return {};
        }
    };

    const ExperiencesSection = ({ experiences = [], onUpdate, onDelete, validationErrors = {} }) => (
        <div className="resume-section">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h2>Experiences</h2>
                <button onClick={() => handleAddItem('experiences')} style={{ cursor: 'pointer', color: '#333', border: '1px solid black', backgroundColor: 'transparent', padding: '5px 10px', borderRadius: '5px' }}>
                    + Add Experience
                </button>
            </div>
            {experiences.length > 0 ? (
                experiences.map((exp, index) => (
                    <div key={index} className="resume-item" style={{ position: 'relative' }}>
                        <div className="field-group">
                            <label>Job Title</label>
                            <EditableField
                                value={exp.job_title}
                                onChange={(value) => onUpdate('experiences', index, 'job_title', value)}
                                error={validationErrors[`experiences_${index}`]}
                            />
                        </div>
                        <div className="field-group">
                            <label>Company Name</label>
                            <EditableField
                                value={exp.company_name}
                                onChange={(value) => onUpdate('experiences', index, 'company_name', value)}
                                error={validationErrors[`experiences_${index}`]}
                            />
                        </div>
                        <div className="field-group">
                            <label>Start Date</label>
                            <EditableField
                                value={exp.start_date}
                                onChange={(value) => onUpdate('experiences', index, 'start_date', value)}
                                fieldType="date"
                                error={validationErrors[`experiences_${index}`]}
                            />
                        </div>
                        <div className="field-group">
                            <label>End Date</label>
                            <EditableField
                                value={exp.end_date}
                                onChange={(value) => onUpdate('experiences', index, 'end_date', value)}
                                fieldType="date"
                                error={validationErrors[`experiences_${index}`]}
                                disabled={exp.is_present}
                            />
                            <label>
                                <input
                                    type="checkbox"
                                    checked={exp.is_present}
                                    onChange={(e) => handleCheckboxChange('experiences', index, 'end_date', e.target.checked)}
                                />
                                {' '}Present
                            </label>
                        </div>
                        <div className="field-group">
                            <label>Description</label>
                            <EditableField
                                value={exp.description}
                                onChange={(value) => onUpdate('experiences', index, 'description', value)}
                                fieldType="textarea"
                                error={validationErrors[`experiences_${index}`]}
                            />
                        </div>
                        <button className="delete-icon" onClick={() => onDelete('experiences', index)} style={{ cursor: 'pointer', backgroundColor: 'transparent', border: 'none', color: '#333', padding: '4px', borderRadius: '5px', outline: 'none' }}>
                            <FontAwesomeIcon icon={faTrash} />
                        </button>
                    </div>
                ))
            ) : (
                <p>No experiences listed.</p>
            )}
        </div>
    );

    const ProjectsSection = ({ projects, onUpdate, onDelete, validationErrors }) => (
        <div className="resume-section">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h2>Projects</h2>
                <button onClick={() => handleAddItem('user_projects')} style={{ cursor: 'pointer', color: '#333', border: '1px solid black', backgroundColor: 'transparent', padding: '5px 10px', borderRadius: '5px' }}>
                    + Add Projects
                </button>
            </div>
            {projects?.length > 0 ? (
                projects.map((project, index) => (
                    <div key={index} className="resume-item" style={{ position: 'relative' }}>
                        <div className="field-group">
                            <label>Project Name</label>
                            <EditableField
                                value={project.project_name}
                                onChange={(value) => onUpdate('user_projects', index, 'project_name', value)}
                                error={validationErrors[`projects_${index}`]}
                            />
                        </div>
                        <div className="field-group">
                            <label>Technologies Used</label>
                            <EditableField
                                value={project.technologies_used}
                                onChange={(value) => onUpdate('user_projects', index, 'technologies_used', value)}
                                error={validationErrors[`projects_${index}`]}
                            />
                        </div>
                        <div className="field-group">
                            <label>Start Date</label>
                            <EditableField
                                value={project.start_date}
                                onChange={(value) => onUpdate('user_projects', index, 'start_date', value)}
                                fieldType="date"
                                error={validationErrors[`projects_${index}`]}
                            />
                        </div>
                        <div className="field-group">
                            <label>End Date</label>
                            <EditableField
                                value={project.end_date}
                                onChange={(value) => onUpdate('user_projects', index, 'end_date', value)}
                                fieldType="date"
                                error={validationErrors[`projects_${index}`]}
                                disabled={project.is_present}
                            />
                            <label>
                                <input
                                    type="checkbox"
                                    checked={project.is_present}
                                    onChange={(e) => handleCheckboxChange('user_projects', index, 'end_date', e.target.checked)}
                                />
                                {' '}Present
                            </label>
                        </div>
                        <div className="field-group">
                            <label>Description</label>
                            <EditableField
                                value={project.description}
                                onChange={(value) => onUpdate('user_projects', index, 'description', value)}
                                fieldType="textarea"
                                error={validationErrors[`projects_${index}`]}
                            />
                        </div>
                        <button className="delete-icon" onClick={() => onDelete('user_projects', index)} style={{ cursor: 'pointer', backgroundColor: 'transparent', border: 'none', color: '#333', padding: '4px', borderRadius: '5px', outline: 'none' }}>
                            <FontAwesomeIcon icon={faTrash} />
                        </button>
                    </div>
                ))
            ) : (
                <p>No projects listed.</p>
            )}
        </div>
    );

    const EducationSection = ({ education = [], onUpdate, onDelete, validationErrors = {} }) => (
        <div className="resume-section">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h2>Education</h2>
                <button onClick={() => handleAddItem('education')} style={{ cursor: 'pointer', color: '#333', border: '1px solid black', backgroundColor: 'transparent', padding: '5px 10px', borderRadius: '5px' }}>
                    + Add Education
                </button>
            </div>
            {education.length > 0 ? (
                education.map((edu, index) => (
                    <div key={index} className="resume-item" style={{ position: 'relative' }}>
                        <div className="field-group">
                            <label>Institution Name</label>
                            <EditableField
                                value={edu.institution_name}
                                onChange={(value) => onUpdate('education', index, 'institution_name', value)}
                                error={validationErrors[`education_${index}`]}
                            />
                        </div>
                        <div className="field-group">
                            <label>Degree</label>
                            <EditableField
                                value={edu.degree}
                                onChange={(value) => onUpdate('education', index, 'degree', value)}
                                error={validationErrors[`education_${index}`]}
                            />
                        </div>
                        <div className="field-group">
                            <label>Field of Study</label>
                            <EditableField
                                value={edu.field_of_study}
                                onChange={(value) => onUpdate('education', index, 'field_of_study', value)}
                                error={validationErrors[`education_${index}`]}
                            />
                        </div>
                        <div className="field-group">
                            <label>Start Date</label>
                            <EditableField
                                value={edu.start_date}
                                onChange={(value) => onUpdate('education', index, 'start_date', value)}
                                fieldType="date"
                                error={validationErrors[`education_${index}`]}
                            />
                        </div>
                        <div className="field-group">
                            <label>End Date</label>
                            <EditableField
                                value={edu.end_date}
                                onChange={(value) => onUpdate('education', index, 'end_date', value)}
                                fieldType="date"
                                error={validationErrors[`education_${index}`]}
                                disabled={edu.is_present}
                            />
                            <label>
                                <input
                                    type="checkbox"
                                    checked={edu.is_present}
                                    onChange={(e) => handleCheckboxChange('education', index, 'end_date', e.target.checked)}
                                />
                                {' '}Present
                            </label>
                        </div>
                        <button className="delete-icon" onClick={() => onDelete('education', index)} style={{ cursor: 'pointer', backgroundColor: 'transparent', border: 'none', color: '#333', padding: '4px', borderRadius: '5px', outline: 'none' }}>
                            <FontAwesomeIcon icon={faTrash} />
                        </button>
                    </div>
                ))
            ) : (
                <p>No education listed.</p>
            )}
        </div>
    );

    const CertificationsSection = ({ certifications = [], onUpdate, onDelete, validationErrors = {} }) => (
        <div className="resume-section">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h2>Certifications</h2>
                <button onClick={() => handleAddItem('certifications')} style={{ cursor: 'pointer', color: '#333', border: '1px solid black', backgroundColor: 'transparent', padding: '5px 10px', borderRadius: '5px' }}>
                    + Add Certification
                </button>
            </div>
            {certifications.length > 0 ? (
                certifications.map((cert, index) => (
                    <div key={index} className="resume-item" style={{ position: 'relative' }}>
                        <div className="field-group">
                            <label>Certification Title</label>
                            <EditableField
                                value={cert.certification_name}
                                onChange={(value) => onUpdate('certifications', index, 'certification_name', value)}
                                error={validationErrors[`certifications_${index}`]}
                            />
                        </div>
                        <div className="field-group">
                            <label>Issued By</label>
                            <EditableField
                                value={cert.issued_by}
                                onChange={(value) => onUpdate('certifications', index, 'issued_by', value)}
                                error={validationErrors[`certifications_${index}`]}
                            />
                        </div>
                        <div className="field-group">
                            <label>Issued Date</label>
                            <EditableField
                                value={cert.issued_date}
                                onChange={(value) => onUpdate('certifications', index, 'issued_date', value)}
                                fieldType="date"
                                error={validationErrors[`certifications_${index}`]}
                            />
                        </div>
                        <div className="field-group">
                            <label>Valid Till</label>
                            <EditableField
                                value={cert.valid_till}
                                onChange={(value) => onUpdate('certifications', index, 'valid_till', value)}
                                fieldType="date"
                                error={validationErrors[`certifications_${index}`]}
                                disabled={cert.is_present}
                            />
                            <label>
                                <input
                                    type="checkbox"
                                    checked={cert.is_present}
                                    onChange={(e) => handleCheckboxChange('certifications', index, 'valid_till', e.target.checked)}
                                />
                                {' '}Present
                            </label>
                        </div>
                        <div className="field-group">
                            <label>Description</label>
                            <EditableField
                                value={cert.description}
                                onChange={(value) => onUpdate('certifications', index, 'description', value)}
                                fieldType="textarea"
                                error={validationErrors[`certifications_${index}`]}
                            />
                        </div>
                        <button className="delete-icon" onClick={() => onDelete('certifications', index)} style={{ cursor: 'pointer', backgroundColor: 'transparent', border: 'none', color: '#333', padding: '4px', borderRadius: '5px', outline: 'none' }}>
                            <FontAwesomeIcon icon={faTrash} />
                        </button>
                    </div>
                ))
            ) : (
                <p>No certifications listed.</p>
            )}
        </div>
    );

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!isAuthenticated) {
        return <Navigate to="/" />;
    }

    return (
        <motion.div
            className="resume-container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.5, duration: 0.5 } }}
        >
            <div className="resume-sections">
                <h1>Review Your Details</h1>
                <p>A minimum of one experience & 2 projects will give the best results</p>

                <>
                    <EducationSection
                        education={resumeData.education}
                        onUpdate={handleUpdate}
                        onDelete={handleDelete}
                        validationErrors={validationErrors}
                    />

                    <ExperiencesSection
                        experiences={resumeData.experiences}
                        onUpdate={handleUpdate}
                        onDelete={handleDelete}
                        validationErrors={validationErrors}
                    />

                    <ProjectsSection
                        projects={resumeData.user_projects}
                        onUpdate={handleUpdate}
                        onDelete={handleDelete}
                        validationErrors={validationErrors}
                    />

                    <CertificationsSection
                        certifications={resumeData.certifications}
                        onUpdate={handleUpdate}
                        onDelete={handleDelete}
                        validationErrors={validationErrors}
                    />

                    <ToastContainer />

                    <button
                        className="save-button"
                        onClick={handleSaveChanges}
                        disabled={loading}
                        style={{
                            cursor: loading ? 'not-allowed' : 'pointer',
                            backgroundColor: loading ? '#ccc' : '#0077B5',
                            color: loading ? '#666' : '#fff'
                        }}
                    >
                        {loading ? <div className="loading-spinner"></div> : 'Save Changes'}
                    </button>
                </>
            </div>
        </motion.div>
    );
};

export default ResumePage;