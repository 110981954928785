import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [userDetails, setUserDetails] = useState(null);


    useEffect(() => {
        if (userDetails) {
            localStorage.setItem('userid', user.sub);
            localStorage.setItem('emailID', user.email);
        }
    }, [userDetails]);

    useEffect(() => {
        const fetchUserDetails = async () => {
            if (isAuthenticated) {
                setUserDetails({
                    email: user.email,
                    nickname: user.nickname,
                    sub: user.sub,
                    picture: user.picture,
                });
            }
        };

        fetchUserDetails();
    }, [user, isAuthenticated, getAccessTokenSilently]);


    return (
        <UserContext.Provider value={userDetails}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);