import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Homepage from './pages/homepage';
import DashboardLayout from './dashboard_layout';
import Footer from './components/footer';
import JobsApplied from './components/dashboard/jobs_applied';
import ReportIssue from './components/dashboard/report_issue';
import OnboardUser from './pages/onboard_user';
import JobPreferences from './pages/job_preferences';
import ProfileUpdatePage from './pages/profile_update';
import { UserProvider } from './components/user_context';
import GeneratedResume from './components/dashboard/generated_resumes';
import ResumePage from './pages/resume_page';
import LinkedInPostMockup from './components/homepage/linkedin_comment';
import Pricing from './components/homepage/pricing.js';
import PrivacyPolicy from "./pages/privacy_policy.js";
import HowExtensionWorks from "./pages/how_extension_works.js";
import TermsConditions from './pages/terms_conditions';
import RefundsPolicy from './pages/refunds_policy.js';
import ContactPage from './pages/contact.js';
import AboutUs from './pages/about-us.js';
import { Analytics } from "@vercel/analytics/react";
import * as Sentry from "@sentry/react";



const domain = "dev-yaekcth50bauskwq.us.auth0.com";
const clientId = "8UIX38o00txdnKFoB1ChDjgy1WG5c3jl";
const audience = "https://api.careerfornia.com:25060/api/";
const redirectUri = window.location.origin + '/dashboard-layout';
const scope = "read:current_user";


Sentry.init({
  dsn: "https://b80ae390cadf96b8b1a167690010bd47@o4507325022404608.ingest.us.sentry.io/4507325096787968",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/(www\.)?careerfornia\.com\/api/],
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
        audience: "https://api.careerfornia.com:25060/api/"
      }}
      audience={audience}
    >
      <Router>
        <UserProvider>
          <App />
          <Analytics />
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/dashboard-layout" element={<DashboardLayout />} />
            <Route path="jobs-applied" element={<JobsApplied />} />
            <Route path="report-an-issue" element={<ReportIssue />} />
            <Route path="generated-resumes" element={<GeneratedResume />} />
            <Route path="/onboard-user" element={<OnboardUser />} />
            <Route path="/job-preferences" element={<JobPreferences />} />
            <Route path="/profile" element={<ProfileUpdatePage />} />
            <Route path="/resume-page" element={<ResumePage />} />
            <Route path="/linkedin-post-trial" element={<LinkedInPostMockup />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/how-careerfornia-extension-works" element={<HowExtensionWorks />} />
            <Route path="/terms" element={<TermsConditions />} />
            <Route path="/refunds" element={<RefundsPolicy />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/support" element={<ContactPage />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/pricing" element={<Pricing />} />
          </Routes>
        </UserProvider>
      </Router>
    </Auth0Provider>
  </React.StrictMode >
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
