import React, { useState } from 'react';
import { Box, Grid, TextField, Typography, Button, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const SkillFields = ({ skills, setFormData, isConfirmed, skillsError }) => {
    const [newCategory, setNewCategory] = useState('');
    const [newSkills, setNewSkills] = useState('');

    const handleSkillChange = (category, value) => {
        setFormData(prevData => ({
            ...prevData,
            skills: {
                ...prevData.skills,
                [category]: value.split(',').map(skill => skill.trim())
            }
        }));
    };

    const handleAddNewSkill = () => {
        if (newCategory.trim() && newSkills.trim()) {
            setFormData(prevData => ({
                ...prevData,
                skills: {
                    ...prevData.skills,
                    [newCategory]: newSkills.split(',').map(skill => skill.trim())
                }
            }));
            setNewCategory('');
            setNewSkills('');
        }
    };

    const handleDeleteSkill = (category) => {
        const updatedSkills = { ...skills };
        delete updatedSkills[category];
        setFormData(prevData => ({
            ...prevData,
            skills: updatedSkills
        }));
    };

    return (
        <>
            <Typography variant="h6" style={{ marginTop: 20, fontSize: '1rem' }}>Skills:</Typography>
            <Box style={{ marginTop: 20 }}>
                <Button
                    onClick={handleAddNewSkill}
                    style={{ marginTop: 10, cursor: 'pointer', color: '#333', border: '1px solid black', backgroundColor: 'transparent', padding: '5px 10px', borderRadius: '5px', fontSize: '0.875rem' }}
                    disabled={isConfirmed}
                >
                    + Add Skill
                </Button>
                <Grid container spacing={1} alignItems="center" style={{ marginTop: 10 }}>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            label="Category"
                            value={newCategory}
                            disabled={isConfirmed}
                            onChange={(e) => setNewCategory(e.target.value)}
                            fullWidth
                            margin="dense"
                            InputProps={{ style: { fontSize: '0.875rem' } }}
                            InputLabelProps={{ style: { fontSize: '0.875rem' } }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <TextField
                            label="Skills"
                            value={newSkills}
                            disabled={isConfirmed}
                            onChange={(e) => setNewSkills(e.target.value)}
                            fullWidth
                            margin="dense"
                            multiline
                            InputProps={{ style: { fontSize: '0.875rem' } }}
                            InputLabelProps={{ style: { fontSize: '0.875rem' } }}
                        />
                    </Grid>
                </Grid>
            </Box>
            {Object.entries(skills).map(([category, skills]) => (
                <Box key={category} style={{ marginBottom: 10, display: 'flex', alignItems: 'center' }}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12} sm={3}>
                            <Typography variant="subtitle1" style={{ fontSize: '0.875rem' }}>{category}:</Typography>
                        </Grid>
                        <Grid item xs={10} sm={8}>
                            <TextField
                                value={skills?.join(', ')}
                                disabled={isConfirmed}
                                onChange={(e) => handleSkillChange(category, e.target.value)}
                                fullWidth
                                margin="dense"
                                multiline
                                error={skillsError[category]}
                                helperText={skillsError[category] ? "Required" : ""}
                                InputProps={{ style: { fontSize: '0.875rem' } }}
                                InputLabelProps={{ style: { fontSize: '0.875rem' } }}
                            />
                        </Grid>
                        <Grid item xs={2} sm={1}>
                            <IconButton
                                aria-label="delete"
                                onClick={() => handleDeleteSkill(category)}
                                disabled={isConfirmed}
                                edge="end"
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>
            ))}
        </>
    );
};

export default SkillFields;