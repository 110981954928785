import React, { useState, useEffect, useRef } from 'react';
import '../../../styles/generated_resumes.css';
import {
    Dialog,
    Typography,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Box,
    Checkbox,
    FormControlLabel,
    Grid
} from '@mui/material';
import { formatFileName, handleInputChange } from '../utils';
import SectionFields from './SectionFields';
import SkillFields from './SkillFields';
import { generatePDF } from '../../pdfFormatter';

const ResumeDataModal = ({ open, onClose, data, jobId, userProfileData }) => {
    console.log("data - ",data);
    console.log("userProfileData - ",userProfileData);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [localResumeData, setLocalResumeData] = useState({});
    const [formData, setFormData] = useState(data);
    const [fullName, setFullName] = useState(data.user_full_name);
    const [location, setLocation] = useState(data.user_location);
    const [phone, setPhone] = useState(data.user_phone);
    const [email, setEmail] = useState(data.user_email);
    const [linkedin, setLinkedin] = useState(data.user_linkedin);
    const [portfolio, setPortfolio] = useState(data.portfolio_link);
    const [github, setGithub] = useState(data.github_link);
    const downloadButtonRef = useRef(null);
    const [fullNameError, setFullNameError] = useState(false);
    const [locationError, setLocationError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [linkedinError, setLinkedinError] = useState(false);
    const [portfolioError, setPortfolioError] = useState(false);
    const [githubError, setGithubError] = useState(false);
    const [skillsError, setSkillsError] = useState({});
    const [educationErrors, setEducationErrors] = useState([]);
    const [experienceErrors, setExperienceErrors] = useState([]);
    const [projectErrors, setProjectErrors] = useState([]);
    const [certificationErrors, setCertificationErrors] = useState([]);

    useEffect(() => {
        if (localResumeData && downloadButtonRef.current) {
            downloadButtonRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    }, [localResumeData]);

    const handleSave = () => {
        const updatedData = {
            ...formData,
            user_full_name: fullName,
            user_location: location,
            user_phone: phone,
            user_email: email,
            user_linkedin: linkedin,
            portfolio_link: portfolio,
            github_link: github
        };
        setFormData(updatedData);
        setLocalResumeData(prevState => ({
            ...prevState,
            [jobId]: {
                data: updatedData,
                isReady: true
            }
        }));
    };

    const handleCheckboxChange = (event) => {
        const fields = { fullName, location, phone, email, linkedin, portfolio, github };
        const errorSetters = {
            fullName: setFullNameError,
            location: setLocationError,
            phone: setPhoneError,
            email: setEmailError,
            linkedin: setLinkedinError,
            portfolio: setPortfolioError,
            github: setGithubError
        };

        let isValid = Object.keys(fields).every(key => {
            if (key === 'location' || key === 'linkedin' || key === 'portfolio' || key === 'github') {
                return true; // Skip validation for optional fields
            }
            const isFieldValid = fields[key].trim() !== '';
            errorSetters[key](!isFieldValid);
            return isFieldValid;
        });

        const skillErrors = {};
        if (formData.skills) {
            Object.entries(formData.skills).forEach(([category, skills]) => {
                if (!category.trim() || skills.some(skill => !skill.trim())) {
                    skillErrors[category] = true;
                    isValid = false;
                }
            });
            setSkillsError(skillErrors);
        }

        const validateFields = (items) => {
            return items.map((item) => {
                const itemErrors = {};
                Object.keys(item).forEach((key) => {
                    const value = item[key];
                    if (typeof value === 'string') {
                        if (!value.trim()) {
                            itemErrors[key] = true;
                            isValid = false;
                        }
                    } else if (Array.isArray(value)) {
                        if (value.length === 0 || value.some(val => !val.trim())) {
                            itemErrors[key] = true;
                            isValid = false;
                        }
                    }
                });
                return itemErrors;
            });
        };

        setEducationErrors(validateFields(formData.education || []));
        setExperienceErrors(validateFields(formData.experiences || []));
        setProjectErrors(validateFields(formData.projects || []));
        setCertificationErrors(validateFields(formData.certifications || []));

        if (isValid) {
            setIsConfirmed(event.target.checked);
            if (event.target.checked) handleSave();
        } else {
            event.preventDefault();
        }
    };

    const handleAddItem = (section) => {
        const newItem = {
            experiences: {
                job_title: '',
                company_name: '',
                start_date: '',
                end_date: '',
                description: []
            },
            projects: {
                project_title: '',
                technologies_used: '',
                start_date: '',
                end_date: '',
                description: []
            },
            education: {
                institution_name: '',
                degree: '',
                start_date: '',
                end_date: '',
                field_of_study: ''
            },
            certifications: {
                certificationName: '',
                issuingOrganization: '',
                dateReceived: ''
            }
        }[section];

        if (newItem) {
            setFormData(prevData => ({
                ...prevData,
                [section]: [newItem, ...prevData[section]]
            }));
        }
    };

    const downloadResume = () => {
        try {
            generatePDF(localResumeData[jobId]?.data);
        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Edit Resume Data</DialogTitle>
            <DialogContent dividers style={{ padding: 16 }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Full Name"
                            value={fullName}
                            disabled={isConfirmed}
                            onChange={(e) => handleInputChange(e.target.value, setFullName)}
                            fullWidth
                            margin="dense"
                            error={fullNameError}
                            helperText={fullNameError ? "Required" : ""}
                            InputProps={{ style: { fontSize: '0.875rem' } }}
                            InputLabelProps={{ style: { fontSize: '0.875rem' } }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Location"
                            value={location}
                            disabled={isConfirmed}
                            onChange={(e) => handleInputChange(e.target.value, setLocation)}
                            fullWidth
                            margin="dense"
                            error={locationError}
                            InputProps={{ style: { fontSize: '0.875rem' } }}
                            InputLabelProps={{ style: { fontSize: '0.875rem' } }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Phone"
                            value={phone}
                            disabled={isConfirmed}
                            onChange={(e) => handleInputChange(e.target.value, setPhone)}
                            fullWidth
                            margin="dense"
                            error={phoneError}
                            helperText={phoneError ? "Required" : ""}
                            InputProps={{ style: { fontSize: '0.875rem' } }}
                            InputLabelProps={{ style: { fontSize: '0.875rem' } }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Email"
                            value={email}
                            disabled={isConfirmed}
                            onChange={(e) => handleInputChange(e.target.value, setEmail)}
                            fullWidth
                            margin="dense"
                            error={emailError}
                            helperText={emailError ? "Required" : ""}
                            InputProps={{ style: { fontSize: '0.875rem' } }}
                            InputLabelProps={{ style: { fontSize: '0.875rem' } }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="LinkedIn"
                            value={linkedin}
                            disabled={isConfirmed}
                            onChange={(e) => handleInputChange(e.target.value, setLinkedin)}
                            fullWidth
                            margin="dense"
                            error={linkedinError}
                            InputProps={{ style: { fontSize: '0.875rem' } }}
                            InputLabelProps={{ style: { fontSize: '0.875rem' } }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Portfolio"
                            value={portfolio}
                            disabled={isConfirmed}
                            onChange={(e) => handleInputChange(e.target.value, setPortfolio)}
                            fullWidth
                            margin="dense"
                            error={portfolioError}
                            InputProps={{ style: { fontSize: '0.875rem' } }}
                            InputLabelProps={{ style: { fontSize: '0.875rem' } }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="GitHub"
                            value={github}
                            disabled={isConfirmed}
                            onChange={(e) => handleInputChange(e.target.value, setGithub)}
                            fullWidth
                            margin="dense"
                            error={githubError}
                            InputProps={{ style: { fontSize: '0.875rem' } }}
                            InputLabelProps={{ style: { fontSize: '0.875rem' } }}
                        />
                    </Grid>
                </Grid>
                {formData.generated_summary && (
                    <TextField
                        label="Generated Summary"
                        value={formData.generated_summary}
                        disabled={isConfirmed}
                        onChange={(e) => handleInputChange(e.target.value, (value) => setFormData({ ...formData, generated_summary: value }))}
                        fullWidth
                        margin="dense"
                        multiline
                        rows={4}
                        InputProps={{ style: { fontSize: '0.875rem' } }}
                        InputLabelProps={{ style: { fontSize: '0.875rem' } }}
                    />
                )}

                <SectionFields
                    sectionName="Experiences"
                    formData={formData}
                    setFormData={setFormData}
                    isConfirmed={isConfirmed}
                    handleAddItem={handleAddItem}
                    sectionErrors={experienceErrors}
                />
                <SectionFields
                    sectionName="Projects"
                    formData={formData}
                    setFormData={setFormData}
                    isConfirmed={isConfirmed}
                    handleAddItem={handleAddItem}
                    sectionErrors={projectErrors}
                />
                <SectionFields
                    sectionName="Education"
                    formData={formData}
                    setFormData={setFormData}
                    isConfirmed={isConfirmed}
                    handleAddItem={handleAddItem}
                    sectionErrors={educationErrors}
                />
                <SectionFields
                    sectionName="Certifications"
                    formData={formData}
                    setFormData={setFormData}
                    isConfirmed={isConfirmed}
                    handleAddItem={handleAddItem}
                    sectionErrors={certificationErrors}
                />

                <SkillFields
                    skills={formData.skills}
                    setFormData={setFormData}
                    isConfirmed={isConfirmed}
                    skillsError={skillsError}
                />

            </DialogContent>
            <DialogActions style={{ justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', padding: '8px 24px' }}>
                <Box style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                    <FormControlLabel
                        control={<Checkbox checked={isConfirmed} onChange={handleCheckboxChange} />}
                        label="Save and Download Resume"
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 0, whiteSpace: 'nowrap', fontSize: '0.875rem' }}
                    />
                </Box>
                <Box style={{ display: 'flex', gap: '10px' }}>
                    <Button onClick={onClose} color="primary" style={{ fontSize: '0.875rem' }}>
                        Cancel
                    </Button>
                    {isConfirmed && (
                        <Button
                            variant="outlined"
                            color="primary"
                            fullWidth
                            onClick={downloadResume}
                            ref={downloadButtonRef}
                            style={{ fontSize: '0.875rem' }}
                        >
                            Download PDF
                        </Button>
                    )}
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default ResumeDataModal;