import React, { useState, useRef, useEffect } from 'react';
import { Button, CircularProgress, Snackbar, Alert, TextField, Typography, Grid, Box, useTheme } from '@mui/material';
import axios from 'axios';
import { baseurl, generate_tailored_resume } from '../../util/endpoints';
import Countdown from 'react-countdown';
import '../../styles/tailored_resume.css';
import { useAuth0 } from '@auth0/auth0-react';
import { generatePDF } from '../pdfFormatter'; // Import the new function

const TailoredResume = () => {
    const theme = useTheme();

    const [jobTitle, setJobTitle] = useState('');
    const [jobDescription, setJobDescription] = useState('');
    const [company, setCompany] = useState('');
    const [skills, setSkills] = useState('');
    const [loading, setLoading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [resumeData, setResumeData] = useState(null);
    const [isJobDescriptionValid, setIsJobDescriptionValid] = useState(false);
    const [isJobTitleValid, setIsJobTitleValid] = useState(false);
    const [isCompanyValid, setIsCompanyValid] = useState(false);
    const [jobDescriptionTouched, setJobDescriptionTouched] = useState(false);
    const [jobTitleTouched, setJobTitleTouched] = useState(false);
    const [companyTouched, setCompanyTouched] = useState(false);
    const downloadButtonRef = useRef(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [countdownKey, setCountdownKey] = useState(0);
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

    const handleInputChange = (setter) => (event) => {
        setter(event.target.value);
    };

    useEffect(() => {
        if (resumeData && downloadButtonRef.current) {
            downloadButtonRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    }, [resumeData]);

    useEffect(() => {
        setIsJobDescriptionValid(jobDescription.trim().length > 0);
        setIsJobTitleValid(jobTitle.trim().length > 0);
        setIsCompanyValid(company.trim().length > 0);
    }, [jobDescription, jobTitle, company]);

    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const handleGenerateClick = async () => {
        setLoading(true);
        setCountdownKey(prevKey => prevKey + 1);
        const userid = user.sub; // Use Auth0 user ID

        const payload = {
            userid: userid,
            job_title: jobTitle,
            job_description: jobDescription,
            company: company,
            source: "careerfornia",
            skills: skills || undefined
        };

        try {
            const token = await getAccessTokenSilently();
            const url = baseurl + generate_tailored_resume;
            const response = await axios.post(url, payload, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });

            if (response.status === 200) {
                const data = response.data;

                // Ensure all arrays are properly formatted and invalid experiences are excluded
                data.experiences = Array.isArray(data.experiences) ? data.experiences.filter(exp => typeof exp === 'object' && exp !== null) : [];
                data.projects = Array.isArray(data.projects) ? data.projects : [];
                data.internships = Array.isArray(data.internships) ? data.internships : [];
                data.education = Array.isArray(data.education) ? data.education : [];
                data.certifications = Array.isArray(data.certifications) ? data.certifications : [];
                data.skills = typeof data.skills === 'object' && data.skills !== null ? data.skills : {};

                setResumeData(data);
                await delay(3000);
                setOpenSnackbar(true);
                setErrorMessage('');
            } else {
                throw new Error(response.data.error || 'An unexpected error occurred');
            }
        } catch (error) {
            console.error('Error generating tailored resume:', error);
            setErrorMessage(error.response?.data?.error || 'An unexpected error occurred');
            setOpenSnackbar(true);
        } finally {
            setLoading(false);
        }
    };

    const downloadResume = () => {
        try {
            generatePDF(resumeData);
        } catch (error) {
            setErrorMessage(error.message);
            setOpenSnackbar(true);
        }
    };

    const renderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            return <Typography variant="body1" className="countdown-text">Resume generation complete! Time to shine.</Typography>;
        } else {
            return (
                <Typography variant="body1" className="countdown-text">
                    Crafting your tailored resume takes about 20 seconds, but good things come to those who wait! {seconds}
                    <br />
                    We're working on reducing the wait time while enhancing our resume-building algorithm, because we believe in efficiency without compromising excellence
                </Typography>
            );
        }
    };

    return (
        <Box className="form-container">
            <form noValidate autoComplete="off">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            className="form-field"
                            label="Job Title"
                            multiline
                            variant="outlined"
                            fullWidth
                            value={jobTitle}
                            onChange={handleInputChange(setJobTitle)}
                            onBlur={() => setJobTitleTouched(true)}
                            error={!isJobTitleValid && jobTitleTouched}
                            helperText={!isJobTitleValid && jobTitleTouched ? 'Job title is required' : ''}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            className="form-field"
                            label="Company"
                            multiline
                            variant="outlined"
                            fullWidth
                            value={company}
                            onChange={handleInputChange(setCompany)}
                            onBlur={() => setCompanyTouched(true)}
                            error={!isCompanyValid && companyTouched}
                            helperText={!isCompanyValid && companyTouched ? 'Company name is required' : ''}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            className="form-field"
                            label="Job Description"
                            multiline
                            variant="outlined"
                            fullWidth
                            value={jobDescription}
                            onChange={handleInputChange(setJobDescription)}
                            onBlur={() => setJobDescriptionTouched(true)}
                            error={!isJobDescriptionValid && jobDescriptionTouched}
                            helperText={!isJobDescriptionValid && jobDescriptionTouched ? 'Job description is required' : ''}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            className="form-field"
                            label="Skills (optional)"
                            helperText="Adding skills allows our AI to create the best possible resume for you."
                            multiline
                            variant="outlined"
                            fullWidth
                            value={skills}
                            onChange={handleInputChange(setSkills)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={{
                            fontSize: { xs: '0.75rem', sm: '0.875rem' },
                            fontFamily: 'Georgia, serif',
                            margin: '0 auto',
                            textAlign: 'start',
                            color: theme.palette.text.secondary,
                        }}>
                            By continuing, you agree that you are solely responsible for the resumes you submit.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className="button-container">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleGenerateClick}
                            disabled={loading || !isJobDescriptionValid || !isJobTitleValid || !isCompanyValid}
                            fullWidth
                        >
                            {loading ? <CircularProgress size={24} /> : 'Generate'}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            {loading && (
                <Countdown
                    date={Date.now() + 60000}
                    renderer={renderer}
                    key={countdownKey}
                />
            )}
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
                {errorMessage ? (
                    <Alert onClose={() => setOpenSnackbar(false)} severity="error" sx={{ width: '100%' }}>
                        {errorMessage}
                    </Alert>
                ) : (
                    <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
                        Resume generated successfully!
                    </Alert>
                )}
            </Snackbar>
            {resumeData && (
                <Box className="download-button">
                    <Button
                        variant="outlined"
                        color="primary"
                        fullWidth
                        onClick={downloadResume}
                        ref={downloadButtonRef}
                    >
                        Download PDF
                    </Button>
                </Box>
            )}
        </Box>
    );
};

export default TailoredResume;