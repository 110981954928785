import React, { useState } from 'react';
import { Box, Typography, Card, CardContent, Button, Grid, useTheme } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { baseurl } from '../../util/endpoints';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import confetti from 'canvas-confetti';
import { useNavigate } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

const plans = [
    {
        id: 1,
        name: 'Auto Apply',
        resumes: 0,
        autoApply: 100,
        comments: 100,
        price: '$10',
        features: [
            '100 Auto Applies',
            '100 AI Post Comments (Free)',
            'Seamless Integration with LinkedIn'
        ],
        description: 'Use our advanced auto-apply feature to apply for 100 jobs with one click. Additionally, engage more on LinkedIn with 100 AI-generated comments.',
        highlights: [
            '50% increase in LinkedIn profile views reported by users.',
            '95% satisfaction rate among our customers.'
        ],
        priceId: 'price_1PHv5jSCIZ8MgjijPx7vmFgu'
    },
    {
        id: 2,
        name: 'Resumes',
        resumes: 100,
        autoApply: 0,
        comments: 100,
        price: '$20',
        features: [
            '100 Resume Generations',
            '100 AI Post Comments (Free)',
            'Access to Exclusive Resources'
        ],
        description: 'Generate 100 tailored resumes and boost your LinkedIn engagement with 100 AI-generated comments.',
        highlights: [
            'Make you an ideal candidate for each role, ensuring you stay in the top 1% of applicants.',
            '70% of users saw a significant increase in interview invitations.',
            '80% of users received interview calls within the first month.'
        ],
        priceId: 'price_1PHtanSCIZ8MgjijVuun7B8j'
    },
    {
        id: 3,
        name: 'Ultimate',
        resumes: 100,
        autoApply: 100,
        comments: 200,
        price: '$25',
        features: [
            '100 Resume Generations',
            '100 Auto Applies',
            '200 AI Post Comments',
            'Priority Email Support',
            'Access to All Premium Features'
        ],
        description: 'Get the best of both worlds with 100 tailored resumes, 100 auto applies, and 200 AI-generated comments. Optimize your job search and LinkedIn engagement effortlessly.',
        highlights: [
            '90% of users reported receiving interview calls within the first two weeks.',
            '60% increase in LinkedIn profile views on average.',
            'Over 95% of users saw a boost in job opportunities.',
            '97% customer satisfaction rate.'
        ],
        priceId: 'price_1PHuFjSCIZ8MgjijPx1abFst'
    }
];


const Pricing = () => {
    const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    const [loading, setLoading] = useState(false);
    const theme = useTheme();
    const navigate = useNavigate();

    const loadRazorpayScript = () => {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = 'https://checkout.razorpay.com/v1/checkout.js';
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    };

    const handleSelectPlan = async (planName, priceId) => {
        setLoading(true);

        const res = await loadRazorpayScript();

        if (!res) {
            toast.error('Razorpay SDK failed to load. Are you online?');
            setLoading(false);
            return;
        }

        try {
            const token = await getAccessTokenSilently();
            const endpoint = `${baseurl}/api/create-order`;

            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ planName })
            });

            if (!response.ok) {
                const errorDetails = await response.json();
                throw new Error(`Error: ${response.status} - ${errorDetails.error}`);
            }

            const order = await response.json();

            const options = {
                key: 'rzp_live_RMMdCDTcJjTPeG',
                amount: order.amount,
                currency: order.currency,
                name: 'Careerfornia',
                description: `Purchase ${planName} Plan`,
                order_id: order.id,
                handler: async function (response) {
                    const data = {
                        razorpay_order_id: response.razorpay_order_id,
                        razorpay_payment_id: response.razorpay_payment_id,
                        razorpay_signature: response.razorpay_signature,
                        userId: user.sub,
                        planName,
                        amountPaid: order.amount / 100
                    };

                    const verificationEndpoint = `${baseurl}/api/verify-payment`;

                    const verifyResponse = await fetch(verificationEndpoint, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                        body: JSON.stringify(data),
                    });

                    if (!verifyResponse.ok) {
                        const errorDetails = await verifyResponse.json();
                        throw new Error(`Error: ${verifyResponse.status} - ${errorDetails.error}`);
                    }

                    const duration = 15 * 1000;
                    const end = Date.now() + duration;

                    (function frame() {
                        confetti({
                            particleCount: 7,
                            angle: 60,
                            spread: 55,
                            origin: { x: 0, y: 0.6 },
                            colors: ['#bb0000', '#ffffff'],
                            zIndex: 9999
                        });
                        confetti({
                            particleCount: 7,
                            angle: 120,
                            spread: 55,
                            origin: { x: 1, y: 0.6 },
                            colors: ['#bb0000', '#ffffff'],
                            zIndex: 9999
                        });

                        if (Date.now() < end) {
                            requestAnimationFrame(frame);
                        }
                    }());
                    toast.success('Payment successful and credits added!', {
                        onClose: () => window.location.reload()
                    });
                },
                prefill: {
                    name: user.name,
                    email: user.email,
                },
                theme: {
                    color: '#F37254',
                },
                modal: {
                    ondismiss: function () {
                        console.log("Checkout form closed");
                    }
                }
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        } catch (error) {
            toast.error(`Error in handleSelectPlan: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    const cardVariants = {
        offscreen: { y: 30, opacity: 0 },
        onscreen: {
            y: 0,
            opacity: 1,
            transition: {
                type: 'spring',
                stiffness: 120,
                damping: 10,
                delay: 0.2
            }
        }
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!isAuthenticated) {
        return <Navigate to="/" />;
    }

    return (
        <Box sx={{ padding: theme.spacing(8, 4) }} id="pricing">
            <ToastContainer />
            <Typography variant="h3" align="center" gutterBottom sx={{ mb: 4, fontFamily: 'Georgia, serif' }}>
                Pricing Plans
            </Typography>
            <Grid container spacing={4} justifyContent="center">
                {loading && (
                    <div className="loading-overlay" style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 9999
                    }}>
                        <div className="spinner" style={{
                            border: '16px solid #f3f3f3',
                            borderRadius: '50%',
                            borderTop: '16px solid #3498db',
                            width: '120px',
                            height: '120px',
                            animation: 'spin 2s linear infinite'
                        }}></div>
                        <style>
                            {`
                            @keyframes spin {
                                0% { transform: rotate(0deg); }
                                100% { transform: rotate(360deg); }
                            }
                        `}
                        </style>
                    </div>
                )}
                {plans && plans.map((plan) => (
                    <Grid item xs={12} sm={6} md={4} key={plan.id} sx={{ display: 'flex' }}>
                        <Card
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                padding: theme.spacing(2, 2, 4),
                                backgroundColor: '#fff',
                                height: '100%',
                                color: theme.palette.text.primary,
                                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                                border: `1px solid ${theme.palette.text.primary}`,
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                    transition: 'transform 0.3s ease-in-out',
                                },
                            }}
                        >
                            <CardContent sx={{ fontFamily: 'Georgia, serif', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                                <Box>
                                    <Typography variant="h4" gutterBottom align="center" sx={{ fontFamily: 'Georgia, serif' }}>
                                        {plan.name}
                                    </Typography>
                                    <Typography variant="h5" gutterBottom align="center" sx={{ mb: 2, fontFamily: 'Georgia, serif' }}>
                                        {plan.price}
                                    </Typography>

                                    <Box sx={{ mb: 2 }}>
                                        {plan.autoApply > 0 && (
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '0 16px', fontFamily: 'Georgia, serif' }}>
                                                <Typography variant="body1" gutterBottom sx={{ fontFamily: 'Georgia, serif' }}>
                                                    Auto Applies:
                                                </Typography>
                                                <Typography variant="body1" gutterBottom sx={{ fontFamily: 'Georgia, serif' }}>
                                                    {plan.autoApply}
                                                </Typography>
                                            </Box>
                                        )}
                                        {plan.resumes > 0 && (
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '0 16px', fontFamily: 'Georgia, serif' }}>
                                                <Typography variant="body1" gutterBottom sx={{ fontFamily: 'Georgia, serif' }}>
                                                    Resumes:
                                                </Typography>
                                                <Typography variant="body1" gutterBottom sx={{ fontFamily: 'Georgia, serif' }}>
                                                    {plan.resumes}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Box>

                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '0 16px', fontFamily: 'Georgia, serif', mb: 2 }}>
                                        <Typography variant="body1" gutterBottom sx={{ fontFamily: 'Georgia, serif' }}>
                                            Comments: (Free)
                                        </Typography>
                                        <Typography variant="body1" gutterBottom sx={{ fontFamily: 'Georgia, serif' }}>
                                            {plan.comments}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ mb: 2, height: 'auto', minHeight: '60px' }}>
                                        <Typography variant="body2" gutterBottom sx={{ mt: 2, fontFamily: 'Georgia, serif', color: theme.palette.text.secondary }}>
                                            {plan.description}
                                        </Typography>
                                    </Box>
                                </Box>

                                <Box sx={{ height: 'auto', minHeight: '60px' }}>
                                    <Typography variant="body1" gutterBottom sx={{ mt: 4, fontFamily: 'Georgia, serif', fontWeight: 'bold', color: theme.palette.text.primary }}>
                                        Highlights:
                                    </Typography>
                                    <Box sx={{ fontFamily: 'Georgia, serif' }}>
                                        {plan.highlights.map((highlight, idx) => (
                                            <Typography variant="body1" key={idx} sx={{ fontFamily: 'Georgia, serif', color: theme.palette.success.main }}>
                                                • {highlight}
                                            </Typography>
                                        ))}
                                    </Box>
                                </Box>
                            </CardContent>
                            <Button
                                variant="contained"
                                fullWidth
                                sx={{ mt: 3, backgroundColor: '#0077B5', '&:hover': { backgroundColor: '#005f8d' } }}
                                onClick={() => handleSelectPlan(plan.name, plan.priceId)}
                            >
                                Select Plan
                            </Button>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default Pricing;