import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Navbar from './components/homepage/navbar';
import { UserProvider } from './components/user_context';
import Footer from './components/footer';
import CookieConsent from 'react-cookie-consent';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0077B5',
    },
    secondary: {
      main: '#868686',
    },
    background: {
      default: '#FFFFFF',
      paper: '#ebe8e8',
    },
    error: {
      main: '#DD4B39',
    },
    text: {
      primary: '#333333',
      secondary: '#868686',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <UserProvider>
        <CssBaseline />
        <Navbar />
        <CookieConsent
          location="bottom"
          buttonText="I understand"
          cookieName="myAwesomeCookieName"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#fff", fontSize: "13px", backgroundColor: "#0077B5" }} // Set text color to white
          expires={150}
        >
          This website uses cookies to enhance the user experience. By continuing to use this site, you agree to our use of cookies.
        </CookieConsent>
      </UserProvider>
    </ThemeProvider>
  );
}

export default App;