// src/util/countryCodes.js
const countryCodes = [
    { value: 'United States (+1)', label: 'United States (+1)' },
    { value: 'Afghanistan (+93)', label: 'Afghanistan (+93)' },
    { value: 'Aland Islands (+358)', label: 'Aland Islands (+358)' },
    { value: 'Albania (+355)', label: 'Albania (+355)' },
    { value: 'Algeria (+213)', label: 'Algeria (+213)' },
    { value: 'American Samoa (+1)', label: 'American Samoa (+1)' },
    { value: 'Andorra (+376)', label: 'Andorra (+376)' },
    { value: 'Angola (+244)', label: 'Angola (+244)' },
    { value: 'Anguilla (+1)', label: 'Anguilla (+1)' },
    { value: 'Antarctica (+0)', label: 'Antarctica (+0)' },
    { value: 'Antigua and Barbuda (+1)', label: 'Antigua and Barbuda (+1)' },
    { value: 'Argentina (+54)', label: 'Argentina (+54)' },
    { value: 'Armenia (+374)', label: 'Armenia (+374)' },
    { value: 'Aruba (+297)', label: 'Aruba (+297)' },
    { value: 'Australia (+61)', label: 'Australia (+61)' },
    { value: 'Austria (+43)', label: 'Austria (+43)' },
    { value: 'Azerbaijan (+994)', label: 'Azerbaijan (+994)' },
    { value: 'Bahamas (+1)', label: 'Bahamas (+1)' },
    { value: 'Bahrain (+973)', label: 'Bahrain (+973)' },
    { value: 'Bangladesh (+880)', label: 'Bangladesh (+880)' },
    { value: 'Barbados (+1)', label: 'Barbados (+1)' },
    { value: 'Belarus (+375)', label: 'Belarus (+375)' },
    { value: 'Belgium (+32)', label: 'Belgium (+32)' },
    { value: 'Belize (+501)', label: 'Belize (+501)' },
    { value: 'Benin (+229)', label: 'Benin (+229)' },
    { value: 'Bermuda (+1)', label: 'Bermuda (+1)' },
    { value: 'Bhutan (+975)', label: 'Bhutan (+975)' },
    { value: 'Bolivia (+591)', label: 'Bolivia (+591)' },
    { value: 'Bosnia and Herzegovina (+387)', label: 'Bosnia and Herzegovina (+387)' },
    { value: 'Botswana (+267)', label: 'Botswana (+267)' },
    { value: 'Bouvet Island (+0)', label: 'Bouvet Island (+0)' },
    { value: 'Brazil (+55)', label: 'Brazil (+55)' },
    { value: 'British Indian Ocean Territory (+246)', label: 'British Indian Ocean Territory (+246)' },
    { value: 'Brunei Darussalam (+673)', label: 'Brunei Darussalam (+673)' },
    { value: 'Bulgaria (+359)', label: 'Bulgaria (+359)' },
    { value: 'Burkina Faso (+226)', label: 'Burkina Faso (+226)' },
    { value: 'Burundi (+257)', label: 'Burundi (+257)' },
    { value: 'Cambodia (+855)', label: 'Cambodia (+855)' },
    { value: 'Cameroon (+237)', label: 'Cameroon (+237)' },
    { value: 'Canada (+1)', label: 'Canada (+1)' },
    { value: 'Cape Verde (+238)', label: 'Cape Verde (+238)' },
    { value: 'Caribbean Nations (+0)', label: 'Caribbean Nations (+0)' },
    { value: 'Cayman Islands (+1)', label: 'Cayman Islands (+1)' },
    { value: 'Central African Republic (+236)', label: 'Central African Republic (+236)' },
    { value: 'Chad (+235)', label: 'Chad (+235)' },
    { value: 'Chile (+56)', label: 'Chile (+56)' },
    { value: 'China (+86)', label: 'China (+86)' },
    { value: 'Christmas Island (+61)', label: 'Christmas Island (+61)' },
    { value: 'Cocos (Keeling) Islands (+61)', label: 'Cocos (Keeling) Islands (+61)' },
    { value: 'Colombia (+57)', label: 'Colombia (+57)' },
    { value: 'Comoros (+269)', label: 'Comoros (+269)' },
    { value: 'Congo (+242)', label: 'Congo (+242)' },
    { value: 'Cook Islands (+682)', label: 'Cook Islands (+682)' },
    { value: 'Costa Rica (+506)', label: 'Costa Rica (+506)' },
    { value: 'Cote D’Ivoire (Ivory Coast) (+225)', label: 'Cote D’Ivoire (Ivory Coast) (+225)' },
    { value: 'Croatia (+385)', label: 'Croatia (+385)' },
    { value: 'Cuba (+53)', label: 'Cuba (+53)' },
    { value: 'Cyprus (+357)', label: 'Cyprus (+357)' },
    { value: 'Czech Republic (+420)', label: 'Czech Republic (+420)' },
    { value: 'Democratic Republic of the Congo (+243)', label: 'Democratic Republic of the Congo (+243)' },
    { value: 'Denmark (+45)', label: 'Denmark (+45)' },
    { value: 'Djibouti (+253)', label: 'Djibouti (+253)' },
    { value: 'Dominica (+1)', label: 'Dominica (+1)' },
    { value: 'Dominican Republic (+1)', label: 'Dominican Republic (+1)' },
    { value: 'Ecuador (+593)', label: 'Ecuador (+593)' },
    { value: 'Egypt (+20)', label: 'Egypt (+20)' },
    { value: 'El Salvador (+503)', label: 'El Salvador (+503)' },
    { value: 'Equatorial Guinea (+240)', label: 'Equatorial Guinea (+240)' },
    { value: 'Eritrea (+291)', label: 'Eritrea (+291)' },
    { value: 'Estonia (+372)', label: 'Estonia (+372)' },
    { value: 'Ethiopia (+251)', label: 'Ethiopia (+251)' },
    { value: 'Falkland Islands (Malvinas) (+500)', label: 'Falkland Islands (Malvinas) (+500)' },
    { value: 'Faroe Islands (+298)', label: 'Faroe Islands (+298)' },
    { value: 'Federated States of Micronesia (+691)', label: 'Federated States of Micronesia (+691)' },
    { value: 'Fiji (+679)', label: 'Fiji (+679)' },
    { value: 'Finland (+358)', label: 'Finland (+358)' },
    { value: 'France (+33)', label: 'France (+33)' },
    { value: 'French Guiana (+594)', label: 'French Guiana (+594)' },
    { value: 'French Polynesia (+689)', label: 'French Polynesia (+689)' },
    { value: 'French Southern Territories (+0)', label: 'French Southern Territories (+0)' },
    { value: 'Gabon (+241)', label: 'Gabon (+241)' },
    { value: 'Gambia (+220)', label: 'Gambia (+220)' },
    { value: 'Georgia (+995)', label: 'Georgia (+995)' },
    { value: 'Germany (+49)', label: 'Germany (+49)' },
    { value: 'Ghana (+233)', label: 'Ghana (+233)' },
    { value: 'Gibraltar (+350)', label: 'Gibraltar (+350)' },
    { value: 'Greece (+30)', label: 'Greece (+30)' },
    { value: 'Greenland (+299)', label: 'Greenland (+299)' },
    { value: 'Grenada (+1)', label: 'Grenada (+1)' },
    { value: 'Guadeloupe (+590)', label: 'Guadeloupe (+590)' },
    { value: 'Guam (+1)', label: 'Guam (+1)' },
    { value: 'Guatemala (+502)', label: 'Guatemala (+502)' },
    { value: 'Guernsey (+44)', label: 'Guernsey (+44)' },
    { value: 'Guinea (+224)', label: 'Guinea (+224)' },
    { value: 'Guinea-Bissau (+245)', label: 'Guinea-Bissau (+245)' },
    { value: 'Guyana (+592)', label: 'Guyana (+592)' },
    { value: 'Haiti (+509)', label: 'Haiti (+509)' },
    { value: 'Heard Island and McDonald Islands (+0)', label: 'Heard Island and McDonald Islands (+0)' },
    { value: 'Honduras (+504)', label: 'Honduras (+504)' },
    { value: 'Hong Kong (+852)', label: 'Hong Kong (+852)' },
    { value: 'Hungary (+36)', label: 'Hungary (+36)' },
    { value: 'Iceland (+354)', label: 'Iceland (+354)' },
    { value: 'India (+91)', label: 'India (+91)' },
    { value: 'Indonesia (+62)', label: 'Indonesia (+62)' },
    { value: 'Iran (+98)', label: 'Iran (+98)' },
    { value: 'Iraq (+964)', label: 'Iraq (+964)' },
    { value: 'Ireland (+353)', label: 'Ireland (+353)' },
    { value: 'Isle of Man (+44)', label: 'Isle of Man (+44)' },
    { value: 'Israel (+972)', label: 'Israel (+972)' },
    { value: 'Italy (+39)', label: 'Italy (+39)' },
    { value: 'Jamaica (+1)', label: 'Jamaica (+1)' },
    { value: 'Japan (+81)', label: 'Japan (+81)' },
    { value: 'Jersey (+44)', label: 'Jersey (+44)' },
    { value: 'Jordan (+962)', label: 'Jordan (+962)' },
    { value: 'Kazakhstan (+7)', label: 'Kazakhstan (+7)' },
    { value: 'Kenya (+254)', label: 'Kenya (+254)' },
    { value: 'Kiribati (+686)', label: 'Kiribati (+686)' },
    { value: 'Korea (+82)', label: 'Korea (+82)' },
    { value: 'Korea (North) (+850)', label: 'Korea (North) (+850)' },
    { value: 'Kosovo (+383)', label: 'Kosovo (+383)' },
    { value: 'Kuwait (+965)', label: 'Kuwait (+965)' },
    { value: 'Kyrgyzstan (+996)', label: 'Kyrgyzstan (+996)' },
    { value: 'Laos (+856)', label: 'Laos (+856)' },
    { value: 'Latvia (+371)', label: 'Latvia (+371)' },
    { value: 'Lebanon (+961)', label: 'Lebanon (+961)' },
    { value: 'Lesotho (+266)', label: 'Lesotho (+266)' },
    { value: 'Liberia (+231)', label: 'Liberia (+231)' },
    { value: 'Libya (+218)', label: 'Libya (+218)' },
    { value: 'Liechtenstein (+423)', label: 'Liechtenstein (+423)' },
    { value: 'Lithuania (+370)', label: 'Lithuania (+370)' },
    { value: 'Luxembourg (+352)', label: 'Luxembourg (+352)' },
    { value: 'Macao (+853)', label: 'Macao (+853)' },
    { value: 'Macedonia (+389)', label: 'Macedonia (+389)' },
    { value: 'Madagascar (+261)', label: 'Madagascar (+261)' },
    { value: 'Malawi (+265)', label: 'Malawi (+265)' },
    { value: 'Malaysia (+60)', label: 'Malaysia (+60)' },
    { value: 'Maldives (+960)', label: 'Maldives (+960)' },
    { value: 'Mali (+223)', label: 'Mali (+223)' },
    { value: 'Malta (+356)', label: 'Malta (+356)' },
    { value: 'Marshall Islands (+692)', label: 'Marshall Islands (+692)' },
    { value: 'Martinique (+596)', label: 'Martinique (+596)' },
    { value: 'Mauritania (+222)', label: 'Mauritania (+222)' },
    { value: 'Mauritius (+230)', label: 'Mauritius (+230)' },
    { value: 'Mayotte (+262)', label: 'Mayotte (+262)' },
    { value: 'Mexico (+52)', label: 'Mexico (+52)' },
    { value: 'Moldova (+373)', label: 'Moldova (+373)' },
    { value: 'Monaco (+377)', label: 'Monaco (+377)' },
    { value: 'Mongolia (+976)', label: 'Mongolia (+976)' },
    { value: 'Montenegro (+382)', label: 'Montenegro (+382)' },
    { value: 'Montserrat (+1)', label: 'Montserrat (+1)' },
    { value: 'Morocco (+212)', label: 'Morocco (+212)' },
    { value: 'Mozambique (+258)', label: 'Mozambique (+258)' },
    { value: 'Myanmar (+95)', label: 'Myanmar (+95)' },
    { value: 'Namibia (+264)', label: 'Namibia (+264)' },
    { value: 'Nauru (+674)', label: 'Nauru (+674)' },
    { value: 'Nepal (+977)', label: 'Nepal (+977)' },
    { value: 'Netherlands (+31)', label: 'Netherlands (+31)' },
    { value: 'Netherlands Antilles (+0)', label: 'Netherlands Antilles (+0)' },
    { value: 'New Caledonia (+687)', label: 'New Caledonia (+687)' },
    { value: 'New Zealand (+64)', label: 'New Zealand (+64)' },
    { value: 'Nicaragua (+505)', label: 'Nicaragua (+505)' },
    { value: 'Niger (+227)', label: 'Niger (+227)' },
    { value: 'Nigeria (+234)', label: 'Nigeria (+234)' },
    { value: 'Niue (+683)', label: 'Niue (+683)' },
    { value: 'Norfolk Island (+672)', label: 'Norfolk Island (+672)' },
    { value: 'Northern Mariana Islands (+1)', label: 'Northern Mariana Islands (+1)' },
    { value: 'Norway (+47)', label: 'Norway (+47)' },
    { value: 'Pakistan (+92)', label: 'Pakistan (+92)' },
    { value: 'Palau (+680)', label: 'Palau (+680)' },
    { value: 'Palestinian Territory (+970)', label: 'Palestinian Territory (+970)' },
    { value: 'Panama (+507)', label: 'Panama (+507)' },
    { value: 'Papua New Guinea (+675)', label: 'Papua New Guinea (+675)' },
    { value: 'Paraguay (+595)', label: 'Paraguay (+595)' },
    { value: 'Peru (+51)', label: 'Peru (+51)' },
    { value: 'Philippines (+63)', label: 'Philippines (+63)' },
    { value: 'Pitcairn (+0)', label: 'Pitcairn (+0)' },
    { value: 'Poland (+48)', label: 'Poland (+48)' },
    { value: 'Portugal (+351)', label: 'Portugal (+351)' },
    { value: 'Puerto Rico (+1)', label: 'Puerto Rico (+1)' },
    { value: 'Qatar (+974)', label: 'Qatar (+974)' },
    { value: 'Reunion (+262)', label: 'Reunion (+262)' },
    { value: 'Romania (+40)', label: 'Romania (+40)' },
    { value: 'Russian Federation (+7)', label: 'Russian Federation (+7)' },
    { value: 'Rwanda (+250)', label: 'Rwanda (+250)' },
    { value: 'S. Georgia and S. Sandwich Islands (+0)', label: 'S. Georgia and S. Sandwich Islands (+0)' },
    { value: 'Saint Helena (+290)', label: 'Saint Helena (+290)' },
    { value: 'Saint Kitts and Nevis (+1)', label: 'Saint Kitts and Nevis (+1)' },
    { value: 'Saint Lucia (+1)', label: 'Saint Lucia (+1)' },
    { value: 'Saint Pierre and Miquelon (+508)', label: 'Saint Pierre and Miquelon (+508)' },
    { value: 'Saint Vincent and the Grenadines (+1)', label: 'Saint Vincent and the Grenadines (+1)' },
    { value: 'Samoa (+685)', label: 'Samoa (+685)' },
    { value: 'San Marino (+378)', label: 'San Marino (+378)' },
    { value: 'Sao Tome and Principe (+239)', label: 'Sao Tome and Principe (+239)' },
    { value: 'Saudi Arabia (+966)', label: 'Saudi Arabia (+966)' },
    { value: 'Senegal (+221)', label: 'Senegal (+221)' },
    { value: 'Serbia (+381)', label: 'Serbia (+381)' },
    { value: 'Serbia and Montenegro (+0)', label: 'Serbia and Montenegro (+0)' },
    { value: 'Seychelles (+248)', label: 'Seychelles (+248)' },
    { value: 'Sierra Leone (+232)', label: 'Sierra Leone (+232)' },
    { value: 'Singapore (+65)', label: 'Singapore (+65)' },
    { value: 'Slovak Republic (+421)', label: 'Slovak Republic (+421)' },
    { value: 'Slovenia (+386)', label: 'Slovenia (+386)' },
    { value: 'Solomon Islands (+677)', label: 'Solomon Islands (+677)' },
    { value: 'Somalia (+252)', label: 'Somalia (+252)' },
    { value: 'South Africa (+27)', label: 'South Africa (+27)' },
    { value: 'South Sudan (+211)', label: 'South Sudan (+211)' },
    { value: 'Spain (+34)', label: 'Spain (+34)' },
    { value: 'Sri Lanka (+94)', label: 'Sri Lanka (+94)' },
    { value: 'Sudan (+249)', label: 'Sudan (+249)' },
    { value: 'Sultanate of Oman (+968)', label: 'Sultanate of Oman (+968)' },
    { value: 'Suriname (+597)', label: 'Suriname (+597)' },
    { value: 'Svalbard and Jan Mayen (+47)', label: 'Svalbard and Jan Mayen (+47)' },
    { value: 'Swaziland (+268)', label: 'Swaziland (+268)' },
    { value: 'Sweden (+46)', label: 'Sweden (+46)' },
    { value: 'Switzerland (+41)', label: 'Switzerland (+41)' },
    { value: 'Syria (+963)', label: 'Syria (+963)' },
    { value: 'Taiwan (+886)', label: 'Taiwan (+886)' },
    { value: 'Tajikistan (+992)', label: 'Tajikistan (+992)' },
    { value: 'Tanzania (+255)', label: 'Tanzania (+255)' },
    { value: 'Thailand (+66)', label: 'Thailand (+66)' },
    { value: 'Timor-Leste (+670)', label: 'Timor-Leste (+670)' },
    { value: 'Togo (+228)', label: 'Togo (+228)' },
    { value: 'Tokelau (+690)', label: 'Tokelau (+690)' },
    { value: 'Tonga (+676)', label: 'Tonga (+676)' },
    { value: 'Trinidad and Tobago (+1)', label: 'Trinidad and Tobago (+1)' },
    { value: 'Tunisia (+216)', label: 'Tunisia (+216)' },
    { value: 'Turkey (+90)', label: 'Turkey (+90)' },
    { value: 'Turkmenistan (+993)', label: 'Turkmenistan (+993)' },
    { value: 'Turks and Caicos Islands (+1)', label: 'Turks and Caicos Islands (+1)' },
    { value: 'Tuvalu (+688)', label: 'Tuvalu (+688)' },
    { value: 'Uganda (+256)', label: 'Uganda (+256)' },
    { value: 'Ukraine (+380)', label: 'Ukraine (+380)' },
    { value: 'United Arab Emirates (+971)', label: 'United Arab Emirates (+971)' },
    { value: 'United Kingdom (+44)', label: 'United Kingdom (+44)' },
    { value: 'Uruguay (+598)', label: 'Uruguay (+598)' },
    { value: 'Uzbekistan (+998)', label: 'Uzbekistan (+998)' },
    { value: 'Vanuatu (+678)', label: 'Vanuatu (+678)' },
    { value: 'Vatican City State (Holy See) (+39)', label: 'Vatican City State (Holy See) (+39)' },
    { value: 'Venezuela (+58)', label: 'Venezuela (+58)' },
    { value: 'Vietnam (+84)', label: 'Vietnam (+84)' },
    { value: 'Virgin Islands (British) (+1)', label: 'Virgin Islands (British) (+1)' },
    { value: 'Virgin Islands (U.S.) (+1)', label: 'Virgin Islands (U.S.) (+1)' },
    { value: 'Wallis and Futuna (+681)', label: 'Wallis and Futuna (+681)' },
    { value: 'Western Sahara (+212)', label: 'Western Sahara (+212)' },
    { value: 'Yemen (+967)', label: 'Yemen (+967)' },
    { value: 'Zambia (+260)', label: 'Zambia (+260)' },
    { value: 'Zimbabwe (+263)', label: 'Zimbabwe (+263)' }
];

export default countryCodes;