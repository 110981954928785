import React from 'react';
import { Grid, Paper, Typography, Box, Container } from '@mui/material';

const ContactPage = () => {
    return (
        <Container>
            <Grid 
                container 
                spacing={3} 
                justifyContent="center" 
                alignItems="center" 
                style={{ minHeight: '100vh', padding: '20px', backgroundColor: '#f5f5f5' }}
            >
                <Grid item xs={12} md={6}>
                    <Paper 
                        elevation={3} 
                        sx={{ 
                            padding: 3, 
                            textAlign: 'center', 
                            backgroundColor: '#ffffff', 
                            borderRadius: '10px', 
                            boxShadow: '0 3px 5px rgba(0, 0, 0, 0.1)' 
                        }}
                    >
                        <Typography variant="h6" gutterBottom>
                            Need Help?
                        </Typography>
                        <Typography variant="body1">
                            Our support team is here to assist you with any questions or concerns.
                        </Typography>
                        <Box mt={2}>
                            <Typography variant="body2" sx={{ marginBottom: 1 }}>
                                Email: <a href="mailto:support@careerfornia.com" style={{ color: '#1976d2', textDecoration: 'none' }}>support@careerfornia.com</a>
                            </Typography>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default ContactPage;