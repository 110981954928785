import React from 'react';
import { Box, Typography } from '@mui/material';

const AboutUsPage = () => {
    return (
        <Box sx={{
            maxWidth: 800,
            margin: 'auto',
            p: 2,
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            '@media (max-width:600px)': {
                p: 1,
            },
        }}>
            <Typography variant="h4" sx={{
                mb: 2,
                textAlign: 'center',
                fontSize: { xs: '1.5rem', sm: '2.125rem' },
                fontFamily: 'Georgia, serif',
            }}>
                About Us
            </Typography>
            <Typography variant="body1" sx={{
                mb: 2,
                textAlign: 'center',
                fontSize: { xs: '1rem', sm: '1.25rem' },
                fontFamily: 'Georgia, serif',
            }}>
                At Careerfornia, we understand that applying for jobs can be a daunting and time-consuming process. Our founder experienced firsthand the challenges and frustrations of job hunting, from filling out countless forms to tailoring resumes for each application. This realization sparked the idea to create a platform that simplifies and streamlines the job application process.
            </Typography>
            <Typography variant="body1" sx={{
                mb: 2,
                textAlign: 'center',
                fontSize: { xs: '1rem', sm: '1.25rem' },
                fontFamily: 'Georgia, serif',
            }}>
                Our mission is to help job seekers apply for jobs without the hassle. With Careerfornia, you can generate tailored resumes in seconds, apply to multiple jobs with a single click, and even leave thoughtful comments on LinkedIn posts using our AI-powered tools. We are dedicated to making your job search as efficient and stress-free as possible.
            </Typography>
            <Typography variant="body1" sx={{
                textAlign: 'center',
                fontSize: { xs: '1rem', sm: '1.25rem' },
                fontFamily: 'Georgia, serif',
            }}>
                Join us on our journey to revolutionize the job application process and help you land your dream job with ease.
            </Typography>
            <Typography variant="body2" sx={{
                textAlign: 'center',
                fontSize: { xs: '0.875rem', sm: '1rem' },
                fontFamily: 'Georgia, serif',
                mt: 4,
            }}>
                Address: Plot No 61, Om Ganesh Nagar Colony, Raganaguda, Hayathnagar, Hyderabad, Telangana, India, 501510
            </Typography>
        </Box>
    );
};

export default AboutUsPage;